<div class="" id="container">

    <div class="form-container sign-in-container">
        <div class="navbar-header" >
            <a class="navbar-brand" routerLink="/">
                <!-- Logo icon -->
                <b class="logo-icon">
                    <img src="assets/img/onetaplogo.png" alt="homepage" class="light-logo" />
                </b>
            </a>
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
   
        <h1 class="font-weight-bold">Create Account</h1>
       
              <div class="form-group">
                <label>First Name</label>
                <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <label>Last Name</label>
                <input type="text" formControlName="lastName"class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
            </div>
            <div class="form-group">
              <label>Mobile</label>
              <input type="text" formControlName="mobile"class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
              <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                  <div *ngIf="f.mobile.errors.required">Mobile number is required</div>
                  <div *ngIf="f.mobile.errors?.pattern">Mobile number should be 10 digit start with 09</div>
              </div>
          </div>
        <div class="form-group">
            <label>Email</label>
            <input type="text" formControlName="email"class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email is invalid</div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group">
                <label>Password</label>
                <input type="password" formControlName="password"class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
            </div>
            <div class="form-group">
                <label>Confirm Password</label>
                <input type="password" formControlName="confirmPassword"class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <label for="acceptTerms" class="" >Accept Terms & Conditions</label>
            <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
    
            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
        </div>
        <button [disabled]="loading" class="signin">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Sign Up
        </button>
        <alert></alert>
      
      </form>
    </div>

    <div class="overlay-container">
      <div class="overlay">
        <div class="overlay-panel overlay-right">
            <img src="assets/img/register.png"  />
            <h1 class="font-weight-bold">Good to see you!</h1>
            <p>You already have an account? </p>
         <a [routerLink]="['/auth/login']">
               <button class="signup" id="signUp">Sign In</button>
         </a> 
        </div>
      </div>
    </div>
  </div>
  

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { AlertComponent} from './_components/alert.component';
import { RouterModule } from '@angular/router';
import { AppModule } from './app.module';

@NgModule({
    declarations: [
        // AlertComponent,
    ],
    exports: [
        // AlertComponent,
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    bootstrap: [],
    entryComponents: []
})


export class AppSharedModule {
    static forRoot(): ModuleWithProviders<AppModule> {
        return {
            ngModule: AppModule,
            providers: []
        };
    }
 }

import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {Employee, Certification, Contact,Education,Family,LanguageSkill,Skill } from '../_models';

const employeeUrl = `${environment.apiUrl}/employee`;
const certifcationUrl = `${environment.apiUrl}/certification`;
const contactUrl = `${environment.apiUrl}/contact`;
const educationUrl = `${environment.apiUrl}/education`;
const familyUrl = `${environment.apiUrl}/family`;
const languageSkillUrl = `${environment.apiUrl}/languageSkill`;
const skillUrl = `${environment.apiUrl}/skill`;

@Injectable({ providedIn: 'root' })
export class HrmService {
    private employeeSubject: BehaviorSubject<Employee>;
    public employee: Observable<Employee>;
    private certifcationSubject: BehaviorSubject<Certification>;
    public certification: Observable<Certification>;
    private contactSubject: BehaviorSubject<Contact>;
    public contact: Observable<Contact>;
    private eudcationSubject: BehaviorSubject<Education>;
    public education: Observable<Education>;
    private familySubject: BehaviorSubject<Family>;
    public family: Observable<Family>;
    private languageSkillSubject: BehaviorSubject<LanguageSkill>;
    public languageSkill: Observable<LanguageSkill>;
    private skillSubject: BehaviorSubject<Skill>;
    public skill: Observable<Skill>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.employeeSubject = new BehaviorSubject<Employee>(null);
        this.employee = this.employeeSubject.asObservable();
        this.certifcationSubject = new BehaviorSubject<Certification>(null);
        this.certification = this.certifcationSubject.asObservable();
        this.contactSubject = new BehaviorSubject<Contact>(null);
        this.contact = this.contactSubject.asObservable();
        this.eudcationSubject = new BehaviorSubject<Education>(null);
        this.education = this.eudcationSubject.asObservable();
        this.familySubject = new BehaviorSubject<Family>(null);
        this.family = this.familySubject.asObservable();
        this.languageSkillSubject = new BehaviorSubject<LanguageSkill>(null);
        this.languageSkill = this.languageSkillSubject.asObservable();
        this.skillSubject = new BehaviorSubject<Skill>(null);
        this.skill = this.skillSubject.asObservable();
    }

    public get employeeValue(): Employee {
        return this.employeeSubject.value;
    }

    public get certifcationValue(): Certification {
        return this.certifcationSubject.value;
    }

    public get contactValue(): Contact {
        return this.contactSubject.value;
    }

    public get eudcationValue(): Education {
        return this.eudcationSubject.value;
    }

    public get familyValue(): Family {
        return this.familySubject.value;
    }
    public get languageSkillValue(): LanguageSkill {
        return this.languageSkillSubject.value;
    }
    public get skillValue(): Skill {
        return this.skillSubject.value;
    }



    getAllEmployee() {
        return this.http.get<Employee[]>(employeeUrl);
    }

    getAllCertification() {
        return this.http.get<Certification[]>(certifcationUrl);
    }

    getAllContact() {
        return this.http.get<Contact[]>(contactUrl);
    }

    getAllFamily() {
        return this.http.get<Family[]>(familyUrl);
    }

    getAllEducation() {
        return this.http.get<Education[]>(educationUrl);
    }

    getAllLanguageSkill() {
        return this.http.get<LanguageSkill[]>(languageSkillUrl);
    }
    getSkill() {
        return this.http.get<Skill[]>(skillUrl);
    }
  

   //country Method
    getEmployeeById(id: string) {
        return this.http.get<Employee>(`${employeeUrl}/${id}`);
    }

    getCertificationById(id: string) {
        return this.http.get<Certification>(`${certifcationUrl}/${id}`);
    }

    getContactById(id: string) {
        return this.http.get<Contact>(`${contactUrl}/${id}`);
    }
    getEducationById(id: string) {
        return this.http.get<Education>(`${educationUrl}/${id}`);
    }

    getFamilyById(id: string) {
        return this.http.get<Family>(`${familyUrl}/${id}`);
    }

    getLanguageSkillById(id: string) {
        return this.http.get<LanguageSkill>(`${languageSkillUrl}/${id}`);
    }
    getSkillById(id: string) {
        return this.http.get<Skill>(`${skillUrl}/${id}`);
    }
    
  

    createEmployee(params) {
        return this.http.post(employeeUrl, params);
    }
    
    updateEmployee(id, params) {
        return this.http.put(`${employeeUrl}/${id}`, params)
            .pipe(map((employee: any) => {
                    this.employee = { ...this.employeeValue, ...employee };
                    this.employeeSubject.next(employee);
              
                return employee;
            }));
    }
    deleteEmployee(id: string) {
        return this.http.delete(`${employeeUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createCertifcation(params) {
        return this.http.post(certifcationUrl, params);
    }
    
    updateCertifcation(id, params) {
        return this.http.put(`${certifcationUrl}/${id}`, params)
            .pipe(map((certification: any) => {
                    this.certification = { ...this.certifcationValue, ...certification };
                    this.certifcationSubject.next(certification);
              
                return certification;
            }));
    }
    deleteCertification(id: string) {
        return this.http.delete(`${certifcationUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createContact(params) {
        return this.http.post(contactUrl, params);
    }
    
    updateContact(id, params) {
        return this.http.put(`${contactUrl}/${id}`, params)
            .pipe(map((contact: any) => {
                    this.contact = { ...this.contactValue, ...contact };
                    this.contactSubject.next(contact);
              
                return contact;
            }));
    }
    deleteContact(id: string) {
        return this.http.delete(`${contactUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createEducation(params) {
        return this.http.post(educationUrl, params);
    }
    
    updateEducation(id, params) {
        return this.http.put(`${educationUrl}/${id}`, params)
            .pipe(map((education: any) => {
                    this.education = { ...this.eudcationValue, ...education };
                    this.eudcationSubject.next(education);
              
                return education;
            }));
    }
    deleteEducation(id: string) {
        return this.http.delete(`${educationUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createFamily(params) {
        return this.http.post(familyUrl, params);
    }
    
    updateFamily(id, params) {
        return this.http.put(`${familyUrl}/${id}`, params)
            .pipe(map((family: any) => {
                    this.family = { ...this.familyValue, ...family };
                    this.familySubject.next(family);
                return family;
            }));
    }
    deleteFamily(id: string) {
        return this.http.delete(`${familyUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createLanguageSkill(params) {
        return this.http.post(languageSkillUrl, params);
    }
    
    updateLanguageSkill(id, params) {
        return this.http.put(`${languageSkillUrl}/${id}`, params)
            .pipe(map((langaugeSkill: any) => {
                    this.languageSkill = { ...this.languageSkillValue, ...langaugeSkill };
                    this.languageSkillSubject.next(langaugeSkill);
                return langaugeSkill;
            }));
    }
    deleteLanguageSkill(id: string) {
        return this.http.delete(`${languageSkillUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createSkill(params) {
        return this.http.post(skillUrl, params);
    }
    
    updateSkill(id, params) {
        return this.http.put(`${skillUrl}/${id}`, params)
            .pipe(map((skill: any) => {
                    this.skill = { ...this.skillValue, ...skill };
                    this.skillSubject.next(skill);
                return skill;
            }));
    }
    deleteSkill(id: string) {
        return this.http.delete(`${skillUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

   
    
}


export class Certification {
    id: string;
    certification: string;
    institute: string;
    grantedOn: string;
    active: string;
    created: string;
    updated: string;
}
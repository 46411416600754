

export class Calllog {
    id: string;
    callfrom: string;
    calltype: string;
    language: boolean;
    number;
    category;
    topic;
    date;
    month;
    status;
    atype;
    rtm;
    priority;
    dept;
    closed: boolean;
    resolved: boolean;
    worker;
    team;
    proj;
    escalated;
}
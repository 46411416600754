import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { TaskReport} from '../_models';


const taskreportUrl = `${environment.apiUrl}/taskreport`;


@Injectable({ providedIn: 'root' })
export class TaskService {
    private taskSubject: BehaviorSubject<TaskReport>;
 
    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.taskSubject = new BehaviorSubject<TaskReport>(null);
      
    }
    getAgentTicketPerformance(accountId: string,start: string,end: string,projectId: string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/agentticketperformance/${accountId}/${start}/${end}/${projectId}`);
    }
    getCustomerTicketDetail(start: string,end: string,project: string,category : string,type : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/customerticketdetail/${start}/${end}/${project}/${category}/${type}`);
    }
    getDepartmentTicketDetail(start: string,end: string,project: string,category : string,type : string,status : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/departmentticketdetail/${start}/${end}/${project}/${category}/${type}/${status}`);
    }
    getDepartmentTicketVolume(start: string,end: string,project: string,category : string,type : string,status : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/departmentticketvolume/${start}/${end}/${project}/${category}/${type}/${status}`);
    }
    getDepartmentDailyTicketVolume(start: string,end: string,project: string,category : string,type : string,status : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/departmentdailyticketvolume/${start}/${end}/${project}/${category}/${type}/${status}`);
    }
    getDepartmentMonthlyTicketVolume(year: string,status: string,) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/departmentmonthlyticketvolume/${year}/${status}`);
    }
    agentTicketPerformance(start: string,end: string,projectId: string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/ticketperformance/${start}/${end}/${projectId}`);
    }
    getTstatus() {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/status`);
    }

    getProejctTicketStats() {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/project/ticket/status`);
    }
    getTaskByProject(){
        return this.http.get<TaskService[]>(`${taskreportUrl}/ticketbyproject`);
    }
    getProejctMilestoneStats() {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/project/milestone/status`);
    }
    getProejctTaskStats() {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/project/task/status`);
    }
    getTaskStats(id : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/task/status/${id}`);
    }
    getAllTaskStats() {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/all/task/status`);
    }
    getProejctTaskCount() {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/project/task/count`);
    }
    getTaskStatusStats() {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/all/task/status`);
    }
    getTaskPrirotyStats() {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/all/task/priority`);
    }
    getTaskCategoryStats() {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/all/task/category`);
    }
    getDepartmentTicketstatus(departmentId) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/status/department/${departmentId}`);
    }
    getProjectTaskPrirotyStats(projectId : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/all/project/${projectId}/task/priority`);
    }
    getMilestoneTaskPrirotyStats(projectId : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/all/milestone/${projectId}/task/priority`);
    }
    getProjectTaskStatusStats(projectId: string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/all/project/${projectId}/task/status`);
    }
    getMilestoneTaskStatusStats(projectId: string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/all/milestone/${projectId}/task/status`);
    }
    
    getTicketByTeams(){
        return this.http.get<TaskReport[]>(`${taskreportUrl}/teamticketstats`);
    }
    
    getTeamTicketDetail(start: string,end: string,project: string,category : string,type : string,status : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/teamticketdetail/${start}/${end}/${project}/${category}/${type}/${status}`);
    }
    getTeamTicketVolume(start: string,end: string,project: string,category : string,type : string,status : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/teamticketvolume/${start}/${end}/${project}/${category}/${type}/${status}`);
    }
    getTeamDailyTicketVolume(start: string,end: string,project: string,category : string,type : string,status : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/teamdailyticketvolume/${start}/${end}/${project}/${category}/${type}/${status}`);
    }
    getTeamMonthlyTicketVolume(year: string,status: string,) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/teammonthlyticketvolume/${year}/${status}`);
    }

    getTicketByAgent(){
        return this.http.get<TaskReport[]>(`${taskreportUrl}/agentticketstats`);
    }
    
    getAgentTicketDetail(start: string,end: string,project: string,category : string,type : string,status : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/agentticketdetail/${start}/${end}/${project}/${category}/${type}/${status}`);
    }
    getAgentTicketVolume(start: string,end: string,project: string,category : string,type : string,status : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/agentticketvolume/${start}/${end}/${project}/${category}/${type}/${status}`);
    }
    getAgentDailyTicketVolume(start: string,end: string,project: string,category : string,type : string,status : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/agentdailyticketvolume/${start}/${end}/${project}/${category}/${type}/${status}`);
    }
    getAgentMonthlyTicketVolume(year: string,status: string,) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/agentmonthlyticketvolume/${year}/${status}`);
    }
    getTicketByDept(){
        return this.http.get<TaskReport[]>(`${taskreportUrl}/departmentticketstats`);
    }


    getTopDailyTicketVolume(start: string,end: string,project: string,category : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/topdailyticketvolume/${start}/${end}/${project}/${category}`);
    }
    getTopMonthlyicketVolume(year: string,project: string,category : string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/topmontlyticketvolume/${year}/${project}/${category}`);
    }
   
   
}
    
   
    
    

   

    
  
    

   

    
   
    
    

   
    
    
    
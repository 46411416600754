
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {VendorHeaderComponent} from './header/header.component';
import { PrcingComponent } from './pages/prcing.component';
import { FaqComponent } from './pages/faq/faq.component';
import { CartComponent } from './pages/cart/cart.component';
import { RouterModule } from '@angular/router';
import { HomeModule } from './home.module';
// import { AlertComponent } from '../_components';



@NgModule({
    declarations: [
        VendorHeaderComponent,
        PrcingComponent,
        FaqComponent,
        CartComponent,
        // AlertComponent
    ],
    exports: [
        VendorHeaderComponent,
        PrcingComponent,
        FaqComponent,
        // AlertComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    bootstrap: [],
    entryComponents: []
})


export class HomeSharedModule {
    static forRoot(): ModuleWithProviders<HomeModule> {
        return {
            ngModule: HomeModule,
            providers: []
        };
    }
 }


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppModule} from '../app.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
// components
import {HomeComponent} from './home.component';
// import {VendorHeaderComponent} from './header/header.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeSharedModule } from './home-shared.module';
import { NgApexchartsModule } from "ng-apexcharts";

@NgModule({
    declarations: [
        HomeComponent
    ],imports: [
        CommonModule,
        ChartsModule,
        HomeRoutingModule,
        HomeSharedModule,
        NgApexchartsModule
    ],
    bootstrap: [],
    entryComponents: []
})

export class HomeModule {
}

import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Operators, Bus,Agent, TicketOffice,Driver,RouteDetail,Fuel,Menteance,SparePart} from '../_models';


const operatorUrl = `${environment.apiUrl}/operator`;
const busUrl = `${environment.apiUrl}/bus`;
const agentUrl = `${environment.apiUrl}/agent`;
const ticketOfficeUrl = `${environment.apiUrl}/ticketOffice`;
const driverUrl = `${environment.apiUrl}/driver`;
const routeDetailUrl = `${environment.apiUrl}/routeDetail`;
const fuelUrl = `${environment.apiUrl}/fuel`;
const menteanceUrl = `${environment.apiUrl}/menteance`;
const sparepartUrl = `${environment.apiUrl}/sparepart`;

@Injectable({ providedIn: 'root' })
export class GuzoyeService {
    private operatorsSubject: BehaviorSubject<Operators>;
    public operators: Observable<Operators>;
    private busSubject: BehaviorSubject<Bus>;
    public bus: Observable<Bus>;
    private agentSubject: BehaviorSubject<Agent>;
    public agent: Observable<Agent>;
    private ticketOfficeSubject: BehaviorSubject<TicketOffice>;
    public ticketOffice: Observable<TicketOffice>;
    private driverSubject: BehaviorSubject<Driver>;
    public driver: Observable<Driver>;
    private routeDetailSubject: BehaviorSubject<RouteDetail>;
    public routeDetail: Observable<RouteDetail>;
    private fuelSubject: BehaviorSubject<Fuel>;
    public fuel: Observable<Fuel>;
    private menteanceSubject: BehaviorSubject<Menteance>;
    public menteance: Observable<Menteance>;
    private sparePartSubject: BehaviorSubject<SparePart>;
    public sparePart: Observable<SparePart>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.operatorsSubject = new BehaviorSubject<Operators>(null);
        this.operators = this.operatorsSubject.asObservable();
        this.busSubject = new BehaviorSubject<Bus>(null);
        this.bus = this.busSubject.asObservable();
        this.agentSubject = new BehaviorSubject<Agent>(null);
        this.agent = this.agentSubject.asObservable();
        this.ticketOfficeSubject = new BehaviorSubject<TicketOffice>(null);
        this.ticketOffice = this.ticketOfficeSubject.asObservable();
        this.driverSubject = new BehaviorSubject<Driver>(null);
        this.driver = this.driverSubject.asObservable();
        this.routeDetailSubject = new BehaviorSubject<RouteDetail>(null);
        this.routeDetail = this.routeDetailSubject.asObservable();
        this.fuelSubject = new BehaviorSubject<Fuel>(null);
        this.fuel = this.fuelSubject.asObservable();
        this.menteanceSubject = new BehaviorSubject<Menteance>(null);
        this.menteance = this.menteanceSubject.asObservable();
        this.sparePartSubject = new BehaviorSubject<SparePart>(null);
        this.sparePart = this.sparePartSubject.asObservable();
    }

    public get operatorsValue(): Operators {
        return this.operatorsSubject.value;
    }

    public get busValue(): Bus {
        return this.busSubject.value;
    }

    public get agentValue(): Agent {
        return this.agentSubject.value;
    }

    public get driverValue(): Driver {
        return this.driverSubject.value;
    }

    public get routeDetailValue(): RouteDetail {
        return this.routeDetailSubject.value;
    }

    public get ticketOfficeValue(): TicketOffice {
        return this.ticketOfficeSubject.value;
    }

    public get fuelValue(): Fuel {
        return this.fuelSubject.value;
    }

    public get menteanceValue(): Menteance {
        return this.menteanceSubject.value;
    }

    public get sparePartValue(): SparePart {
        return this.sparePartSubject.value;
    }
   

    getAllOperators() {
        return this.http.get<Operators[]>(operatorUrl);
    }

    getAllBus() {
        return this.http.get<Bus[]>(busUrl);
    }

    getAllAgent() {
        return this.http.get<Agent[]>(agentUrl);
    }

    getAllTicketOffice() {
        return this.http.get<TicketOffice[]>(ticketOfficeUrl);
    }

    getAllDriver() {
        return this.http.get<Driver[]>(driverUrl);
    }

    getAllRouteDetail() {
        return this.http.get<RouteDetail[]>(routeDetailUrl);
    }

    getAllfuel() {
        return this.http.get<Fuel[]>(fuelUrl);
    }

    getAllMenteance() {
        return this.http.get<Menteance[]>(menteanceUrl);
    }

    getAllSparePart() {
        return this.http.get<SparePart[]>(sparepartUrl);
    }

   //country Method
    getOperatorsById(id: string) {
        return this.http.get<Operators>(`${operatorUrl}/${id}`);
    }

    getBusById(id: string) {
        return this.http.get<Bus>(`${busUrl}/${id}`);
    }

    getAgentById(id: string) {
        return this.http.get<Agent>(`${agentUrl}/${id}`);
    }
    getDriverById(id: string) {
        return this.http.get<Driver>(`${driverUrl}/${id}`);
    }

    getTicketOfficeById(id: string) {
        return this.http.get<TicketOffice>(`${ticketOfficeUrl}/${id}`);
    }
    
    getRouteDetailById(id: string) {
        return this.http.get<RouteDetail>(`${routeDetailUrl}/${id}`);
    }

    getFuelById(id: string) {
        return this.http.get<Fuel>(`${fuelUrl}/${id}`);
    }

    getSparePartById(id: string) {
        return this.http.get<SparePart>(`${sparepartUrl}/${id}`);
    }

    getMenteanceById(id: string) {
        return this.http.get<Menteance>(`${menteanceUrl}/${id}`);
    }

    createOperators(params) {
        return this.http.post(operatorUrl, params);
    }
    
    updateOperators(id, params) {
        return this.http.put(`${operatorUrl}/${id}`, params)
            .pipe(map((operators: any) => {
                    this.operators = { ...this.operatorsValue, ...operators };
                    this.operatorsSubject.next(operators);
              
                return operators;
            }));
    }
    deleteOperators(id: string) {
        return this.http.delete(`${operatorUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createBus(params) {
        return this.http.post(busUrl, params);
    }
    
    updateBus(id, params) {
        return this.http.put(`${busUrl}/${id}`, params)
            .pipe(map((bus: any) => {
                    this.bus = { ...this.busValue, ...bus };
                    this.busSubject.next(bus);
              
                return bus;
            }));
    }
    deleteBus(id: string) {
        return this.http.delete(`${busUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createAgent(params) {
        return this.http.post(agentUrl, params);
    }
    
    updateAgent(id, params) {
        return this.http.put(`${agentUrl}/${id}`, params)
            .pipe(map((agent: any) => {
                    this.agent = { ...this.agentValue, ...agent };
                    this.agentSubject.next(agent);
              
                return agent;
            }));
    }
    deleteAgent(id: string) {
        return this.http.delete(`${agentUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createTicketOffice(params) {
        return this.http.post(ticketOfficeUrl, params);
    }
    
    updateTicketOffice(id, params) {
        return this.http.put(`${ticketOfficeUrl}/${id}`, params)
            .pipe(map((ticketOffice: any) => {
                    this.ticketOffice = { ...this.ticketOfficeValue, ...ticketOffice };
                    this.ticketOfficeSubject.next(ticketOffice);
              
                return ticketOffice;
            }));
    }
    deleteTiceketOffice(id: string) {
        return this.http.delete(`${ticketOfficeUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createDriver(params) {
        return this.http.post(driverUrl, params);
    }
    
    updateDriver(id, params) {
        return this.http.put(`${driverUrl}/${id}`, params)
            .pipe(map((driver: any) => {
                    this.driver = { ...this.driverValue, ...driver };
                    this.driverSubject.next(driver);
                return driver;
            }));
    }
    deleteDriver(id: string) {
        return this.http.delete(`${driverUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createFuel(params) {
        return this.http.post(fuelUrl, params);
    }
    
    updateFuel(id, params) {
        return this.http.put(`${fuelUrl}/${id}`, params)
            .pipe(map((fuel: any) => {
                    this.fuel = { ...this.fuelValue, ...fuel };
                    this.fuelSubject.next(fuel);
              
                return fuel;
            }));
    }
    deleteFuel(id: string) {
        return this.http.delete(`${fuelUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createMenteance(params) {
        return this.http.post(menteanceUrl, params);
    }
    
    updateMenteance(id, params) {
        return this.http.put(`${menteanceUrl}/${id}`, params)
            .pipe(map((menteance: any) => {
                    this.menteance = { ...this.menteanceValue, ...menteance };
                    this.menteanceSubject.next(menteance);
              
                return menteance;
            }));
    }
    deleteMenteance(id: string) {
        return this.http.delete(`${menteanceUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    
}
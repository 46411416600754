
<div id="main-wrapper">

    <header class="topbar">
        <nav class="navbar top-navbar navbar-expand-md" >
            <div class="navbar-header" >
           
                <app-header></app-header>
            </div>
       
           
        </nav>
    </header>

   

    <router-outlet></router-outlet>
    <footer class="footer text-center">
        <div class="footer-wrap">
            <div class="footer-left">
                <h3> OneTap </h3>
                <p> Copyrights &copy; 2021, OneTap Technologies PLC. All Rights Reserved. </p>
            </div>
            <div class="footer-right">
                V.1.0
            </div>
        </div>
    </footer>
    <div class="chat-windows"></div>
</div>
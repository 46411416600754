import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AccountService } from './_services';
import { first } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

declare let $: any;


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    imageUrl = `${environment.apiUrl}/preview`;
    location: any;
    modules: any[]
    routerSubscription: any;
    account = this.accountService.accountValue;
    
    public isCollapsed = false;
    constructor(private router: Router,    private accountService: AccountService) {
    }

    ngOnInit(){
        this.accountService.getModulePermission(this.account.roleId)
        .pipe(first())
        .subscribe(modules => this.modules = modules);
    }

     logout() {
        this.accountService.logout()
        window.location.reload();
      }
 
 
   

    
}
import { Role } from './role';

export class Account {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
    mobile: string;
    tinNumber: string;
    email: string;
    role: Role;
    roleId: string;
    supportTeamId: string;
    departmentId: string;
    avator: string;
    avatorPath: string;
    jwtToken?: string;
    branchId: string;
    type: string;
}
<div class="page">

<div class="container">
  <div class="statsboard">
  <div class="row">
    <h1> Ticket Metrics</h1>
              <div class="col-2 card">
                <a href="javascript:void(0)"  [routerLink]="['/ticket','status','duesoon']">
                    <div class="row">
                      <div class="col-8 " style="background-color:palevioletred ;">
                        <h3 class="title">Due Soon</h3>
                      </div>
                      <div class="col-4"><h2 class="stats">{{ticketSlaStatus?.duesoon}}</h2></div>
                    </div>
                    </a>
              </div>
              <div class="col-2 card">
                <a href="javascript:void(0)"  [routerLink]="['/ticket','status','overdue']">
                <div class="row">
                  <div class="col-8 " style="background-color:red ;">
                    <h3 class="title">Over Due</h3>
                  </div>
                  <div class="col-4"><h2 class="stats">{{ticketSlaStatus?.escalated}}</h2></div>
                </div>
                </a>
          </div>
          <div class="card col-8" *ngFor="let s of status">
            <a href="javascript:void(0)"  [routerLink]="['/ticket','status',s.ticketstatus?.id]">
                 <div class="row">
                        <div class="col-8 ">
                          <h3 class="title">{{s?.ticketstatus?.name}}</h3>
                        </div>
                        <div class="col-4"><h2 class="stats" style="color:{{s?.ticketstatus?.color}} ;">{{s?.number}}</h2></div>
                 </div>
                 </a>
          </div>
    </div>
    <div class="row">
      <h1>Task Metrics </h1>
                <div class="col-2 card">
                    <a href="javascript:void(0)"  [routerLink]="['/task','status','duesoon']">
                      <div class="row">
                        <div class="col-8 ">
                          <h3 class="title">Due Soon</h3>
                        </div>
                        <div class="col-1"><h2  style="color:palevioletred ;">{{taskSlaStatus?.duesoon}}</h2></div>
                      </div>
                    </a>
                </div>
                <div class="col-2 card">
                  <a href="javascript:void(0)"  [routerLink]="['/task','status','overdue']">
                      <div class="row">
                        <div class="col-8 " >
                          <h3 class="title">Over Due</h3>
                        </div>
                        <div class="col-1"><h2 style="color:red ;">{{taskSlaStatus?.escalated}}</h2></div>
                      </div>
                  </a>
            </div>
            <div class="card col-8" *ngFor="let s of taskstatus">
              <a href="javascript:void(0)"  [routerLink]="['/task','status',s.taskstatus?.id]">
                   <div class="row">
                          <div class="col-7" >
                            <b>{{s?.taskstatus?.name}}</b>
                          </div>
                          <div class="col-4 status"><h2  style="color:{{s?.taskstatus?.color}} ;">{{s?.number}}</h2></div>
                   </div>
               </a>
            </div>
      </div>
</div>
<br/>
</div>
<div class="container">
  <div class="board" >
    <div class="row">
      <div class="col-8">
        <div id="chart">
        <apx-chart
        [series]="statusChart"
        [chart]="areaChart.chart"
        [colors]="areaChart.colors"
        [title]="areaChart.title"
        [xaxis]="xaxis"
        [stroke]="areaChart.stroke"
        [tooltip]="areaChart.tooltip"
        [dataLabels]="areaChart.dataLabels"
        ></apx-chart>
        </div>
      </div>
      <div class="col-4">
       <div class="row side">
        <h3>Ticket Priotity Stats</h3>
        <div class="col-5 stats-card-box" *ngFor="let p of priority">
          <a href="javascript:void(0)"  [routerLink]="['/ticket','priority',p.priority?.id]">
              <h2 class="sub-title" style="color: {{p?.priority?.color}};">{{p?.priority?.name}}</h2>
              <h3 >{{p.number}}</h3>
              </a>
      </div>
       </div>
      </div>
    </div>
  </div>
  <div class="board" >
    <div class="row">
      <div class="col-8">
        <div id="chart">
        <apx-chart
        [series]="taskStatusChart"
        [chart]="taskAreaChart.chart"
        [title]="taskAreaChart.title"
        [xaxis]="taskxaxis"
        [stroke]="taskAreaChart.stroke"
        [tooltip]="taskAreaChart.tooltip"
        [dataLabels]="taskAreaChart.dataLabels"
        ></apx-chart>
        </div>
      </div>
      <div class="col-4">
       <div class="row side">
         <h3>Task Priotity Stats</h3>
        <div class="col-5 stats-card-box" *ngFor="let p of taskPriority">
          <a href="javascript:void(0)"  [routerLink]="['/task','priority',p.priority?.id]">
              <h2 class="sub-title" style="color: {{p?.priority?.color}};">{{p?.priority?.name}}</h2>
              <h3 >{{p.number}}</h3>
          </a>
      </div>
       </div>
      </div>
    </div>
  </div>
  <div class="row statscontainer">
      <div class="status-box" style="border-top-color: rosybrown;">
        <h3 style="color:  rosybrown;"> Tickets need your attention</h3>
        <hr/>
            <div  *ngFor="let ticket of highattention">
              <a href="javascript:void(0)"  [routerLink]="['/crm','project', ticket?.projectId,'ticket','view',ticket?.ticketId]">
             <div class="row">
              <p class="name"> <b class="line">#{{ticket?.ticketNo}}</b>  <b>   {{ticket?.subject}}</b></p>
             </div>
               <div class="row">
              <div class="col-8">
               <div class="row">
                 <p class="col-6 line" style="color: {{ticket?.statusColor}};">{{ticket?.status}}</p>
                 <p class="col-6 line">{{ticket?.priority}}</p>
             
               </div>
              </div>
             <div class="col-4">
                 <!-- var diff = moment.duration(moment(then).diff(moment(now)));
                var days = parseInt(diff.asDays()); -->
              <p > <strong *ngIf="ticket.escalated" style="color: red;"> Over Due</strong> <strong *ngIf="!ticket.escalated" style="color: #8a0a26;"><b *ngIf="ticket.active" >{{moment.duration(now.diff(ticket.firstviolated)).asDays() | number : '1.0-0' }} Days<i *ngIf="moment.duration(now.diff(ticket.firstviolated)).asDays()  >= 1"> late</i><i *ngIf="moment.duration(now.diff(ticket.firstviolated)).asDays() <= 0"> left</i></b><b *ngIf="!ticket.active" >{{moment.duration(now.diff(ticket.resolutionviolated)).asDays() | number : '1.0-0' }} Days <i *ngIf="moment.duration(now.diff(ticket.resolutionviolated)).asDays()  >= 1"> late</i><i *ngIf="moment.duration(now.diff(ticket.resolutionviolated)).asDays() <= 0"> left</i></b></strong>
              </p>
             </div>
             
             </div>
            </a>
             <hr/>
          </div>
        </div>
        <div class="status-box" style="border-top-color: powderblue;">
          <h3 style="color:  powderblue;"> High  priority Todo list</h3>
          <hr/>
              <div  *ngFor="let todo of todolists">
                <a href="javascript:void(0)"  [routerLink]="['/crm','project', todo?.projectId,'task','view',todo?.taskId]">
               <div class="row">
                <p class="name"> <b class="line">#{{todo?.taskNo}}</b>  <b>   {{todo?.subject}}</b></p>
               </div>
                 <div class="row">
                <div class="col-8">
                 <div class="row">
                   <p class="col-6 line" style="color: {{todo?.statusColor}};">{{todo?.status}}</p>
                   <p class="col-6 line">{{todo?.priority}}</p>
               
                 </div>
                </div>
               <div class="col-4">
                   <!-- var diff = moment.duration(moment(then).diff(moment(now)));
                  var days = parseInt(diff.asDays()); -->
                <p > <strong *ngIf="todo.escalated" style="color: red;"> Over Due</strong> <strong *ngIf="!todo.escalated" style="color: #8a0a26;"><b *ngIf="todo.active" >{{moment.duration(now.diff(todo.firstescalation)).asDays() | number : '1.0-0' }} Days<i *ngIf="moment.duration(now.diff(todo.firstviolated)).asDays()  >= 1"> late</i><i *ngIf="moment.duration(now.diff(todo.firstviolated)).asDays() <= 0"> left</i></b><b *ngIf="!todo.active" >{{moment.duration(now.diff(todo.resolutionviolated)).asDays() | number : '1.0-0' }} Days <i *ngIf="moment.duration(now.diff(todo.resolutionviolated)).asDays()  >= 1"> late</i><i *ngIf="moment.duration(now.diff(todo.resolutionviolated)).asDays() <= 0"> left</i></b></strong>
                </p>
               </div>
               
               </div>
              </a>
               <hr/>
            </div>
          </div>
    
       
  </div>
  
  </div>

</div>



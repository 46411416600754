
<div class="page-wrapper">

    <div class="container-fluid">
        <div class="vendor-nav flex " >
            <div class="sub-nav-menus" >
                <i *ngFor="let module of modules">
                    <a *ngIf="module.page === 'Accounts'"   href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/account"> <img src="../assets/img/header-icons/marketplace-report.svg"> Accounts </a>
                    <a *ngIf="module.page === 'Department'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/department"> <img src="../assets/img/header-icons/marketplace-report.svg"> Department </a>
                    <a *ngIf="module.page === 'Modules'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/team"> <img src="../assets/img/header-icons/marketplace-report.svg"> Support Team </a>
                    <a *ngIf="module.page === 'Pages'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/module" > <img src="../assets/img/header-icons/marketplace-payments.svg" > Module </a>
                    <a *ngIf="module.page === 'Permission'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/role" > <img src="../assets/img/header-icons/marketplace-payments.svg" > Role </a>
                    <a *ngIf="module.page === 'Role'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/permission" > <img src="../assets/img/header-icons/marketplace-payments.svg" > Permission </a>
                    </i>
            </div>
        </div>
        
        <div class="container">
            <div class="row">
               
                    <div class="card">
                        <a href="javascript:void(0)"  [routerLink]="['/accounts','account']">
                           <div class="row">
                                  <div class="col-6 status" style="background-color : purple ;">
                                    <h2 class="title">Accounts</h2>
                                  </div>
                                  <div class="col-6 "><h2 class="title">{{numbers?.accounts}}</h2></div>
                           </div>
                        </a>
                    </div>
                   
                    
                    <div class="card" >
                        <a href="javascript:void(0)"  [routerLink]="['/accounts','department']">
                            <div class="row">
                                <div class="col-6 status" style="background-color:aquamarine ;">
                                    <h2 class="title">Departments</h2>
                                </div>
                                <div class="col-6 "><h2 class="title">{{numbers?.departments}}</h2></div>
                            </div>
                        </a>
                    </div>
                    <div class="card" >
                        <a href="javascript:void(0)"  [routerLink]="['/accounts','role']">
                        <div class="row">
                            <div class="col-6 status" style="background-color :goldenrod ;">
                                <h2 class="title">Support Teams</h2>
                            </div>
                            <div class="col-6 "><h2 class="title">{{numbers?.teams}}</h2></div>
                        </div>
                    </a>
                    </div>
              </div>
          </div>
        <router-outlet>
            
        </router-outlet>
    </div>
    
    

</div>

<div class="page-wrapper">

    <div class="container-fluid">
        <div class="vendor-nav flex " >
            <div class="sub-nav-menus" >
                <i *ngFor="let module of modules">
                    <a *ngIf="module.page === 'Accounts'"   href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/account"> <img src="../assets/img/header-icons/marketplace-report.svg"> Accounts </a>
                    <a *ngIf="module.page == 'Department'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/department"> <img src="../assets/img/header-icons/marketplace-report.svg"> Department </a>
                    <a *ngIf="module.page == 'Modules'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/team"> <img src="../assets/img/header-icons/marketplace-report.svg"> Support Team </a>
                    <a *ngIf="module.page == 'Pages'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/module" > <img src="../assets/img/header-icons/marketplace-payments.svg" > Module </a>
                    <a *ngIf="module.page == 'Permission'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/role" > <img src="../assets/img/header-icons/marketplace-payments.svg" > Role </a>
                    <a *ngIf="module.page == 'Role'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/permission" > <img src="../assets/img/header-icons/marketplace-payments.svg" > Permission </a>
                    <a *ngIf="module.page == 'SupportTeam'" href="javascript:void(0)" [routerLinkActive]="'active'" routerLink="/accounts/settings"> <img src="../assets/img/header-icons/marketplace-settings.svg"> Settings </a>
                    </i>
                    
            </div>
    
        </div>

    </div>
    
    

</div>





export class Business {
    id: string;
    name: string;
    nameE: string;
    tinNumber: string;
    mobile: string;
    email: string;
    website: string;
}
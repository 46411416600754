
<div class="container-fluid" id="login">

 
    <div class="form-container sign-in-container">
      <div class="navbar-header" >
        <a class="navbar-brand" routerLink="/">
            <!-- Logo icon -->
            <b class="logo-icon">
                <img src="assets/img/dashen.png" alt="homepage" class="light-logo" />
            </b>
        </a>
    </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
     
  

          <div >
            <div class="signin">
            <h1 class="font-weight-bold">Forgot Password</h1>
            <div class="form-group">
              <label>Email</label>
              <input type="text" formControlName="email" class="input-box" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email is invalid</div>
              </div>
          </div>
          
                 <button [disabled]="loading" class="signup" id="signUp">Reset Password
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                 </button>
         
        <div class="forgot" [routerLink]="['../login']">
             login?
            </div>
            <alert></alert>
          </div>
        </div>
      
      </form>
   
    </div>

    <div class="overlay-container">
      <div class="overlay">
        <div class="overlay-panel overlay-right">
          <img src="assets/img/forget.png"  />
          <h1 class="boldfont">Relax, We get you back!</h1>
            <!-- <p>Don't worry, you can reset your password by using Email</p> -->
     
        </div>
      </div>
    </div>
  </div>





<div class="page-wrapper">

    <div class="container-fluid">
  
        <form class="brandForm">

            <div class="row products-section">
                <div class="col-12">
                    <div class="breadcrumbs-row noborder">
                        <div class="bc-col">
                            <ul>
                                <li><a href="javascript:void(0)"></a></li>
                                <li> </li>
                            </ul>
                        </div>
                        <div class="bc-col-btn ap-col-btn">
                            <button class="btn btn-save" type="submit" ><img
                                    src="assets/img/tick-round-white.png"> 
                            </button>
                            <button class="btn btn-cancel" ><img
                                    src="assets/img/close-white-ico.png"> 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="flex addproduct-wrap">
                <div class="card ap-general">
                    <div class="ap-header">
                        <span> 1 </span> 
                    </div>
                    <div class="flex ap-body">
                        <div class="ap-body-col">
                            <div class="form-group">
                                <label> <sup>*</sup></label>
                                <input type="text" class="form-control" placeholder="test" formControlName="name" >
                                <div class="validation-error" >
                                   
                                </div>
                                <div class="validation-error" >
                                    Must be max 64 chracters long
                                </div>
                            </div>
                        </div>
                        <!-- Col -->
                        <div class="ap-body-col">
                            <div class="form-group">
                                <label class="image-upload-container">
                                    <sup></sup></label>
                                <input type="file" accept="image/*" class="form-control"  #filePath style="display:none" >
                                <input type="hidden" name="fileHidden" formControlName="image"/>
                            </div>
                            <button class="btn btn-success" >Upload Pic</button>
                            <p style="font-size: 10px">Support (.png .jpg .jpeg) Format & below 2MB Files allowed</p>
                            <div class="validation-error" >
                                
                            </div>
                            <div class="upload-error validation-error">Image should be less than 2MB</div>
                            <div class="upload-error validation-error">Please upload image only(.png,.jpg,.jpeg)</div>
                        </div>
        
                        <!-- col for updating image -->
                        <!--  -->
                        <div >
                            <img class="brand-img" alt="">
                        </div>
        
                        <div class="ap-body-col">
                            <div class="form-group">
                                <label><sup>*</sup></label>
                                <input type="text" appOnlyNumber class="form-control" placeholder="sortOrder" formControlName="sortOrder" >
                                <div class="validation-error" >
                                    
                                </div>
                            </div>
                        </div>
                        <div class="ap-body-col">
                            <div class="form-group">
                                <label> <sup>*</sup></label>
                                <select class="form-control custom-select" formControlName="status" placeholder="status" >
                                    <option value=''>- -Select Status- -</option>
                                    <option value="1">Enabled</option>
                                    <option value="0">Disabled</option>
                                </select>
                                <div class="validation-error">
                                  
                                </div>
        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    
    

</div>

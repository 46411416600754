import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AccountService,CrmService } from '../_services';

@Component({ templateUrl: 'view.component.html',styleUrls: ['./view.component.scss'] })
export class ViewComponent {
    account = this.accountService.accountValue;
    modules : any[];
    numbers;
    constructor(
        private router: Router,
        private accountService: AccountService,
        private crmService: CrmService,
        public titleService: Title
    ) {
    }
    ngOnInit(): void {
        this.accountService.getPagePermission('Accounts',this.account.roleId)
        .subscribe(modules => this.modules = modules);  
        this.accountService.getAccountStats()
        .subscribe(numbers => this.numbers = numbers);
      }
}
<div class="container">
    <div *ngIf="alerts.length" >
        <div class="m-3">
            <!-- <div *ngFor="let alert of alerts" class="{{cssClasses(alert)}}">
                <a class="close" (click)="removeAlert(alert)">&times;</a>
                <span style="color: yellow;"> {{alert.message}}</span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> -->
            {{account | json}}
            <div class="notification-alert alert alert-success alert-dismissible fade show" role="alert" *ngFor="let alert of alerts">
                <strong> {{alert.message}}</strong>
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        </div>
    </div>
</div>


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppModule} from '../app.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// components
import {AccountComponent} from './account.component';
import { AccountRoutingModule } from './account-routing.module';
import { AccountSharedModule } from './account-shared.module';

@NgModule({
    declarations: [
        AccountComponent,
    ],imports: [
        CommonModule,
        AccountSharedModule,
        AccountRoutingModule
    ],
    bootstrap: [],
    entryComponents: []
})

export class AccountModule {
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AccountService, AlertService } from '../../_services';


@Component({
    selector: 'app-spurt-login',
    templateUrl: './login.component.html'
  })
export class LogoutComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        console.log('logout')
        this.accountService.logout()
        this.router.navigateByUrl('auth/login');
    }
}


export class Education {
    id: string;
    qualification: string;
    institute: string;
    startDate: string;
    endDate: string;
    joinedDate: string;
    active: string;
    created: string;
    updated: string;
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Pricing,PlanHint } from '../_models';
const baseUrl = `${environment.apiUrl}/planhint`;

@Injectable({ providedIn: 'root' })
export class PlanHintService {
    private planHintSubject: BehaviorSubject<PlanHint>;
    public planHint: Observable<PlanHint>;
    private PlanHintSubject: BehaviorSubject<PlanHintService>;
    public PlanHint: Observable<PlanHintService>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.planHintSubject = new BehaviorSubject<PlanHint>(null);
        this.planHint = this.planHintSubject.asObservable();
    }

    public get planHintValue(): PlanHint {
        return this.planHintSubject.value;
    }

   

    getAll() {
        return this.http.get<PlanHint[]>(baseUrl);
    }

    getById(id: string) {
        return this.http.get<PlanHint>(`${baseUrl}/${id}`);
    }

    getAllProductWithPrice() {
        return this.http.get<PlanHint[]>(`${baseUrl}/prcing`);
    }

    getAllPricingByIds(id: string) {
        return this.http.get<PlanHint[]>(`${baseUrl}/pricingdetail/${id}`);
    }

    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((planHint: any) => {
                    // publish updated membership to subscribers
                    planHint = { ...this.planHintValue, ...planHint };
                    this.planHintSubject.next(planHint);
              
                return planHint;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}
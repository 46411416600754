import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {Announcement, Callerid, Device,Entries,Extention,Incoming,Indb,Ivr,Ivrdetail,Ivrmenu,Recording,Queue,Rgroup,Sip,Timeview,Team,Cdr,TimeGroup} from '../_models';


const announcementUrl = `${environment.apiUrl}/announcement`;
const calleridUrl = `${environment.apiUrl}/callerid`;
const deviceUrl = `${environment.apiUrl}/device`;
const entriesUrl = `${environment.apiUrl}/entries`;
const extentionUrl = `${environment.apiUrl}/extention`;
const incomingUrl = `${environment.apiUrl}/incoming`;
const indbUrl = `${environment.apiUrl}/indb`;
const ivrUrl = `${environment.apiUrl}/ivr`;
const ivrdetailUrl = `${environment.apiUrl}/ivrdetail`;
const ivrmenuUrl = `${environment.apiUrl}/ivrmenu`;
const recordingUrl = `${environment.apiUrl}/recording`;
const queueUrl = `${environment.apiUrl}/queue`;
const rgroupUrl = `${environment.apiUrl}/rgroup`;
const sipUrl = `${environment.apiUrl}/sip`;
const timeviewUrl = `${environment.apiUrl}/timeview`;
const teamUrl = `${environment.apiUrl}/team`;
const cdrUrl = `${environment.apiUrl}/cdr`;
const timegrouptUrl = `${environment.apiUrl}/timegroup`;

@Injectable({ providedIn: 'root' })
export class CallCenterService {
    private timegroupSubject: BehaviorSubject<TimeGroup>;
    public timegroup: Observable<TimeGroup>;
    private announcementSubject: BehaviorSubject<Announcement>;
    public announcement: Observable<Announcement>;
    private calleridSubject: BehaviorSubject<Callerid>;
    public callerid: Observable<Callerid>;
    private deviceSubject: BehaviorSubject<Device>;
    public device: Observable<Device>;
    private entriesSubject: BehaviorSubject<Entries>;
    public entries: Observable<Entries>;
    private extentionSubject: BehaviorSubject<Extention>;
    public extention: Observable<Extention>;
    private incomingSubject: BehaviorSubject<Incoming>;
    public incoming: Observable<Incoming>;
    private commentSubject: BehaviorSubject<Comment>;
    public comment: Observable<Comment>;
    private indbSubject: BehaviorSubject<Indb>;
    public indb: Observable<Indb>;
    private ivrSubject: BehaviorSubject<Ivr>;
    public ivr: Observable<Ivr>;
    private ivrdetailSubject: BehaviorSubject<Ivrdetail>;
    public ivrdetail: Observable<Ivrdetail>;
    private ivrmenuSubject: BehaviorSubject<Ivrmenu>;
    public ivrmenu: Observable<Ivrmenu>;
    private recordingSubject: BehaviorSubject<Recording>;
    public recording: Observable<Recording>;
    private queueSubject: BehaviorSubject<Queue>;
    public queue: Observable<Queue>;
    private rgroupSubject: BehaviorSubject<Rgroup>;
    public rgroup: Observable<Rgroup>;
    private sipSubject: BehaviorSubject<Sip>;
    public sip: Observable<Sip>;
    private timeviewSubject: BehaviorSubject<Timeview>;
    public timeview: Observable<Timeview>;
    private teamSubject: BehaviorSubject<Team>;
    public team: Observable<Team>;
    private cdrSubject: BehaviorSubject<Cdr>;
    public cdr: Observable<Cdr>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.timegroupSubject = new BehaviorSubject<TimeGroup>(null);
        this.timegroup = this.timegroupSubject.asObservable();
        this.announcementSubject = new BehaviorSubject<Announcement>(null);
        this.announcement = this.announcementSubject.asObservable();
        this.calleridSubject = new BehaviorSubject<Callerid>(null);
        this.callerid = this.calleridSubject.asObservable();
        this.deviceSubject = new BehaviorSubject<Device>(null);
        this.device = this.deviceSubject.asObservable();
        this.entriesSubject = new BehaviorSubject<Entries>(null);
        this.entries = this.entriesSubject.asObservable();
        this.indbSubject = new BehaviorSubject<Indb>(null);
        this.indb = this.indbSubject.asObservable();
        this.incomingSubject = new BehaviorSubject<Incoming>(null);
        this.incoming = this.incomingSubject.asObservable();
        this.extentionSubject = new BehaviorSubject<Extention>(null);
        this.extention = this.extentionSubject.asObservable();
        this.commentSubject = new BehaviorSubject<Comment>(null);
        this.comment = this.commentSubject.asObservable();
        this.ivrSubject = new BehaviorSubject<Ivr>(null);
        this.ivr = this.ivrSubject.asObservable();
        this.ivrdetailSubject = new BehaviorSubject<Ivrdetail>(null);
        this.ivrdetail = this.ivrdetailSubject.asObservable();
        this.ivrmenuSubject = new BehaviorSubject<Ivrmenu>(null);
        this.ivrmenu = this.ivrmenuSubject.asObservable();
        this.queueSubject = new BehaviorSubject<Queue>(null);
        this.queue = this.queueSubject.asObservable();
        this.rgroupSubject = new BehaviorSubject<Rgroup>(null);
        this.rgroup = this.rgroupSubject.asObservable();
        this.sipSubject = new BehaviorSubject<Sip>(null);
        this.sip = this.sipSubject.asObservable();
        this.timeviewSubject = new BehaviorSubject<Timeview>(null);
        this.timeview = this.timeviewSubject.asObservable();
        this.teamSubject = new BehaviorSubject<Team>(null);
        this.team = this.teamSubject.asObservable();
        this.cdrSubject = new BehaviorSubject<Cdr>(null);
        this.cdr = this.cdrSubject.asObservable();
    }

    public get timegroupValue(): TimeGroup {
        return this.timegroupSubject.value;
    }

    public get announcementValue(): Announcement {
        return this.announcementSubject.value;
    }

    public get calleridValue(): Callerid {
        return this.calleridSubject.value;
    }

    public get deviceValue(): Device {
        return this.deviceSubject.value;
    }
    public get entriesValue(): Entries {
        return this.entriesSubject.value;
    }
    public get commentValue(): Comment {
        return this.commentSubject.value;
    }

    public get ivrdetailValue(): Ivrdetail {
        return this.ivrdetailSubject.value;
    }

    public get extentionValue(): Extention {
        return this.extentionSubject.value;
    }
    public get incomingValue(): Incoming {
        return this.incomingSubject.value;
    }

    public get indbValue(): Indb {
        return this.indbSubject.value;
    }

    public get sipValue(): Sip {
        return this.sipSubject.value;
    }

    public get ivrValue(): Ivr {
        return this.ivrSubject.value;
    }

    public get ivrmenuValue(): Ivrmenu {
        return this.ivrmenuSubject.value;
    }

    public get queueValue(): Queue {
        return this.queueSubject.value;
    }

    public get recordingValue(): Recording {
        return this.recordingSubject.value;
    }

    public get rgroupValue(): Rgroup {
        return this.rgroupSubject.value;
    }

    public get timeviewValue(): Timeview {
        return this.timeviewSubject.value;
    }

    public get teamValue(): Team {
        return this.teamSubject.value;
    }

    public get cdrValue(): Cdr {
        return this.cdrSubject.value;
    }

    getAllCdr() {
        return this.http.get<Cdr[]>(cdrUrl);
    }

    getSummary() {
        return this.http.get<Cdr[]>(`${cdrUrl}/summary`);
    }
    getCallMonthlyStatsSummary() {
        return this.http.get<Cdr[]>(`${cdrUrl}/call/monthly/summary`);
    }
    getCallyearlyStatsSummary() {
        return this.http.get<Cdr[]>(`${cdrUrl}/call/yearly/summary`);
    }
    getDailyPeakHourStats() {
        return this.http.get<Cdr[]>(`${cdrUrl}/call/daily/peakhour`);
    }
    getMonthlyCallStats() {
        return this.http.get<Cdr[]>(`${cdrUrl}/call/monthly/stats`);
    }
    getYearlyCallStats() {
        return this.http.get<Cdr[]>(`${cdrUrl}/call/yearly/stats`);
    }
    getDetail(start: string,end: string,type: string,status: string,grpnum: string) {
        return this.http.get<Cdr[]>(`${cdrUrl}/detail/${start}/${end}/${type}/${status}/${grpnum}`);
    }
    getHourly(start: string,end: string,type: string,status: string,grpnum: string) {
        return this.http.get<Cdr[]>(`${cdrUrl}/hourly/${start}/${end}/${type}/${status}/${grpnum}`);
    }
    getDaily(start: string,end: string,type: string,status: string,grpnum: string) {
        return this.http.get<Cdr[]>(`${cdrUrl}/daily/${start}/${end}/${type}/${status}/${grpnum}`);
    }
    getMonthly(year: string,type: string,status: string,grpnum: string) {
        return this.http.get<Cdr[]>(`${cdrUrl}/monthly/${year}/${type}/${status}/${grpnum}`);
    }

    getAllTimeGroup() {
        return this.http.get<TimeGroup[]>(timegrouptUrl);
    }

    getAllAnnouncement() {
        return this.http.get<Announcement[]>(announcementUrl);
    }

    getAllCallerid() {
        return this.http.get<Callerid[]>(calleridUrl);
    }

    getAllDevice() {
        return this.http.get<Device[]>(deviceUrl);
    }
    getAllEntries() {
        return this.http.get<Entries[]>(entriesUrl);
    }
    getAllIndb() {
        return this.http.get<Indb[]>(indbUrl);
    }
    getAllSip() {
        return this.http.get<Sip[]>(sipUrl);
    }

    getAllExtention() {
        return this.http.get<Extention[]>(extentionUrl);
    }
    getAllIncoming() {
        return this.http.get<Incoming[]>(incomingUrl);
    }
    getAllIvrdetail() {
        return this.http.get<Ivrdetail[]>(ivrdetailUrl);
    }
    getAllIvrmenu() {
        return this.http.get<Ivrmenu[]>(ivrmenuUrl);
    }
    getAllIvr() {
        return this.http.get<Ivr[]>(ivrUrl);
    }
    getAllQueue() {
        return this.http.get<Queue[]>(queueUrl);
    }
    getAllRecording() {
        return this.http.get<Recording[]>(recordingUrl);
    }
    getAllRgroup() {
        return this.http.get<Rgroup[]>(rgroupUrl);
    }

    getAllTimeview() {
        return this.http.get<Timeview[]>(timeviewUrl);
    }

    getAllTeam() {
        return this.http.get<Team[]>(teamUrl);
    }

   //country Method
   getTimeGroupById(id: string) {
    return this.http.get<TimeGroup>(`${timegrouptUrl}/${id}`);
}

    getAnnouncementById(id: string) {
        return this.http.get<Announcement>(`${announcementUrl}/${id}`);
    }

    getCalleridById(id: string) {
        return this.http.get<Callerid>(`${calleridUrl}/${id}`);
    }

    getDeviceById(id: string) {
        return this.http.get<Device>(`${deviceUrl}/${id}`);
    }
    getEntriesById(id: string) {
        return this.http.get<Entries>(`${entriesUrl}/${id}`);
    }
    
    getIndbById(id: string) {
        return this.http.get<Indb>(`${indbUrl}/${id}`);
    }

    getExtentionById(id: string) {
        return this.http.get<Extention>(`${extentionUrl}/${id}`);
    }

    getIncomingById(id: string) {
        return this.http.get<Incoming>(`${incomingUrl}/${id}`);
    }
    getIvrdetailById(id: string) {
        return this.http.get<Ivrdetail>(`${ivrdetailUrl}/${id}`);
    }
    getSipById(id: string) {
        return this.http.get<Sip>(`${sipUrl}/${id}`);
    }
    getQueueById(id: string) {
        return this.http.get<Queue>(`${queueUrl}/${id}`);
    }

    getRecordingById(id: string) {
        return this.http.get<Recording>(`${recordingUrl}/${id}`);
    }

    getIvrmenuById(id: string) {
        return this.http.get<Ivrmenu>(`${ivrmenuUrl}/${id}`);
    }
    getMenus(id: string) {
        return this.http.get<Ivrmenu[]>(`${ivrmenuUrl}/menu/${id}`);
    }

    getRgroupById(id: string) {
        return this.http.get<Rgroup>(`${rgroupUrl}/${id}`);
    }

    getTimeviewById(id: string) {
        return this.http.get<Timeview>(`${timeviewUrl}/${id}`);
    }

    getTeamById(id: string) {
        return this.http.get<Team>(`${teamUrl}/${id}`);
    }

    createAnnouncement(params) {
        return this.http.post(announcementUrl, params);
    }
    
    updateAnnouncement(id, params) {
        return this.http.put(`${announcementUrl}/${id}`, params)
            .pipe(map((announcement: any) => {
                    this.announcement = { ...this.announcementValue, ...announcement };
                    this.announcementSubject.next(announcement);
              
                return announcement;
            }));
    }
    deleteAnnouncement(id: string) {
        return this.http.delete(`${announcementUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createCallerid(params) {
        return this.http.post(calleridUrl, params);
    }
    
    updateCallerid(id, params) {
        return this.http.put(`${calleridUrl}/${id}`, params)
            .pipe(map((callerid: any) => {
                    this.callerid = { ...this.calleridValue, ...callerid };
                    this.calleridSubject.next(callerid);
              
                return callerid;
            }));
    }
    deleteCallerid(id: string) {
        return this.http.delete(`${calleridUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createDevice(params) {
        return this.http.post(deviceUrl, params);
    }
    
    updateDevice(id, params) {
        return this.http.put(`${deviceUrl}/${id}`, params)
            .pipe(map((device: any) => {
                    this.device = { ...this.deviceValue, ...device };
                    this.deviceSubject.next(device);
              
                return device;
            }));
    }
    deleteDevice(id: string) {
        return this.http.delete(`${deviceUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createEntries(params) {
        return this.http.post(entriesUrl, params);
    }
    
    updateEntries(id, params) {
        return this.http.put(`${deviceUrl}/${id}`, params)
            .pipe(map((entries: any) => {
                    this.entries = { ...this.entriesValue, ...entries };
                    this.entriesSubject.next(entries);
              
                return entries;
            }));
    }
    deleteEntries(id: string) {
        return this.http.delete(`${entriesUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createQueue(params) {
        return this.http.post(queueUrl, params);
    }
    
    updateQueue(id, params) {
        return this.http.put(`${queueUrl}/${id}`, params)
            .pipe(map((queue: any) => {
                    this.queue = { ...this.queueValue, ...queue };
                    this.queueSubject.next(queue);
              
                return queue;
            }));
    }
    deleteQueue(id: string) {
        return this.http.delete(`${queueUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createRgroup(params) {
        return this.http.post(rgroupUrl, params);
    }
    
    updateRgroup(id, params) {
        return this.http.put(`${rgroupUrl}/${id}`, params)
            .pipe(map((rgroup: any) => {
                    this.rgroup = { ...this.rgroupValue, ...rgroup };
                    this.rgroupSubject.next(rgroup);
              
                return rgroup;
            }));
    }
    deleteRgroup(id: string) {
        return this.http.delete(`${rgroupUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createTimeview(params) {
        return this.http.post(timeviewUrl, params);
    }
    
    updateTimeview(id, params) {
        return this.http.put(`${timeviewUrl}/${id}`, params)
            .pipe(map((timeview: any) => {
                    this.timeview = { ...this.timeviewValue, ...timeview };
                    this.timeviewSubject.next(timeview);
              
                return timeview;
            }));
    }
    deleteTimeview(id: string) {
        return this.http.delete(`${timeviewUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createTeam(params) {
        return this.http.post(teamUrl, params);
    }
    
    updateTeam(id, params) {
        return this.http.put(`${teamUrl}/${id}`, params)
            .pipe(map((team: any) => {
                    this.team = { ...this.teamValue, ...team };
                    this.teamSubject.next(team);
              
                return team;
            }));
    }
    deleteTeam(id: string) {
        return this.http.delete(`${teamUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createIvrmenu(params) {
        return this.http.post(ivrmenuUrl, params);
    }
    
    updateIvrmenu(id, params) {
        return this.http.put(`${ivrmenuUrl}/${id}`, params)
            .pipe(map((ivrmenu: any) => {
                    this.ivrmenu = { ...this.ivrmenuValue, ...ivrmenu };
                    this.ivrmenuSubject.next(ivrmenu);
                return ivrmenu;
            }));
    }
    deleteIvrmenu(id: string) {
        return this.http.delete(`${ivrmenuUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createExtention(params) {
        return this.http.post(extentionUrl, params);
    }
    
    updateExtention(id, params) {
        return this.http.put(`${extentionUrl}/${id}`, params)
            .pipe(map((extention: any) => {
                    this.extention = { ...this.extentionValue, ...extention };
                    this.extentionSubject.next(extention);
                return extention;
            }));
    }
    deleteExtention(id: string) {
        return this.http.delete(`${extentionUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createIndb(params) {
        return this.http.post(indbUrl, params);
    }
    
    updateIndb(id, params) {
        return this.http.put(`${indbUrl}/${id}`, params)
            .pipe(map((indb: any) => {
                    this.indb = { ...this.indbValue, ...indb };
                    this.indbSubject.next(indb);
                return indb;
            }));
    }
    deleteIndb(id: string) {
        return this.http.delete(`${indbUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createSip(params) {
        return this.http.post(sipUrl, params);
    }
    
    updateSip(id, params) {
        return this.http.put(`${sipUrl}/${id}`, params)
            .pipe(map((sip: any) => {
                    this.sip = { ...this.sipValue, ...sip };
                    this.sipSubject.next(sip);
                return sip;
            }));
    }
    deleteSip(id: string) {
        return this.http.delete(`${sipUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createIvr(params) {
        return this.http.post(ivrUrl, params);
    }
    
    updateIvr(id, params) {
        return this.http.put(`${ivrUrl}/${id}`, params)
            .pipe(map((ivr: any) => {
                    this.ivr = { ...this.ivrValue, ...ivr };
                    this.ivrSubject.next(ivr);
                return ivr;
            }));
    }
    deleteIvr(id: string) {
        return this.http.delete(`${ivrUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createIvrdetail(params) {
        return this.http.post(ivrdetailUrl, params);
    }
    
    updateIvrdetail(id, params) {
        return this.http.put(`${ivrdetailUrl}/${id}`, params)
            .pipe(map((ivrdetail: any) => {
                    this.ivrdetail = { ...this.ivrdetailValue, ...ivrdetail };
                    this.ivrdetailSubject.next(ivrdetail);
                return ivrdetail;
            }));
    }
    deleteIvrdetail(id: string) {
        return this.http.delete(`${ivrdetailUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createRecording(params) {
        return this.http.post(recordingUrl, params);
    }
    
    updateRecording(id, params) {
        return this.http.put(`${recordingUrl}/${id}`, params)
            .pipe(map((recording: any) => {
                    this.recording = { ...this.recordingValue, ...recording };
                    this.recordingSubject.next(recording);
                return recording;
            }));
    }
    deleteRecording(id: string) {
        return this.http.delete(`${recordingUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createIncoming(params) {
        return this.http.post(incomingUrl, params);
    }
    
    updateIncoming(id, params) {
        return this.http.put(`${incomingUrl}/${id}`, params)
            .pipe(map((incoming: any) => {
                    this.incoming = { ...this.incomingValue, ...incoming };
                    this.incomingSubject.next(incoming);
                return incoming;
            }));
    }
    deleteIncoming(id: string) {
        return this.http.delete(`${incomingUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createTimeGroup(params) {
        return this.http.post(timegrouptUrl, params);
    }
    
    updateTimeGroup(id, params) {
        return this.http.put(`${timegrouptUrl}/${id}`, params)
            .pipe(map((timegroup: any) => {
                    this.timegroup = { ...this.timegroupValue, ...timegroup };
                    this.timegroupSubject.next(timegroup);
                return timegroup;
            }));
    }
    deleteTimegroup(id: string) {
        return this.http.delete(`${timegrouptUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
}


export class Pricing {
    id: string;
    tagline: string;
    plan: string;
    price: string;
    discount: string;
    dateAvailable: string;
    describtion: string;
    colorCode: string;
}



import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Pricing,PlanFaq } from '../_models';
const baseUrl = `${environment.apiUrl}/planfaq`;

@Injectable({ providedIn: 'root' })
export class PlanFaqService {
    private planFaqSubject: BehaviorSubject<PlanFaq>;
    public planFaq: Observable<PlanFaq>;
    private PlanFaqSubject: BehaviorSubject<PlanFaqService>;
    public PlanFaq: Observable<PlanFaqService>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.planFaqSubject = new BehaviorSubject<PlanFaq>(null);
        this.planFaq = this.planFaqSubject.asObservable();
    }

    public get planFaqValue(): PlanFaq {
        return this.planFaqSubject.value;
    }

   

    getAll() {
        return this.http.get<PlanFaq[]>(baseUrl);
    }

    getById(id: string) {
        return this.http.get<PlanFaq>(`${baseUrl}/${id}`);
    }

    getAllProductWithPrice() {
        return this.http.get<PlanFaq[]>(`${baseUrl}/prcing`);
    }

    getAllPricingByIds(id: string) {
        return this.http.get<PlanFaq[]>(`${baseUrl}/pricingdetail/${id}`);
    }

    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((planFaq: any) => {
                    // publish updated membership to subscribers
                    planFaq = { ...this.planFaqValue, ...planFaq };
                    this.planFaqSubject.next(planFaq);
              
                return planFaq;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}
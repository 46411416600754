import { Component ,OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService,ApplcationService } from '../../_services';
import { first } from 'rxjs/operators';

@Component({
    selector: 'vendor-layout',
    templateUrl: './prcing.component.html',
    styleUrls: ['./prcing.component.scss'],
  })
export class PrcingComponent implements OnInit {
    app;
    id: string;
    account = this.accountService.accountValue;

    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private accountService: AccountService,
      private applcationService: ApplcationService
    ) { }
           
    ngOnInit() {
      this.id = this.route.snapshot.params['id'];
      
        this.applcationService.getAllPricingByIds(this.id)
            .pipe(first())
            .subscribe(app => this.app = app);
    }
}
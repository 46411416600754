import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Applcation,Pricing } from '../_models';
const baseUrl = `${environment.apiUrl}/applcation`;
const imageUrl = `${environment.apiUrl}/preview`;

@Injectable({ providedIn: 'root' })
export class ApplcationService {
    private applcationSubject: BehaviorSubject<Applcation>;
    public applcation: Observable<Applcation>;
    private pricingSubject: BehaviorSubject<Pricing>;
    public pricing: Observable<Pricing>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.applcationSubject = new BehaviorSubject<Applcation>(null);
        this.applcation = this.applcationSubject.asObservable();
    }

    public get applcationValue(): Applcation {
        return this.applcationSubject.value;
    }

   

    getAll() {
        return this.http.get<Applcation[]>(baseUrl);
    }
    view() {
        return this.http.get<Applcation[]>(`${baseUrl}/view`);
    }
    getAllPricingByIds(id: string) {
        return this.http.get<Applcation[]>(`${baseUrl}/price/${id}`);
    }

    getAllPricingByCode(appCode: string) {
        return this.http.get<Applcation[]>(`${baseUrl}/prcing/${appCode}`);
    }

    getById(id: string) {
        return this.http.get<Applcation>(`${baseUrl}/${id}`);
    }
    preview(appCode: string,image: string) {
        return this.http.get<Applcation>(`${imageUrl}/${appCode}/${image}`);
    }

    getAllAppWithPrice() {
        return this.http.get<Applcation[]>(`${baseUrl}/prcing`);
    }

     getAllPricingDetails(id: string) {
        return this.http.get<Applcation[]>(`${baseUrl}/prcing/${id}`);
    }

    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((applcation: any) => {
                    // publish updated membership to subscribers
                    applcation = { ...this.applcationValue, ...applcation };
                    this.applcationSubject.next(applcation);
              
                return applcation;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}
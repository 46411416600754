
   <app-vendor-header></app-vendor-header>

            <div class="p-4">
                <div class="container">
                    <h1>Hi {{account.firstName}} {{account.lastName}}!</h1>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{app.application.applcationName}}</h5>
                  <p class="card-text">
                    {{app.application.describtion}}
                  </p>
                </div>
              </div>
    
              
            <div class="container">
                <!--Section: Content-->
                <section class="text-center">
                  <h4 class="mb-4"><strong>Pricing Plan</strong></h4>
          
                  <div class="btn-group mb-4" role="group" aria-label="Basic example">
                 
                  </div>
          
                  <div class="row gx-lg-5">
          
                  
          
                    <!--Grid column-->
                    <div class="col-lg-6 col-md-6 mb-4" >
          
                      <!-- Card -->
                      <div class="card" >
          
                        <div class="card-header" style="background-color: #FF6700;">

                          <p class="text-uppercase small mb-2"><strong>{{pricing.tagline}} </strong></p>
                          <h5 class="mb-0">{{pricing.price}} Birr / Month</h5>
                        </div>
          
                        <div class="card-body" >
                          <ul class="list-group list-group-flush"  *ngFor="let feature of pricing.appFeatures">
                            <li class="list-group-item">{{feature.name}} </li>
                          </ul>
                        </div>
          
     

                        <div class="card-footer bg-white py-3">
                          <a [routerLink]="['/pricing', app.application.id,'pricing',pricing.id]"> <button type="button" class="btn btn-danger btn-sm"  [style.background-color]="pricing.colorCode">Learn More</button> </a>
                        </div>
          
                      </div>
                      <!-- Card -->
          
                    </div>

                    <!--Grid column-->
                    <div class="col-lg-6 col-md-6 mb-4" >
          
                      <!-- Card -->
                      <div class="card" >
          
                        <div class="card-header" style="background-color: #FF6700;">

                          <p class="text-uppercase small mb-2"><strong>Billing Detail</strong></p>
                      
                        </div>
          
                        <div class="card-body" >

        <article class="part card-details">
          <div class="container mt-5" id="container">
          <div class="form-container sign-in-container">
			
           
              
              <form>
                <div class="row mt-4">
                  <div class="col-md-6 form-group">
                      <label for="card-name">First Name</label>
                      <input type="text" class="form-control" id="firstName" name="firstName" placeholder required value="{{account.firstName}}">
                      <div class="invalid-feedback">
                        First Name
                      </div>
                    </div>
        
                    <div class="col-md-6 form-group">
                      <label for="lastName">Last Name</label>
                      <input type="text" class="form-control" id="lastName" placeholder required value="{{account.lastName}}">
                      <div class="invalid-feedback">
                        Last Name is required
                      </div>
                    </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6 form-group">
                      <label for="card-name">Email</label>
                      <input type="text" class="form-control" id="email" name="email" placeholder required value="{{account.email}}">
                      <div class="invalid-feedback">
                       Email address required
                      </div>
                    </div>
        
                    <div class="col-md-6 form-group">
                      <label for="lastName">Mobile</label>
                      <input type="text" class="form-control" id="mobile" placeholder required value="{{account.mobile}}">
                      <div class="invalid-feedback">
                        Mobile number is required
                      </div>
                    </div>
                </div>
        
                <hr class="mb-4">
        
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label for="country">Country</label>
                    <select type="text" class="form-control" id="country">
                      <option value>Choose...</option>
                      <option>United Kingdom</option>
                    </select>
                    <div class="invalid-feedback">
                      Please select a valid country.
                    </div>
                  </div>
        
                  <div class="col-md-4 form-group">
                    <label for="city">City</label>
                    <select type="text" class="form-control" id="city">
                      <option value>Choose...</option>
                      <option>London</option>
                    </select>
                    <div class="invalid-feedback">
                      Please provide a valid city.
                    </div>
                  </div>
                    
                  <div class="col-md-4 form-group">
                    <label for="postcode">Postcode</label>
                    <select type="text" class="form-control" id="postcode">
                      <option value>Choose...</option>
                      <option>NW6 2LS</option>
                    </select>
                    <div class="invalid-feedback">
                      Postcode required.
                    </div>
                  </div>
                </div>
        
                <hr>
                <h4 class="mb-4">Payment Method</h4>
                
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="credit" name="payment-method" checked required>
                  <label for="credit" class="form-check-label">Credit Card</label>
                </div>
        
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="debit" name="payment-method" required>
                  <label for="debit" class="form-check-label">Debit Card</label>
                </div>
        
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="paypal" name="payment-method"  required>
                  <label for="paypal" class="form-check-label">PayPal</label>
                </div>
              
        
                  <hr class="mb-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="shipping-adress"> 
                      Shipping address is the same as my billing address
                    <label for="shipping-adress" class="form-check-label"></label>
                  </div>
                  <hr class="mb-4">
                  <button class="btn btn-primary bt-lg btn-block" type="submit">Continue to Checkout</button>
              </form>
            </div>
          </div>
        </article>
        <div class="part bg"></div>


                        </div>
          
                      </div>
                      <!-- Card -->
          
                    </div>
                   
                  </div>
                </section>
                <!--Section: Content-->
              </div>
      






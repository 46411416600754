

export class Employee {
    id: string;
    nameA: string;
    nameE: string;
    dateOfBirth: string;
    gender: string;
    maritalStatus: string;
    Ethnicity: string;
    drivingLicenseNo: string;
    tinNumber: string;
    govermentID: boolean;
    employmentStatus: string;
    department: string;
    jobTitle: string;
    homePhone: string;
    mobile: string;
    email: string;
    workEmail: string;
    supervisor: string;
    IndirectSupervisor: boolean;
    firstApprover: string;
    secondApprover: string;
    thirdApprover: boolean;
    joinedDate: string;
    active: string;
    created: string;
    updated: string;
    deleted: string;
    createdBy: string;
    updatedBy: string;
    deletedBy: string;
}
import { Component } from '@angular/core';


import { AccountService } from '../_services';

@Component({
    selector: 'vendor-layout',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
  })
export class AccountComponent {
    account = this.accountService.accountValue;

    constructor(private accountService: AccountService) { }
}
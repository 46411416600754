

export class Contact {
    id: string;
    name: string;
    relationship: string;
    mobile: string;
    active: string;
    created: string;
    updated: string;
}
import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {Ticket, TicketReport,Task, Project,Activity,SupportTeam,CheckList,Comment,Contact,Customer,Group,Meeting,Industry,Ownership,Retting,Sector,Type,Vendor,Department,KnowlageBase,Wiki,News,Faq,Posstion,Note ,TicketUpdate,Resolution,RtmZone,RtmMapping,Calllog, Language,ProjectMember,Help,Agent, TaskReport,Distrcit,Branch,AccountNumber,Transaction} from '../_models';

const accountnumberUrl = `${environment.apiUrl}/accountnumber`;
const transactionUrl = `${environment.apiUrl}/transaction`;
const agentUrl = `${environment.apiUrl}/agent`;
const helpUrl = `${environment.apiUrl}/help`;
const calllogUrl = `${environment.apiUrl}/calllog`;
const ticketUrl = `${environment.apiUrl}/ticket`;
const ticketUpdateUrl = `${environment.apiUrl}/tickethisitry`;
const ticketreportUrl = `${environment.apiUrl}/ticketreport`;
const taskreportUrl = `${environment.apiUrl}/taskreport`;
const taskUrl = `${environment.apiUrl}/task`;
const projectUrl = `${environment.apiUrl}/project`;
const projectmemberUrl = `${environment.apiUrl}/projectmember`;
const checklistUrl = `${environment.apiUrl}/checklist`;
const ownershipUrl = `${environment.apiUrl}/ownership`;
const districtUrl = `${environment.apiUrl}/district`;
const branchUrl = `${environment.apiUrl}/branch`;
const contactUrl = `${environment.apiUrl}/contact`;
const commentUrl = `${environment.apiUrl}/comment`;
const customerUrl = `${environment.apiUrl}/customer`;
const groupUrl = `${environment.apiUrl}/customergroup`;
const meetingUrl = `${environment.apiUrl}/meeting`;
const industryUrl = `${environment.apiUrl}/industry`;
const rettingUrl = `${environment.apiUrl}/retting`;
const sectorUrl = `${environment.apiUrl}/sector`;
const vendorUrl = `${environment.apiUrl}/vendor`;

const wikiUrl = `${environment.apiUrl}/wiki`;
const newsUrl = `${environment.apiUrl}/news`;
const teamUrl = `${environment.apiUrl}/supportteam`;
const departmentUrl = `${environment.apiUrl}/department`;
const faqUrl = `${environment.apiUrl}/faq`;
const knowlageBaseUrl = `${environment.apiUrl}/knowlageBase`;
const typeUrl = `${environment.apiUrl}/customertype`;
const posstionUrl = `${environment.apiUrl}/posstion`;
const noteUrl = `${environment.apiUrl}/note`;
const resolutionUrl = `${environment.apiUrl}/resolution`;
const rtmzoneUrl = `${environment.apiUrl}/rtmzone`;
const rtmmappingUrl = `${environment.apiUrl}/rtmmap`;

@Injectable({ providedIn: 'root' })
export class CrmService {
    private accountnumberSubject: BehaviorSubject<AccountNumber>;
    public accountnumber: Observable<AccountNumber>;
    private transactionSubject: BehaviorSubject<Transaction>;
    public transaction: Observable<Transaction>;
    private trSubject: BehaviorSubject<TicketReport>;
    public tr: Observable<TicketReport>;
    private agentSubject: BehaviorSubject<Agent>;
    public agent: Observable<Agent>;
    private helpSubject: BehaviorSubject<Help>;
    public help: Observable<Help>;
    private calllogSubject: BehaviorSubject<Calllog>;
    public calllog: Observable<Calllog>;
    private ticketSubject: BehaviorSubject<Ticket>;
    public ticket: Observable<Ticket>;
    private taskSubject: BehaviorSubject<Task>;
    public task: Observable<Task>;
    private projectSubject: BehaviorSubject<Project>;
    public project: Observable<Project>;
    private projectmemberSubject: BehaviorSubject<ProjectMember>;
    public projectmember: Observable<ProjectMember>;
    private checklistSubject: BehaviorSubject<CheckList>;
    public checklist: Observable<CheckList>;
    private ownershipSubject: BehaviorSubject<Ownership>;
    public ownership: Observable<Ownership>;
    private districtSubject: BehaviorSubject<Distrcit>;
    public district: Observable<Distrcit>;
    private branchSubject: BehaviorSubject<Branch>;
    public branch: Observable<Branch>;
    private contactSubject: BehaviorSubject<Contact>;
    public contact: Observable<Contact>;
    private commentSubject: BehaviorSubject<Comment>;
    public comment: Observable<Comment>;
    private customerSubject: BehaviorSubject<Customer>;
    public customer: Observable<Customer>;
    private groupSubject: BehaviorSubject<Group>;
    public group: Observable<Group>;
    private meetingSubject: BehaviorSubject<Meeting>;
    public meeting: Observable<Meeting>;
    private industrySubject: BehaviorSubject<Industry>;
    public industry: Observable<Industry>;
    private rettingSubject: BehaviorSubject<Retting>;
    public retting: Observable<Retting>;
    private sectorSubject: BehaviorSubject<Sector>;
    public sector: Observable<Sector>;
    private vendorSubject: BehaviorSubject<Vendor>;
    public vendor: Observable<Vendor>;
    private typeSubject: BehaviorSubject<Type>;
    public type: Observable<Type>;
    private wikiSubject: BehaviorSubject<Wiki>;
    public wiki: Observable<Wiki>;
    private newsSubject: BehaviorSubject<News>;
    public news: Observable<News>;
    private departmentSubject: BehaviorSubject<Department>;
    public department: Observable<Department>;
    private teamSubject: BehaviorSubject<SupportTeam>;
    public team: Observable<SupportTeam>;
    private faqSubject: BehaviorSubject<Faq>;
    public faq: Observable<Faq>;
    private knowlageBaseSubject: BehaviorSubject<KnowlageBase>;
    public knowlageBase: Observable<KnowlageBase>;
    private posstionSubject: BehaviorSubject<Posstion>;
    public posstion: Observable<Posstion>;
    private noteSubject: BehaviorSubject<Note>;
    public note: Observable<Note>;
    private ticketUpdateSubject: BehaviorSubject<TicketUpdate>;
    public ticketUpdate: Observable<TicketUpdate>;
    private resolutionSubject: BehaviorSubject<Resolution>;
    public resolution: Observable<Resolution>;
    private rtmzoneSubject: BehaviorSubject<RtmZone>;
    public rtmzone: Observable<RtmZone>;
    private rtmmappingSubject: BehaviorSubject<RtmMapping>;
    public rtmmapping: Observable<RtmMapping>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.accountnumberSubject = new BehaviorSubject<AccountNumber>(null);
        this.accountnumber = this.accountnumberSubject.asObservable();
        this.transactionSubject = new BehaviorSubject<Transaction>(null);
        this.transaction = this.transactionSubject.asObservable();
        this.trSubject = new BehaviorSubject<TicketReport>(null);
        this.tr = this.trSubject.asObservable();
        this.agentSubject = new BehaviorSubject<Agent>(null);
        this.agent = this.agentSubject.asObservable();
        this.helpSubject = new BehaviorSubject<Help>(null);
        this.help = this.helpSubject.asObservable();
        this.calllogSubject = new BehaviorSubject<Calllog>(null);
        this.calllog = this.calllogSubject.asObservable();
        this.ticketSubject = new BehaviorSubject<Ticket>(null);
        this.ticket = this.ticketSubject.asObservable();
        this.taskSubject = new BehaviorSubject<Task>(null);
        this.task = this.taskSubject.asObservable();
        this.projectSubject = new BehaviorSubject<Project>(null);
        this.project = this.projectSubject.asObservable();
        this.projectmemberSubject = new BehaviorSubject<ProjectMember>(null);
        this.projectmember = this.projectmemberSubject.asObservable();
        this.checklistSubject = new BehaviorSubject<CheckList>(null);
        this.checklist = this.checklistSubject.asObservable();
        this.customerSubject = new BehaviorSubject<Customer>(null);
        this.customer = this.customerSubject.asObservable();
        this.contactSubject = new BehaviorSubject<Contact>(null);
        this.contact = this.contactSubject.asObservable();
        this.ownershipSubject = new BehaviorSubject<Ownership>(null);
        this.ownership = this.ownershipSubject.asObservable();
        this.districtSubject = new BehaviorSubject<Distrcit>(null);
        this.district = this.districtSubject.asObservable();
        this.branchSubject = new BehaviorSubject<Branch>(null);
        this.branch = this.branchSubject.asObservable();
        this.commentSubject = new BehaviorSubject<Comment>(null);
        this.comment = this.commentSubject.asObservable();
        this.groupSubject = new BehaviorSubject<Group>(null);
        this.group = this.groupSubject.asObservable();
        this.meetingSubject = new BehaviorSubject<Meeting>(null);
        this.meeting = this.meetingSubject.asObservable();
        this.industrySubject = new BehaviorSubject<Industry>(null);
        this.industry = this.industrySubject.asObservable();
        this.sectorSubject = new BehaviorSubject<Sector>(null);
        this.sector = this.sectorSubject.asObservable();
        this.vendorSubject = new BehaviorSubject<Vendor>(null);
        this.vendor = this.vendorSubject.asObservable();
        this.typeSubject = new BehaviorSubject<Type>(null);
        this.type = this.typeSubject.asObservable();
        this.wikiSubject = new BehaviorSubject<Wiki>(null);
        this.wiki = this.wikiSubject.asObservable();
        this.newsSubject = new BehaviorSubject<News>(null);
        this.news = this.newsSubject.asObservable();
        this.faqSubject = new BehaviorSubject<Faq>(null);
        this.faq = this.faqSubject.asObservable();
        this.teamSubject = new BehaviorSubject<SupportTeam>(null);
        this.team = this.teamSubject.asObservable();
        this.knowlageBaseSubject = new BehaviorSubject<KnowlageBase>(null);
        this.knowlageBase = this.knowlageBaseSubject.asObservable();
        this.departmentSubject = new BehaviorSubject<Department>(null);
        this.department = this.departmentSubject.asObservable();
        this.posstionSubject = new BehaviorSubject<Posstion>(null);
        this.posstion = this.posstionSubject.asObservable();
        this.noteSubject = new BehaviorSubject<Note>(null);
        this.note = this.noteSubject.asObservable();
        this.ticketUpdateSubject = new BehaviorSubject<TicketUpdate>(null);
        this.ticketUpdate = this.ticketUpdateSubject.asObservable();
        this.resolutionSubject = new BehaviorSubject<Resolution>(null);
        this.resolution = this.resolutionSubject.asObservable();
        this.rtmzoneSubject = new BehaviorSubject<RtmZone>(null);
        this.rtmzone = this.rtmzoneSubject.asObservable();
        this.rtmmappingSubject = new BehaviorSubject<RtmMapping>(null);
        this.rtmmapping = this.rtmmappingSubject.asObservable();
    }
    public get accountnumberValue(): AccountNumber {
        return this.accountnumberSubject.value;
    }
    public get transactionValue(): Transaction {
        return this.transactionSubject.value;
    }
    public get trValue(): TicketReport {
        return this.trSubject.value;
    }
    public get agentValue(): Agent {
        return this.agentSubject.value;
    }
    public get helpValue(): Help {
        return this.helpSubject.value;
    }
    public get calllogValue(): Calllog {
        return this.calllogSubject.value;
    }
    public get ticketValue(): Ticket {
        return this.ticketSubject.value;
    }

    public get taskValue(): Task {
        return this.taskSubject.value;
    }

    public get projectValue(): Project {
        return this.projectSubject.value;
    }
    public get projectmemberValue(): ProjectMember {
        return this.projectmemberSubject.value;
    }
    public get checklistValue(): CheckList {
        return this.checklistSubject.value;
    }
    public get commentValue(): Comment {
        return this.commentSubject.value;
    }

    public get meetingValue(): Meeting {
        return this.meetingSubject.value;
    }

    public get ownershipValue(): Ownership {
        return this.ownershipSubject.value;
    }
    public get distrcitValue(): Distrcit {
        return this.districtSubject.value;
    }
    public get branchValue(): Branch {
        return this.branchSubject.value;
    }
    public get contactValue(): Contact {
        return this.contactSubject.value;
    }

    public get customerValue(): Customer {
        return this.customerSubject.value;
    }

    public get typeValue(): Type {
        return this.typeSubject.value;
    }

    public get groupValue(): Group {
        return this.groupSubject.value;
    }

    public get industryValue(): Industry {
        return this.industrySubject.value;
    }

    public get sectorValue(): Sector {
        return this.sectorSubject.value;
    }

    public get rettingValue(): Retting {
        return this.rettingSubject.value;
    }

    public get vendorValue(): Vendor {
        return this.vendorSubject.value;
    }

    public get wikiValue(): Wiki {
        return this.wikiSubject.value;
    }
    public get teamValue(): News {
        return this.newsSubject.value;
    }

    public get departmentValue(): Department {
        return this.departmentSubject.value;
    }
    public get faqValue(): Faq {
        return this.faqSubject.value;
    }
    public get knowlageBaseValue(): KnowlageBase {
        return this.knowlageBaseSubject.value;
    }
    public get posstionValue(): Posstion {
        return this.posstionSubject.value;
    }

    public get noteValue(): Note {
        return this.noteSubject.value;
    }

    public get ticketUpdateValue(): TicketUpdate {
        return this.ticketUpdateSubject.value;
    }
    public get resolutionValue(): Resolution {
        return this.resolutionSubject.value;
    }

    public get rtmzoneValue(): RtmZone {
        return this.rtmzoneSubject.value;
    }
    public get rtmmappingValue(): RtmMapping {
        return this.rtmmappingSubject.value;
    }
    getAllAccountNumber() {
        return this.http.get<AccountNumber[]>(accountnumberUrl);
    }

    getAllTransaction() {
        return this.http.get<Transaction[]>(transactionUrl);
    }

    getAllTR() {
        return this.http.get<TicketReport[]>(ticketreportUrl);
    }

    getAllAgent() {
        return this.http.get<Agent[]>(agentUrl);
    }
    getAllHelp() {
        return this.http.get<Help[]>(helpUrl);
    }
    getAllCalllog() {
        return this.http.get<Calllog[]>(calllogUrl);
    }
    getAllTicket() {
        return this.http.get<Ticket[]>(ticketUrl);
    }
    getProjectTicket(id: string) {
        return this.http.get<Ticket[]>(`${ticketUrl}/project/all/${id}`);
    }
    getProjectTask(id: string) {
        return this.http.get<Ticket[]>(`${taskUrl}/project/${id}`);
    }
    getMilestoneTask(id: string) {
        return this.http.get<Task[]>(`${taskUrl}/milestone/${id}`);
    }
    getMyTask(id: string) {
        return this.http.get<Task[]>(`${taskUrl}/my/${id}`);
    }
    getMyOpenTask(id: string) {
        return this.http.get<Task[]>(`${taskUrl}/myopen/${id}`);
    }
    getMyResolvedTask(id: string) {
        return this.http.get<Task[]>(`${taskUrl}/mycompleted/${id}`);
    }
    getMyClosedTask(id: string) {
        return this.http.get<Task[]>(`${taskUrl}/myclosed/${id}`);
    }
    getMyDueSoonTask(id: string) {
        return this.http.get<Task[]>(`${taskUrl}/myduesoon/${id}`);
    }
    getMyOverDueTask(id: string) {
        return this.http.get<Task[]>(`${taskUrl}/myoverdue/${id}`);
    }
    getTaskByDepartment(id: string) {
        return this.http.get<Task[]>(`${taskUrl}/department/all/${id}`);
    }
    getTaskByTeam(id: string) {
        return this.http.get<Task[]>(`${taskUrl}/team/all/${id}`);
    }
    getMyTicket(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/my/${id}`);
    }
    getMyOpenTicket(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/myopen/${id}`);
    }
    getMyCompleteTicket(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/mycomplete/${id}`);
    }
    getMyClosedTicket(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/myclosed/${id}`);
    }
    getMyDueSoonTicket(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/myduesoon/${id}`);
    }
    getMyOverDueTicket(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/myoverdue/${id}`);
    }
    getMyFirstTicket(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/myfirst/${id}`);
    }
    getMySecondTicket(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/mysecond/${id}`);
    }
    getMyThirdTicket(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/mythird/${id}`);
    }
    getMyFourthTicket(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/myfourth/${id}`);
    }
    getTicketByDepartment(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/department/all/${id}`);
    }
    getTicketByTeam(id: string) {
        return this.http.get<Ticket[]>(`${ticketreportUrl}/team/all/${id}`);
    }
    getTopTenTicket() {
        return this.http.get<Ticket[]>(`${ticketUrl}/topten`);
    }
    gethighAttention(id: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/highattention/${id}`);
    }
    getTodoList(id: string) {
        return this.http.get<TaskReport[]>(`${taskreportUrl}/todolist/${id}`);
    }
    getCustomerTicket(id: string) {
        return this.http.get<Ticket[]>(`${ticketUrl}/customer/${id}`);
    }
    getCustomerAccount(id: string) {
        return this.http.get<AccountNumber[]>(`${accountnumberUrl}/customer/${id}`);
    }
    getCustomerTransaction(id: string) {
        return this.http.get<Transaction[]>(`${transactionUrl}/customer/${id}`);
    }
    getBranchTicket(id: string) {
        return this.http.get<Ticket[]>(`${ticketreportUrl}/branch/${id}`);
    }
    getBranchTicketStatusStats(branchId: string) {
        return this.http.get<TaskReport[]>(`${ticketreportUrl}/branch/${branchId}/ticket/status`);
    }
    getBranchTicketPriorityStats(branchId: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/branch/${branchId}/ticket/priority`);
    }

    getCustomerTicketStatusStats(customerId: string) {
        return this.http.get<TaskReport[]>(`${ticketreportUrl}/customer/${customerId}/ticket/status`);
    }
    getCustomerTicketPriorityStats(customerId: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/customer/${customerId}/ticket/priority`);
    }
    getCustomerCalllogStats(customerId: string) {
        return this.http.get<TicketReport[]>(`${calllogUrl}/customer/${customerId}/calllog/stats`);
    }
    getCustomerCalllog(id: string) {
        return this.http.get<Calllog[]>(`${calllogUrl}/customer/${id}`);
    }
    getTicketStatus() {
        return this.http.get<Ticket[]>(`${ticketUrl}/status`);
    }
    getTicketDashboardStatus(id :string) {
        return this.http.get<Ticket[]>(`${ticketUrl}/statusdashboard/${id}`);
    }
    getAllTicketDashboardStatus() {
        return this.http.get<Ticket[]>(`${ticketUrl}/statusdashboard`);
    }
    getTaskDashboardStatus(id :string) {
        return this.http.get<Ticket[]>(`${taskUrl}/statusdashboard/${id}`);
    }
    getAllTaskDashboardStatus() {
        return this.http.get<Ticket[]>(`${taskUrl}/all/statusdashboard`);
    }
    getCrmPage() {
        return this.http.get<Project[]>(`${projectUrl}/crm/status`);
    }
    getAgentTicketPerformance(accountId: string,start: string,end: string,projectId: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/agentticketperformance/${accountId}/${start}/${end}/${projectId}`);
    }
    getCustomerTicketDetail(start: string,end: string,project: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/customerticketdetail/${start}/${end}/${project}/${category}/${type}`);
    }
    getDepartmentTicketDetail(start: string,end: string,department: string,status : string,category : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/departmentticketdetail/${start}/${end}/${department}/${status}/${category}`);
    }
    getDepartmentTicketVolume(start: string,end: string,classification: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/departmentticketvolume/${start}/${end}/${classification}/${category}/${type}`);
    }
    getDepartmentDailyTicketVolume(start: string,end: string,category: string,classification : string,status : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/departmentdailyticketvolume/${start}/${end}/${category}/${classification}/${status}`);
    }
    getDepartmentMonthlyTicketVolume(year: string,status: string,) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/departmentmonthlyticketvolume/${year}/${status}`);
    }
    getProjectTicketVolume(start: string,end: string,project: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/projectticketvolume/${start}/${end}/${project}/${category}/${type}`);
    }
    agentTicketPerformance(start: string,end: string,projectId: string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/ticketperformance/${start}/${end}/${projectId}`);
    }
    getTstatus() {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/status`);
    }

    getProejctTicketStats() {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/project/ticket/status`);
    }
    getProejctTaskStats() {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/project/task/status`);
    }
    getDepartmentTicketstatus(departmentId) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/status/department/${departmentId}`);
    }
    getDepartmentTicketCategory(departmentId) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/category/department/${departmentId}`);
    }
    getDepartmentTicketPriority(departmentId) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/priority/department/${departmentId}`);
    }
    getProjectTicketStatus(id : string) {
        return this.http.get<Ticket[]>(`${ticketUrl}/status/${id}`);
    }
    getTicketPriority(id : string) {
        return this.http.get<Ticket[]>(`${ticketUrl}/priority/${id}`);
    }
    getAllTicketPriority() {
        return this.http.get<Ticket[]>(`${ticketUrl}/all/priority`);
    }
    
    getTaskPriority(id : string) {
        return this.http.get<Ticket[]>(`${taskUrl}/priority/${id}`);
    }
    getAllTaskPriority() {
        return this.http.get<Ticket[]>(`${taskUrl}/all/priority`);
    }
    getTicketCategory() {
        return this.http.get<Ticket[]>(`${ticketUrl}/category`);
    }
    getTicketDashboardCategory(id :string) {
        return this.http.get<Ticket[]>(`${ticketUrl}/categorydashboard/${id}`);
    }
    getAllTicketDashboardCategory() {
        return this.http.get<Ticket[]>(`${ticketUrl}/all/categorydashboard`);
    }
    getCalllogTopic() {
        return this.http.get<Calllog[]>(`${calllogUrl}/topic`);
    }
    getCalllogFrom() {
        return this.http.get<Calllog[]>(`${calllogUrl}/from`);
    }
    getCalllogStatus() {
        return this.http.get<Calllog[]>(`${calllogUrl}/status`);
    }
    getCalllogLanguage() {
        return this.http.get<Language[]>(`${calllogUrl}/language`);
    }
    getTicketSlaTicket(id : string){
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/ticketslastatus/${id}`);
    }
    getAllTicketSlaTicket(){
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/all/ticketslastatus`);
    }
    getTaskSlaTask(id : string){
        return this.http.get<TaskReport[]>(`${taskreportUrl}/taskslastatus/${id}`);
    }
    getAllTaskSlaTask(){
        return this.http.get<TaskReport[]>(`${taskreportUrl}/all/taskslastatus`);
    }
    getDailyTicket(id : string){
        return this.http.get<TaskReport[]>(`${ticketreportUrl}/dailyticket/${id}`);
    }
    getAllDailyTicket(){
        return this.http.get<TaskReport[]>(`${ticketreportUrl}/all/dailyticket`);
    }
    getTicketByRtm(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/ticketbyrtm`);
    }
    getTicketByTeams(){
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/teamticketstats`);
    }
    getTeamTicketByPrirotity(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/team/priorityStats`);
    }
    getTeamTicketOpenVsResolved(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/team/openvsresolved`);
    }
    getTeamTicketOpenVsClosed(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/team/openvsclosed`);
    }
    getTeamTicketOpenVsEscalated(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/agent/openvsescalated`);
    }
    getTeamTicketDetail(start: string,end: string,department: string,project: string,status : string,category : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/teamticketdetail/${start}/${end}/${department}/${project}/${status}/${category}`);
    }
    getTeamTicketVolume(start: string,end: string,project: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/teamticketvolume/${start}/${end}/${project}/${category}/${type}`);
    }
    getTeamDailyTicketVolume(start: string,end: string,project: string,category : string,status : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/teamdailyticketvolume/${start}/${end}/${project}/${category}/${status}`);
    }
    getTeamMonthlyTicketVolume(year: string,status: string,) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/teammonthlyticketvolume/${year}/${status}`);
    }

    getTicketByAgent(){
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/agentticketstats`);
    }
    getAgentTicketByPrirotity(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/agent/priorityStats`);
    }
    getAgentTicketOpenVsResolved(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/agent/openvsresolved`);
    }
    getAgentTicketOpenVsClosed(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/agent/openvsclosed`);
    }
    getAgentTicketOpenVsEscalated(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/agent/openvsescalated`);
    }
    getAgentTicketDetail(start: string,end: string,department : string,category: string,status : string,classification : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/agentticketdetail/${start}/${end}/${department}/${category}/${status}/${classification}`);
    }
    getAgentTicketVolume(start: string,end: string,category: string,classification : string,impact : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/agentticketvolume/${start}/${end}/${category}/${classification}/${impact}`);
    }
    getAgentDailyTicketVolume(start: string,end: string,project: string,category : string,status : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/agentdailyticketvolume/${start}/${end}/${project}/${category}/${status}`);
    }
    getAgentMonthlyTicketVolume(year: string,status: string,) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/agentmonthlyticketvolume/${year}/${status}`);
    }
    getProjectTicketSLAStatus(start: string,end: string,resolved: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/projectslastatus/${start}/${end}/${resolved}/${category}/${type}`);
    }
    getDepartmentTicketSLAStatus(start: string,end: string,resolved: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/departmentslastatus/${start}/${end}/${resolved}/${category}/${type}`);
    }
    getTeamTicketSLAStatus(start: string,end: string,resolved: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/teamslastatus/${start}/${end}/${resolved}/${category}/${type}`);
    }
    getAgentTicketSLAStatus(start: string,end: string,resolved: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/agentslastatus/${start}/${end}/${resolved}/${category}/${type}`);
    }
    getProjectTaskSLAStatus(start: string,end: string,isDone: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${taskreportUrl}/projectslastatus/${start}/${end}/${isDone}/${category}/${type}`);
    }
    getDepartmentTaskSLAStatus(start: string,end: string,isDone: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${taskreportUrl}/departmentslastatus/${start}/${end}/${isDone}/${category}/${type}`);
    }
    getTeamTaskSLAStatus(start: string,end: string,isDone: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${taskreportUrl}/teamslastatus/${start}/${end}/${isDone}/${category}/${type}`);
    }
    getAgentTaskSLAStatus(start: string,end: string,isDone: string,category : string,type : string) {
        return this.http.get<TicketReport[]>(`${taskreportUrl}/agentslastatus/${start}/${end}/${isDone}/${category}/${type}`);
    }
    getDepartmentTicketByPrirotity(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/department/priorityStats`);
    }
    getDepartmentTicketOpenVsResolved(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/department/openvsresolved`);
    }
    getDepartmentTicketOpenVsClosed(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/department/openvsclosed`);
    }
    getDepartmentTicketOpenVsEscalated(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/department/openvsescalated`);
    }
    getDepartmentTicketByDate(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/department/bydate`);
    }
    getCalllogByCalltype(){
        return this.http.get<Calllog[]>(`${calllogUrl}/calltype`);
    }
    getCalllogBylanguage(){
        return this.http.get<Calllog[]>(`${calllogUrl}/language`);
    }
    getCalllogByCallfrom(){
        return this.http.get<Calllog[]>(`${calllogUrl}/callfrom`);
    }
    getCalllogByLanguage(){
        return this.http.get<Calllog[]>(`${calllogUrl}/language`);
    }
    getCalllogByCategory(){
        return this.http.get<Calllog[]>(`${calllogUrl}/category`);
    }
    getCalllogByCategoryDaily(){
        return this.http.get<Calllog[]>(`${calllogUrl}/daily/category`);
    }
    getCalllogByCategoryMonthly(){
        return this.http.get<Calllog[]>(`${calllogUrl}/monthly/category`);
    }
    getTicketByCustomerType(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/ticketbycustomertype`);
    }
    getTicketByProject(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/ticketbyproject`);
    }
    getTicketByCategorySla(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/category/sla`);
    }
    getTaskByProjectSla(){
        return this.http.get<Calllog[]>(`${taskreportUrl}/project/sla`);
    }
    getTicketByDepartmentSla(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/department/sla`);
    }
    getTicketCategoryStatus() {
        return this.http.get<TaskReport[]>(`${ticketreportUrl}/category/ticket/status`);
    }
    getTaskByDepartmentSla(){
        return this.http.get<Calllog[]>(`${taskreportUrl}/department/sla`);
    }
    getTicketByTeamSla(){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/team/sla`);
    }
    getTaskByTeamSla(){
        return this.http.get<Calllog[]>(`${taskreportUrl}/team/sla`);
    }
    getDailyTicketByProject(start: string,end: string,project: string,category : string){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/dailyticketbyproject/${start}/${end}/${project}/${category}`);
    }
    MonthlyRoutingModule(year: string,project: string,category : string){
        return this.http.get<Calllog[]>(`${ticketreportUrl}/monthlyticketbyproject/${year}/${project}/${category}`);
    }
    getTicketByDept(){
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/departmentticketstats`);
    }


    getTopDailyTicketVolume(start: string,end: string,project: string,category : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/topdailyticketvolume/${start}/${end}/${project}/${category}`);
    }
    getTopMonthlyicketVolume(year: string,project: string,category : string) {
        return this.http.get<TicketReport[]>(`${ticketreportUrl}/topmontlyticketvolume/${year}/${project}/${category}`);
    }
    getAllTask() {
        return this.http.get<Task[]>(taskUrl);
    } 
   
    getAllProject() {
        return this.http.get<Project[]>(projectUrl);
    }
    getAllProjectmember(id: string) {
        return this.http.get<ProjectMember[]>(`${projectmemberUrl}/project/${id}`);
    }
    getAllCustomer() {
        return this.http.get<Customer[]>(customerUrl);
    }
    searchCustomer(mobile: string) {
        return this.http.get<Customer[]>(`${customerUrl}/search/${mobile}`);
    }
    customerStats() {
        return this.http.get<Customer[]>(`${customerUrl}/customer/metrics`);
    }
    deptStats() {
        return this.http.get<Department[]>(`${departmentUrl}/department/stats`);
    }
    getAllType() {
        return this.http.get<Type[]>(typeUrl);
    }

    getAllOwnership() {
        return this.http.get<Ownership[]>(ownershipUrl);
    }
    getAllBranch() {
        return this.http.get<Branch[]>(branchUrl);
    }
    getAllDistrcit() {
        return this.http.get<Distrcit[]>(districtUrl);
    }
    getAllDistrcitBranch(id: string) {
        return this.http.get<Distrcit[]>(`${districtUrl}/branch/${id}`);
    }
    getAllContact() {
        return this.http.get<Contact[]>(contactUrl);
    }
    getAllMeeting() {
        return this.http.get<Meeting[]>(meetingUrl);
    }
    getAllIndustry() {
        return this.http.get<Industry[]>(industryUrl);
    }
    getAllGroup() {
        return this.http.get<Group[]>(groupUrl);
    }
    getAllSector() {
        return this.http.get<Sector[]>(industryUrl);
    }
    getAllRetting() {
        return this.http.get<Retting[]>(rettingUrl);
    }
    getAllVendor() {
        return this.http.get<Vendor[]>(vendorUrl);
    }

    getAllWiki() {
        return this.http.get<Wiki[]>(wikiUrl);
    }

    getAllNews() {
        return this.http.get<News[]>(newsUrl);
    }
    getAllKnowlageBase() {
        return this.http.get<KnowlageBase[]>(knowlageBaseUrl);
    }
    getAllDepartment() {
        return this.http.get<Department[]>(departmentUrl);
    }
    getAllTeam() {
        return this.http.get<SupportTeam[]>(teamUrl);
    }
    getAllTeamMember(id: string) {
        return this.http.get<SupportTeam[]>(`${teamUrl}/department/${id}`);
    }
    getAllSubTeamMember(id: string) {
        return this.http.get<SupportTeam[]>(`${teamUrl}/team/${id}`);
    }
    getAllFaq() {
        return this.http.get<Faq[]>(faqUrl);
    }
    getCategoryFaq(id: string) {
        return this.http.get<Faq[]>(`${faqUrl}/category/${id}`);
    }
    getAllPosstion() {
        return this.http.get<Posstion[]>(posstionUrl);
    }
  
    getAllNote() {
        return this.http.get<Note[]>(noteUrl);
    }

    getAllResolution() {
        return this.http.get<Resolution[]>(resolutionUrl);
    }


    getAllTicketUpdate() {
        return this.http.get<TicketUpdate[]>(ticketUpdateUrl);
    }

    getRtmZone() {
        return this.http.get<RtmZone[]>(rtmzoneUrl);
    }

    getAllRtmMapping() {
        return this.http.get<RtmMapping[]>(rtmmappingUrl);
    }
    getAgentById(id: string) {
        return this.http.get<Agent>(`${agentUrl}/${id}`);
    }
    getCalllogById(id: string) {
        return this.http.get<Calllog>(`${calllogUrl}/${id}`);
    }
    getTicketById(id: string) {
        return this.http.get<Ticket>(`${ticketUrl}/${id}`);
    }
    getTicketByStatus(acountId : string,id: string) {
        return this.http.get<TicketReport>(`${ticketreportUrl}/ticketstatus/${acountId}/${id}`);
    }
    getTicketByPriority(acountId : string,id: string) {
        return this.http.get<TicketReport>(`${ticketreportUrl}/ticketpriority/${acountId}/${id}`);
    }
    getTaskByStatus(ownerId : string,id: string) {
        return this.http.get<TaskReport>(`${taskreportUrl}/taskstatus/${ownerId}/${id}`);
    }
    getTaskByPriority(ownerId : string,id: string) {
        return this.http.get<TaskReport>(`${taskreportUrl}/taskpriority/${ownerId}/${id}`);
    }
    getCustomerTicketById(id: string) {
        return this.http.get<Ticket>(`${ticketUrl}/history/${id}`);
    }
    getTaskById(id: string) {
        return this.http.get<Task[]>(`${taskUrl}/${id}`);
    }
    getAccountNumberById(id: string) {
        return this.http.get<AccountNumber[]>(`${accountnumberUrl}/${id}`);
    }
    getTransactionById(id: string) {
        return this.http.get<Transaction[]>(`${transactionUrl}/${id}`);
    }
    getProjectById(id: string) {
        return this.http.get<Project>(`${projectUrl}/${id}`);
    }
    getProjectmemberById(id: string) {
        return this.http.get<ProjectMember>(`${projectmemberUrl}/${id}`);
    }
    getMemberOfProject(id: string) {
        return this.http.get<ProjectMember[]>(`${projectmemberUrl}/project/${id}`);
    }
    
    getCustomerById(id: string) {
        return this.http.get<Customer>(`${customerUrl}/${id}`);
    }
    getCustomerByMobile(mobile: string) {
        return this.http.get<Customer>(`${customerUrl}/mobile/${mobile}`);
    }
    getOwnershipById(id: string) {
        return this.http.get<Ownership>(`${ownershipUrl}/${id}`);
    }
    getBranchById(id: string) {
        return this.http.get<Branch>(`${branchUrl}/${id}`);
    }
    getDistrictById(id: string) {
        return this.http.get<Distrcit>(`${districtUrl}/${id}`);
    }
    getContactById(id: string) {
        return this.http.get<Contact>(`${contactUrl}/${id}`);
    }
    getMeetingById(id: string) {
        return this.http.get<Meeting>(`${meetingUrl}/${id}`);
    }

    getGroupById(id: string) {
        return this.http.get<Group>(`${groupUrl}/${id}`);
    }
    getTypeById(id: string) {
        return this.http.get<Type>(`${typeUrl}/${id}`);
    }
    getSectorById(id: string) {
        return this.http.get<Sector>(`${sectorUrl}/${id}`);
    }

    getRettingById(id: string) {
        return this.http.get<Retting>(`${rettingUrl}/${id}`);
    }

    getIndustryById(id: string) {
        return this.http.get<Industry>(`${industryUrl}/${id}`);
    }

    getVendorById(id: string) {
        return this.http.get<Vendor>(`${vendorUrl}/${id}`);
    }

    getWikiById(id: string) {
        return this.http.get<Wiki>(`${wikiUrl}/${id}`);
    }

    getFaqById(id: string) {
        return this.http.get<Faq>(`${faqUrl}/${id}`);
    }

    getDepartmentById(id: string) {
        return this.http.get<Department>(`${departmentUrl}/${id}`);
    }
    getTeamById(id: string) {
        return this.http.get<SupportTeam>(`${teamUrl}/${id}`);
    }

    getKnowlageBaseById(id: string) {
        return this.http.get<KnowlageBase>(`${knowlageBaseUrl}/${id}`);
    }
    getKnowlageBaseOfTopicById(id: string) {
        return this.http.get<KnowlageBase[]>(`${knowlageBaseUrl}/topic/${id}`);
    }
    getTopArticle(projectId: string) {
        return this.http.get<KnowlageBase[]>(`${knowlageBaseUrl}/top/${projectId}`);
    }
    getPosstionById(id: string) {
        return this.http.get<Posstion>(`${posstionUrl}/${id}`);
    }
  
    getNoteById(id: string) {
        return this.http.get<Note>(`${noteUrl}/${id}`);
    }
    getTicketupdateById(ticketId: string) {
        return this.http.get<TicketUpdate>(`${ticketUpdateUrl}/${ticketId}`);
    }

    getResolutionById(id: string) {
        return this.http.get<Resolution>(`${resolutionUrl}/${id}`);
    }
    getTaskResolutionById(id: string) {
        return this.http.get<Resolution>(`${resolutionUrl}/task/${id}`);
    }

    getRtmZoneById(id: string) {
        return this.http.get<RtmZone>(`${rtmzoneUrl}/${id}`);
    }
    getRtmMappingById(id: string) {
        return this.http.get<RtmMapping>(`${rtmmappingUrl}/${id}`);
    }
    getCustomerZone(id: string) {
        return this.http.get<RtmMapping[]>(`${rtmmappingUrl}/city/${id}`);
    }
    getzoneList(id: string) {
        return this.http.get<RtmMapping[]>(`${rtmmappingUrl}/zone/${id}`);
    }
    createAccountNumber(params) {
        return this.http.post(accountnumberUrl, params);
    }
    
    updateAccountNumber(id, params) {
        return this.http.put(`${accountnumberUrl}/${id}`, params)
            .pipe(map((accountnumber: any) => {
                    this.accountnumber = { ...this.accountnumberValue, ...accountnumber };
                    this.accountnumberSubject.next(accountnumber);
              
                return accountnumber;
            }));
    }
    deleteAccountNumber(id: string) {
        return this.http.delete(`${accountnumberUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createTransaction(params) {
        return this.http.post(transactionUrl, params);
    }
    
    updateTransaction(id, params) {
        return this.http.put(`${transactionUrl}/${id}`, params)
            .pipe(map((transaction: any) => {
                    this.transaction = { ...this.transactionValue, ...transaction };
                    this.transactionSubject.next(transaction);
              
                return transaction;
            }));
    }
    deleteTransaction(id: string) {
        return this.http.delete(`${transactionUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createAgent(params) {
        return this.http.post(agentUrl, params);
    }
    
    updateAgent(id, params) {
        return this.http.put(`${agentUrl}/${id}`, params)
            .pipe(map((agent: any) => {
                    this.agent = { ...this.agentValue, ...agent };
                    this.agentSubject.next(agent);
              
                return agent;
            }));
    }
    deleteAgent(id: string) {
        return this.http.delete(`${agentUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createCalllog(params) {
        return this.http.post(calllogUrl, params);
    }
    
    updateCalllog(id, params) {
        return this.http.put(`${calllogUrl}/${id}`, params)
            .pipe(map((calllog: any) => {
                    this.calllog = { ...this.calllogValue, ...calllog };
                    this.calllogSubject.next(calllog);
              
                return calllog;
            }));
    }
    deleteCalllog(id: string) {
        return this.http.delete(`${calllogUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createTicket(params) {
        return this.http.post(ticketUrl, params);
    }
    
    updateTicket(id, params) {
        return this.http.put(`${ticketUrl}/${id}`, params)
            .pipe(map((ticket: any) => {
                    this.ticket = { ...this.ticketValue, ...ticket };
                    this.ticketSubject.next(ticket);
              
                return ticket;
            }));
    }
    deleteTicket(id: string) {
        return this.http.delete(`${ticketUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createTask(params) {
        return this.http.post(taskUrl, params);
    }
    
    updateTask(id, params) {
        return this.http.put(`${taskUrl}/${id}`, params)
            .pipe(map((task: any) => {
                    this.task = { ...this.taskValue, ...task };
                    this.taskSubject.next(task);
              
                return task;
            }));
    }
    deleteTask(id: string) {
        return this.http.delete(`${taskUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createProject(params) {
        return this.http.post(projectUrl, params);
    }
    
    updateProject(id, params) {
        return this.http.put(`${projectUrl}/${id}`, params)
            .pipe(map((project: any) => {
                    this.project = { ...this.projectValue, ...project };
                    this.projectSubject.next(project);
              
                return project;
            }));
    }
    deleteProject(id: string) {
        return this.http.delete(`${projectUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    deleteProjectMember(id: string) {
        return this.http.delete(`${projectmemberUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createProjectmember(params) {
        return this.http.post(projectmemberUrl, params);
    }
    
    updateProjectmember(id, params) {
        return this.http.put(`${projectmemberUrl}/${id}`, params)
            .pipe(map((projectmember: any) => {
                    this.projectmember = { ...this.projectmemberValue, ...projectmember };
                    this.projectSubject.next(projectmember);
              
                return projectmember;
            }));
    }
    deleteProjectmember(id: string) {
        return this.http.delete(`${projectmemberUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    
    createFaq(params) {
        return this.http.post(faqUrl, params);
    }
    
    updateFaq(id, params) {
        return this.http.put(`${faqUrl}/${id}`, params)
            .pipe(map((faq: any) => {
                    this.faq = { ...this.faqValue, ...faq };
                    this.faqSubject.next(faq);
              
                return faq;
            }));
    }
    deleteFaq(id: string) {
        return this.http.delete(`${faqUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createWiki(params) {
        return this.http.post(wikiUrl, params);
    }
    
    updateWiki(id, params) {
        return this.http.put(`${wikiUrl}/${id}`, params)
            .pipe(map((wiki: any) => {
                    this.wiki = { ...this.wikiValue, ...wiki };
                    this.wikiSubject.next(wiki);
              
                return wiki;
            }));
    }
    deleteWiki(id: string) {
        return this.http.delete(`${wikiUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createKnowlageBase(params) {
        return this.http.post(knowlageBaseUrl, params);
    }
    
    updateKnowlageBase(id, params) {
        return this.http.put(`${knowlageBaseUrl}/${id}`, params)
            .pipe(map((knowlageBase: any) => {
                    this.knowlageBase = { ...this.knowlageBaseValue, ...knowlageBase };
                    this.knowlageBaseSubject.next(knowlageBase);
                return knowlageBase;
            }));
    }
    deleteKnowlageBase(id: string) {
        return this.http.delete(`${knowlageBaseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createDepartment(params) {
        return this.http.post(departmentUrl, params);
    }
    
    updateDepartment(id, params) {
        return this.http.put(`${departmentUrl}/${id}`, params)
            .pipe(map((department: any) => {
                    this.department = { ...this.departmentValue, ...department };
                    this.departmentSubject.next(department);
                return department;
            }));
    }
    deleteDepartment(id: string) {
        return this.http.delete(`${departmentUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createTeam(params) {
        return this.http.post(teamUrl, params);
    }
    
    updateTeam(id, params) {
        return this.http.put(`${teamUrl}/${id}`, params)
            .pipe(map((team: any) => {
                    this.team = { ...this.teamValue, ...team };
                    this.teamSubject.next(team);
                return team;
            }));
    }
    deleteTeam(id: string) {
        return this.http.delete(`${teamUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createIndustry(params) {
        return this.http.post(industryUrl, params);
    }
    
    updateIndustry(id, params) {
        return this.http.put(`${industryUrl}/${id}`, params)
            .pipe(map((industry: any) => {
                    this.industry = { ...this.industryValue, ...industry };
                    this.industrySubject.next(industry);
                return industry;
            }));
    }
    deleteIndustry(id: string) {
        return this.http.delete(`${industryUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createBranch(params) {
        return this.http.post(branchUrl, params);
    }
    
    updateBranch(id, params) {
        return this.http.put(`${branchUrl}/${id}`, params)
            .pipe(map((branch: any) => {
                    this.branch = { ...this.ownershipValue, ...branch };
                    this.branchSubject.next(branch);
                return branch;
            }));
    }
    deleteBranch(id: string) {
        return this.http.delete(`${branchUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createOwnership(params) {
        return this.http.post(ownershipUrl, params);
    }
    
    updateOwnership(id, params) {
        return this.http.put(`${ownershipUrl}/${id}`, params)
            .pipe(map((ownership: any) => {
                    this.ownership = { ...this.ownershipValue, ...ownership };
                    this.ownershipSubject.next(ownership);
                return ownership;
            }));
    }
    deleteOwnership(id: string) {
        return this.http.delete(`${ownershipUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createDistrcit(params) {
        return this.http.post(districtUrl, params);
    }
    
    updateDistrcit(id, params) {
        return this.http.put(`${districtUrl}/${id}`, params)
            .pipe(map((district: any) => {
                    this.district = { ...this.distrcitValue, ...district };
                    this.districtSubject.next(district);
                return district;
            }));
    }
    deleteDistrcit(id: string) {
        return this.http.delete(`${districtUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createCustoemr(params) {
        return this.http.post(customerUrl, params);
    }
    
    updateCustomer(id, params) {
        return this.http.put(`${customerUrl}/${id}`, params)
            .pipe(map((customer: any) => {
                    this.customer = { ...this.customerValue, ...customer };
                    this.customerSubject.next(customer);
                return customer;
            }));
    }
    deleteCustomer(id: string) {
        return this.http.delete(`${customerUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createType(params) {
        return this.http.post(typeUrl, params);
    }
    
    updateType(id, params) {
        return this.http.put(`${typeUrl}/${id}`, params)
            .pipe(map((type: any) => {
                    this.type = { ...this.typeValue, ...type };
                    this.typeSubject.next(type);
                return type;
            }));
    }
    deleteType(id: string) {
        return this.http.delete(`${typeUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createGroup(params) {
        return this.http.post(groupUrl, params);
    }
    
    updateGroup(id, params) {
        return this.http.put(`${groupUrl}/${id}`, params)
            .pipe(map((group: any) => {
                    this.group = { ...this.groupValue, ...group };
                    this.groupSubject.next(group);
                return group;
            }));
    }
    deleteGroup(id: string) {
        return this.http.delete(`${groupUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createRetting(params) {
        return this.http.post(rettingUrl, params);
    }
    
    updateRetting(id, params) {
        return this.http.put(`${rettingUrl}/${id}`, params)
            .pipe(map((retting: any) => {
                    this.retting = { ...this.rettingValue, ...retting };
                    this.rettingSubject.next(retting);
                return retting;
            }));
    }
    deleteRetting(id: string) {
        return this.http.delete(`${rettingUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createPosstion(params) {
        return this.http.post(posstionUrl, params);
    }
    
    updatePosstion(id, params) {
        return this.http.put(`${posstionUrl}/${id}`, params)
            .pipe(map((posstion: any) => {
                    this.posstion = { ...this.posstionValue, ...posstion };
                    this.posstionSubject.next(posstion);
                return posstion;
            }));
    }
    deletePosstion(id: string) {
        return this.http.delete(`${posstionUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createContact(params) {
        return this.http.post(contactUrl, params);
    }
    
    updateContact(id, params) {
        return this.http.put(`${contactUrl}/${id}`, params)
            .pipe(map((contact: any) => {
                    this.contact = { ...this.contactValue, ...contact };
                    this.posstionSubject.next(contact);
                return contact;
            }));
    }
    deleteContact(id: string) {
        return this.http.delete(`${contactUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createNote(params) {
        return this.http.post(noteUrl, params);
    }
    updateNote(id, params) {
        return this.http.put(`${noteUrl}/${id}`, params)
            .pipe(map((note: any) => {
                    this.note = { ...this.noteValue, ...note };
                    this.posstionSubject.next(note);
                return note;
            }));
    }
    deleteNote(id: string) {
        return this.http.delete(`${noteUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createTicketupdate(params) {
        return this.http.post(ticketUpdateUrl, params);
    }
    updateTicketUpdate(id, params) {
        return this.http.put(`${ticketUpdateUrl}/${id}`, params)
            .pipe(map((ticketUpdate: any) => {
                    this.ticketUpdate = { ...this.ticketUpdateValue, ...ticketUpdate };
                    this.ticketUpdateSubject.next(ticketUpdate);
                return ticketUpdate;
            }));
    }
    deleteTicketUpdate(id: string) {
        return this.http.delete(`${ticketUpdateUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createResolution(params) {
        return this.http.post(resolutionUrl, params);
    }
    updateResolution(id, params) {
        return this.http.put(`${resolutionUrl}/${id}`, params)
            .pipe(map(( resolution: any) => {
                    this.resolutionSubject = { ...this.resolutionValue, ...resolution };
                    this.resolutionSubject.next(resolution);
                return resolution;
            }));
    }
    deleteResolution(id: string) {
        return this.http.delete(`${resolutionUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createRtmZone(params) {
        return this.http.post(rtmzoneUrl, params);
    }
    updateRtmZone(id, params) {
        return this.http.put(`${rtmzoneUrl}/${id}`, params)
            .pipe(map(( rtmzone: any) => {
                    this.rtmzoneSubject = { ...this.rtmzoneValue, ...rtmzone };
                    this.rtmzoneSubject.next(rtmzone);
                return rtmzone;
            }));
    }
    deleteRtmZone(id: string) {
        return this.http.delete(`${rtmzoneUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createRtmMapping(params) {
        return this.http.post(rtmmappingUrl, params);
    }
    updateRtmMapping(id, params) {
        return this.http.put(`${rtmmappingUrl}/${id}`, params)
            .pipe(map(( rtmmapping: any) => {
                    this.rtmmappingSubject = { ...this.rtmzoneValue, ...rtmmapping };
                    this.rtmmappingSubject.next(rtmmapping);
                return rtmmapping;
            }));
    }
    deleteRtmMapping(id: string) {
        return this.http.delete(`${rtmmappingUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
}
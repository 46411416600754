

export class GigStep {
    id: string;
    serviceDate: string;
    picture1: string;
    picture2: string;
    picture3: string;
    picture4: string;
    seats: string;
    year: string;
    since: string;
    active: boolean;
}

import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '../../_services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  account = this.accountService.accountValue;
  modules: any[]
  roleId: string

  constructor(
              private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              public titleService: Title
    ) { }
  ngOnInit() {
    this.titleService.setTitle('OneTap');
    this.accountService.getModulePermission(this.account.roleId)
        .pipe(first())
        .subscribe(modules => this.modules = modules);
}

}



export class RouteDetail {
    id: string;
    route: string;
    bus: string;
    driver: string;
    departueStation: string;
    departueTime: string;
    arrivalTime: string;
    arrivalStation: string;
    killoMeter: string;
    active: boolean;
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Menu,SubMenu,AppMenu } from '../_models';
const baseUrl = `${environment.apiUrl}/menu`;
const appMenuUrl = `${environment.apiUrl}/appMenu`;

@Injectable({ providedIn: 'root' })
export class MenuService {
    private menuSubject: BehaviorSubject<Menu>;
    public menu: Observable<Menu>;
    private appMenuSubject: BehaviorSubject<AppMenu>;
    public appMenu: Observable<AppMenu>;
    private subMenuSubject: BehaviorSubject<SubMenu>;
    public subMenu: Observable<SubMenu>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.menuSubject = new BehaviorSubject<Menu>(null);
        this.menu = this.menuSubject.asObservable();
        this.appMenuSubject = new BehaviorSubject<AppMenu>(null);
        this.appMenu = this.appMenuSubject.asObservable();
    }

    public get menuValue(): Menu {
        return this.menuSubject.value;
    }
    public get appMenuValue(): Menu {
        return this.appMenuSubject.value;
    }

    getAllAppMenu() {
        return this.http.get<AppMenu[]>(appMenuUrl);
    }
    getAllAppMenuByIds(id: string) {
        return this.http.get<AppMenu[]>(`${baseUrl}/app/${id}`);
    }
    getAppMenuById(id: string) {
        return this.http.get<AppMenu>(`${baseUrl}/${id}`);
    }
    getAll() {
        return this.http.get<Menu[]>(baseUrl);
    }
    getAllAppMenuByCode(id:string,appCode: string) {
        return this.http.get<Menu[]>(`${appMenuUrl}/app/${id}/${appCode}`);
    }
    getAllSubMenuByIds(id: string) {
        return this.http.get<Menu[]>(`${baseUrl}/subMenu/${id}`);
    }

    getAllSubMenuByCode(appCode: string) {
        return this.http.get<Menu[]>(`${baseUrl}/menus/${appCode}`);
    }

    getById(id: string) {
        return this.http.get<Menu>(`${baseUrl}/${id}`);
    }

    getAllAppWithPrice() {
        return this.http.get<Menu[]>(`${baseUrl}/subMenu`);
    }

     getAllSubMenuDetails(id: string) {
        return this.http.get<Menu[]>(`${baseUrl}/subMenu/${id}`);
    }

    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((menu: any) => {
                    // publish updated membership to subscribers
                    menu = { ...this.menuValue, ...menu };
                    this.menuSubject.next(menu);
              
                return menu;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createAppMenu(params) {
        return this.http.post(appMenuUrl, params);
    }
    
    updateAppMenu(id, params) {
        return this.http.put(`${appMenuUrl}/${id}`, params)
            .pipe(map((appMenu: any) => {
                    // publish updated membership to subscribers
                    appMenu = { ...this.appMenuValue, ...appMenu };
                    this.appMenuSubject.next(appMenu);
              
                return appMenu;
            }));
    }
    deleteAppMenu(id: string) {
        return this.http.delete(`${appMenuUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}
import { Component ,OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService,ApplcationService,PricingService ,AlertService} from '../../../_services';
import { first } from 'rxjs/operators';

@Component({
    selector: 'vendor-layout',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
  })
export class CartComponent implements OnInit {
    form: FormGroup;
    app;
    pricing ;
    id: string;
    pId: string;
    loading = false;
    submitted = false;
    account = this.accountService.accountValue;
    

    constructor(
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private router: Router,
      private accountService: AccountService,
      private applcationService: ApplcationService,
      private pricingService: PricingService,
      private alertService: AlertService
    ) { }
           
    ngOnInit() {
      this.id = this.route.snapshot.params['id'];
      this.pId =this.route.snapshot.params['pId'];
     this.applcationService.getAllPricingByIds(this.id)
     .pipe(first())
     .subscribe(app => this.app = app);
        this.pricing=this.pricingService.getById(this.pId)
        .subscribe(pricing => this.pricing = pricing);
     
  
    this.form = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        email: ['', [Validators.required, Validators.email]],
        acceptTerms: [false, Validators.requiredTrue]
    });
    this.accountService.getById(this.account.id)
    .pipe(first())
    .subscribe(x => this.form.patchValue(x));
           
    }
    
    get f() { return this.form.controls; }


    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.accountService.register(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Registration successful, please check your email for verification instructions', { keepAfterRouteChange: true });
                    this.router.navigate(['../login'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

  
}
import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {Status, Priority, Language,Source,Classification,Type,Team,Help,Department,TaskStatus,Topic,Sla,Hour,Notification,TaskType,ProjectStatus,ProjectType,Milestone ,Request,RequestType,Impact,Level,Urgency,Category} from '../_models';
import { not } from '@angular/compiler/src/output/output_ast';

const milestoneUrl = `${environment.apiUrl}/milestone`;
const statusUrl = `${environment.apiUrl}/status`;
const helpUrl = `${environment.apiUrl}/help`;
const sourceUrl = `${environment.apiUrl}/source`;
const teamUrl = `${environment.apiUrl}/supportteam`;
const typeUrl = `${environment.apiUrl}/type`;
const priorityUrl = `${environment.apiUrl}/priority`;
const topicUrl = `${environment.apiUrl}/topic`;
const departmentUrl = `${environment.apiUrl}/department`;
const languageUrl = `${environment.apiUrl}/language`;
const classificationUrl = `${environment.apiUrl}/classification`;
const taskStatusUrl = `${environment.apiUrl}/taskstatus`;
const taskTypeUrl = `${environment.apiUrl}/tasktype`;
const projectStatusUrl = `${environment.apiUrl}/projectstatus`;
const projectTypeUrl = `${environment.apiUrl}/projecttype`;
const requestUrl = `${environment.apiUrl}/request`;
const requestTypeUrl = `${environment.apiUrl}/requesttype`;
const slaUrl = `${environment.apiUrl}/sla`;
const hourUrl = `${environment.apiUrl}/hour`;
const notificationUrl = `${environment.apiUrl}/notification`;
const impactUrl = `${environment.apiUrl}/impact`;
const levelUrl = `${environment.apiUrl}/level`;
const urgencyUrl = `${environment.apiUrl}/urgency`;
const categoryUrl = `${environment.apiUrl}/category`;

@Injectable({ providedIn: 'root' })
export class CareService {
    private slaSubject: BehaviorSubject<Sla>;
    public sla: Observable<Sla>;
    private hourSubject: BehaviorSubject<Hour>;
    public hour: Observable<Hour>;
    private topicSubject: BehaviorSubject<Topic>;
    public topic: Observable<Topic>;
    private statusSubject: BehaviorSubject<Status>;
    public status: Observable<Status>;
    private taskStatusSubject: BehaviorSubject<TaskStatus>;
    public taskStatus: Observable<TaskStatus>;
    private taskTypeSubject: BehaviorSubject<TaskType>;
    public taskType: Observable<TaskType>;
    private projectStatusSubject: BehaviorSubject<ProjectStatus>;
    public  projectStatus: Observable<ProjectStatus>;
    private projectTypeSubject: BehaviorSubject<ProjectType>;
    public projectType: Observable<ProjectType>;
    private sourceSubject: BehaviorSubject<Source>;
    public source: Observable<Source>;
    private typeSubject: BehaviorSubject<Type>;
    public type: Observable<Type>;
    private prioritySubject: BehaviorSubject<Priority>;
    public priority: Observable<Priority>;
    private teamSubject: BehaviorSubject<Team>;
    public team: Observable<Team>;
    private departmentSubject: BehaviorSubject<Department>;
    public department: Observable<Department>;
    private languageSubject: BehaviorSubject<Language>;
    public language: Observable<Language>;
    private helpSubject: BehaviorSubject<Help>;
    public help: Observable<Help>;
    private classificationSubject: BehaviorSubject<Classification>;
    public classification: Observable<Classification>;
    private notificationSubject: BehaviorSubject<Notification>;
    public notification: Observable<Notification>;
    private milestoneSubject: BehaviorSubject<Milestone>;
    public milestone: Observable<Milestone>;
    private requestSubject: BehaviorSubject<Request>;
    public request: Observable<Request>;
    private requestTypeSubject: BehaviorSubject<RequestType>;
    public requestType: Observable<RequestType>;
    private impactSubject: BehaviorSubject<Impact>;
    public impact: Observable<Impact>;
    private levelSubject: BehaviorSubject<Level>;
    public level: Observable<Level>;
    private urgencySubject: BehaviorSubject<Urgency>;
    public urgency: Observable<Urgency>;
    private categorySubject: BehaviorSubject<Category>;
    public category: Observable<Category>;


    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.slaSubject = new BehaviorSubject<Sla>(null);
        this.sla = this.slaSubject.asObservable();
        this.hourSubject = new BehaviorSubject<Hour>(null);
        this.hour = this.hourSubject.asObservable();
        this.topicSubject = new BehaviorSubject<Topic>(null);
        this.topic = this.topicSubject.asObservable();
        this.statusSubject = new BehaviorSubject<Status>(null);
        this.status = this.statusSubject.asObservable();
        this.taskStatusSubject = new BehaviorSubject<TaskStatus>(null);
        this.taskStatus = this.taskStatusSubject.asObservable();
        this.taskTypeSubject = new BehaviorSubject<TaskType>(null);
        this.taskType = this.taskTypeSubject.asObservable();
        this.sourceSubject = new BehaviorSubject<Source>(null);
        this.projectStatusSubject = new BehaviorSubject<ProjectStatus>(null);
        this.projectStatus = this.projectStatusSubject.asObservable();
        this.projectTypeSubject = new BehaviorSubject<ProjectType>(null);
        this.projectType = this.projectTypeSubject.asObservable();
        this.sourceSubject = new BehaviorSubject<Source>(null);
        this.source = this.sourceSubject.asObservable();
        this.typeSubject = new BehaviorSubject<Type>(null);
        this.type = this.typeSubject.asObservable();
        this.helpSubject = new BehaviorSubject<Help>(null);
        this.help = this.helpSubject.asObservable();
        this.prioritySubject = new BehaviorSubject<Priority>(null);
        this.priority = this.prioritySubject.asObservable();
        this.teamSubject = new BehaviorSubject<Team>(null);
        this.team = this.teamSubject.asObservable();
        this.languageSubject = new BehaviorSubject<Language>(null);
        this.language = this.languageSubject.asObservable();
        this.departmentSubject = new BehaviorSubject<Department>(null);
        this.department = this.departmentSubject.asObservable();
        this.classificationSubject = new BehaviorSubject<Classification>(null);
        this.classification = this.classificationSubject.asObservable();
        this.notificationSubject = new BehaviorSubject<Notification>(null);
        this.notification = this.notificationSubject.asObservable();
        this.milestoneSubject = new BehaviorSubject<Milestone>(null);
        this.milestone = this.milestoneSubject.asObservable();
        this.requestSubject = new BehaviorSubject<Request>(null);
        this.request = this.requestSubject.asObservable();
        this.requestTypeSubject = new BehaviorSubject<RequestType>(null);
        this.request = this.requestSubject.asObservable();
        this.impactSubject = new BehaviorSubject<Impact>(null);
        this.impact = this.impactSubject.asObservable();
        this.levelSubject = new BehaviorSubject<Level>(null);
        this.level = this.levelSubject.asObservable();
        this.urgencySubject = new BehaviorSubject<Urgency>(null);
        this.urgency = this.urgencySubject.asObservable();
        this.categorySubject = new BehaviorSubject<Category>(null);
        this.category = this.categorySubject.asObservable();
    }

    public get slaValue(): Sla {
        return this.slaSubject.value;
    }
    public get hourValue(): Hour {
        return this.hourSubject.value;
    }

    public get topicValue(): Topic {
        return this.topicSubject.value;
    }
    public get statusValue(): Status {
        return this.statusSubject.value;
    }
    public get taskStatusValue(): TaskStatus {
        return this.taskStatusSubject.value;
    }
    public get taskTypeValue(): TaskType {
        return this.taskTypeSubject.value;
    }
    public get projectStatusValue(): ProjectStatus {
        return this.projectStatusSubject.value;
    }
    public get projectTypeValue(): ProjectType {
        return this.projectTypeSubject.value;
    }
    public get sourceValue(): Source {
        return this.sourceSubject.value;
    }

    public get helpValue(): Help {
        return this.helpSubject.value;
    }
    public get priorityValue(): Priority {
        return this.prioritySubject.value;
    }
    public get teamValue(): Team {
        return this.teamSubject.value;
    }

    public get departmentValue(): Department {
        return this.departmentSubject.value;
    }
    public get languageValue(): Language {
        return this.languageSubject.value;
    }
    public get typeValue(): Type {
        return this.typeSubject.value;
    }
    public get classificationValue(): Classification {
        return this.classificationSubject.value;
    }
    public get notificationValue(): Notification {
        return this.notificationSubject.value;
    }
    public get milestoneValue(): Milestone {
        return this.milestoneSubject.value;
    }
    public get requestValue(): Request {
        return this.requestSubject.value;
    }
    public get requestTypeValue(): RequestType {
        return this.requestTypeSubject.value;
    }
    public get impactValue(): Impact {
        return this.impactSubject.value;
    }
    public get levelValue(): Level {
        return this.levelSubject.value;
    }
    public get urgencyValue(): Urgency {
        return this.urgencySubject.value;
    }
    public get categoryValue(): Category {
        return this.categorySubject.value;
    }

    getAllUrgency() {
        return this.http.get<Urgency[]>(urgencyUrl);
    }
    getAllSla() {
        return this.http.get<Sla[]>(slaUrl);
    }
    getAllHour() {
        return this.http.get<Hour[]>(hourUrl);
    }
    getAllTopic() {
        return this.http.get<Topic[]>(topicUrl);
    }
    getTopicPerProject(id: string) {
        return this.http.get<Topic[]>(`${topicUrl}/project/all/${id}`);
    }
    getAllStatus() {
        return this.http.get<Status[]>(statusUrl);
    }
    getAllTaskStatus() {
        return this.http.get<TaskStatus[]>(taskStatusUrl);
    }
    getAllProjectType() {
        return this.http.get<ProjectType[]>(projectTypeUrl);
    }
    getAllProjectStatus() {
        return this.http.get<ProjectStatus[]>(projectStatusUrl);
    }
    getAllTaskType() {
        return this.http.get<TaskType[]>(taskTypeUrl);
    }
    getAllSource() {
        return this.http.get<Source[]>(sourceUrl);
    }

    getAllType() {
        return this.http.get<Type[]>(typeUrl);
    }

    getAllPriority() {
        return this.http.get<Priority[]>(priorityUrl);
    }

    getAllTeam() {
        return this.http.get<Team[]>(teamUrl);
    }

    getAllClassification() {
        return this.http.get<Classification[]>(classificationUrl);
    }

    getAllHelp() {
        return this.http.get<Help[]>(helpUrl);
    }
    getAllDepartment() {
        return this.http.get<Department[]>(departmentUrl);
    }

    getAllLanguage() {
        return this.http.get<Language[]>(languageUrl);
    }
    getAllNotification() {
        return this.http.get<Notification[]>(notificationUrl);
    }
    getAllRequest() {
        return this.http.get<Request[]>(requestUrl);
    }
    getAllRequestType() {
        return this.http.get<RequestType[]>(requestTypeUrl);
    }
    getAllMilestone() {
        return this.http.get<Milestone[]>(milestoneUrl);
    }
    getAllImpact() {
        return this.http.get<Impact[]>(impactUrl);
    }
    getAllLevel() {
        return this.http.get<Level[]>(levelUrl);
    }
    getAllCategory() {
        return this.http.get<Category[]>(categoryUrl);
    }
    getProjectMilestone(id: string) {
        return this.http.get<Milestone[]>(`${milestoneUrl}/project/all/${id}`);
    }
    getModuleNotification(module: string) {
        return this.http.get<Notification[]>(`${notificationUrl}/module/${module}`);
    }
    getNotificationById(id: string) {
        return this.http.get<Notification>(`${notificationUrl}/${id}`);
    }
    getHelp() {
        return this.http.get<Help[]>(helpUrl);
    }
    getUrgencyById(id: string) {
        return this.http.get<Urgency>(`${urgencyUrl}/${id}`);
    }
    getCategoryById(id: string) {
        return this.http.get<Category>(`${categoryUrl}/${id}`);
    }
    getSlaById(id: string) {
        return this.http.get<Sla>(`${slaUrl}/${id}`);
    }

    getHourById(id: string) {
        return this.http.get<Hour>(`${hourUrl}/${id}`);
    }
    getTopicById(id: string) {
        return this.http.get<Topic>(`${topicUrl}/${id}`);
    }
    getStatusById(id: string) {
        return this.http.get<Status>(`${statusUrl}/${id}`);
    }
    getTaskStatusById(id: string) {
        return this.http.get<TaskStatus>(`${taskStatusUrl}/${id}`);
    }
    getTaskTypeById(id: string) {
        return this.http.get<TaskType>(`${taskTypeUrl}/${id}`);
    }
    getProjectStatusById(id: string) {
        return this.http.get<ProjectStatus>(`${projectStatusUrl}/${id}`);
    }
    getProjectTypeById(id: string) {
        return this.http.get<ProjectType>(`${projectTypeUrl}/${id}`);
    }
    getSourceById(id: string) {
        return this.http.get<Source>(`${sourceUrl}/${id}`);
    }

    getTypeById(id: string) {
        return this.http.get<Type>(`${typeUrl}/${id}`);
    }

    getPriorityById(id: string) {
        return this.http.get<Priority>(`${priorityUrl}/${id}`);
    }

    getClassificationById(id: string) {
        return this.http.get<Classification>(`${classificationUrl}/${id}`);
    }

    getTeamById(id: string) {
        return this.http.get<Team>(`${teamUrl}/${id}`);
    }

    getDepartmentById(id: string) {
        return this.http.get<Department>(`${departmentUrl}/${id}`);
    }

    getLanguageById(id: string) {
        return this.http.get<Language>(`${languageUrl}/${id}`);
    }
    getHelpById(id: string) {
        return this.http.get<Help>(`${helpUrl}/${id}`);
    }
    getMilestoneById(id: string) {
        return this.http.get<Milestone>(`${milestoneUrl}/${id}`);
    }
    getRequestById(id: string) {
        return this.http.get<Request>(`${requestUrl}/${id}`);
    }
    getRequestTypeById(id: string) {
        return this.http.get<Request>(`${requestTypeUrl}/${id}`);
    }
    getImpactById(id: string) {
        return this.http.get<Impact>(`${impactUrl}/${id}`);
    }
    getLevelById(id: string) {
        return this.http.get<Level>(`${levelUrl}/${id}`);
    }
    createLevel(params) {
        return this.http.post(levelUrl, params);
    }
    updateLevel(id, params) {
        return this.http.put(`${levelUrl}/${id}`, params)
            .pipe(map((level: any) => {
                    this.level = { ...this.levelValue, ...level };
                    this.levelSubject.next(level);
              
                return level;
            }));
    }
    deleteLevel(id: string) {
        return this.http.delete(`${levelUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createTopic(params) {
        return this.http.post(topicUrl, params);
    }
    updateTopic(id, params) {
        return this.http.put(`${topicUrl}/${id}`, params)
            .pipe(map((topic: any) => {
                    this.topic = { ...this.topicValue, ...topic };
                    this.topicSubject.next(topic);
              
                return topic;
            }));
    }
    deleteTopic(id: string) {
        return this.http.delete(`${topicUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createStatus(params) {
        return this.http.post(statusUrl, params);
    }
    
    updateStatus(id, params) {
        return this.http.put(`${statusUrl}/${id}`, params)
            .pipe(map((status: any) => {
                    this.status = { ...this.statusValue, ...status };
                    this.statusSubject.next(status);
              
                return status;
            }));
    }
    deleteStatus(id: string) {
        return this.http.delete(`${statusUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createTaskStatus(params) {
        return this.http.post(taskStatusUrl, params);
    }
    
    updateTaskStatus(id, params) {
        return this.http.put(`${taskStatusUrl}/${id}`, params)
            .pipe(map((taskStatus: any) => {
                    this.taskStatus = { ...this.taskStatusValue, ...taskStatus };
                    this.taskStatusSubject.next(taskStatus);
              
                return taskStatus;
            }));
    }
    deleteTaskStatus(id: string) {
        return this.http.delete(`${taskStatusUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createTaskType(params) {
        return this.http.post(taskTypeUrl, params);
    }
    
    updateTaskType(id, params) {
        return this.http.put(`${taskTypeUrl}/${id}`, params)
            .pipe(map((taskType: any) => {
                    this.taskType = { ...this.taskTypeValue, ...taskType };
                    this.taskTypeSubject.next(taskType);
              
                return taskType;
            }));
    }
    deleteTaskType(id: string) {
        return this.http.delete(`${taskTypeUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createProjectStatus(params) {
        return this.http.post(projectStatusUrl, params);
    }
    
    updateProjectStatus(id, params) {
        return this.http.put(`${projectStatusUrl}/${id}`, params)
            .pipe(map((projectStatus: any) => {
                    this.projectStatus = { ...this.taskStatusValue, ...projectStatus };
                    this.projectStatusSubject.next(projectStatus);
              
                return projectStatus;
            }));
    }
    deleteProjectStatus(id: string) {
        return this.http.delete(`${projectStatusUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createProjectType(params) {
        return this.http.post(projectTypeUrl, params);
    }
    
    updateProjectType(id, params) {
        return this.http.put(`${projectTypeUrl}/${id}`, params)
            .pipe(map((projectType: any) => {
                    this.projectType = { ...this.projectTypeValue, ...projectType };
                    this.projectTypeSubject.next(projectType);
              
                return projectType;
            }));
    }
    deleteProjectType(id: string) {
        return this.http.delete(`${projectTypeUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createSource(params) {
        return this.http.post(sourceUrl, params);
    }
    
    updateSource(id, params) {
        return this.http.put(`${sourceUrl}/${id}`, params)
            .pipe(map((source: any) => {
                    this.source = { ...this.sourceValue, ...source };
                    this.sourceSubject.next(source);
              
                return source;
            }));
    }
    deleteSource(id: string) {
        return this.http.delete(`${sourceUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createType(params) {
        return this.http.post(typeUrl, params);
    }
    
    updateType(id, params) {
        return this.http.put(`${typeUrl}/${id}`, params)
            .pipe(map((type: any) => {
                    this.type = { ...this.typeValue, ...type };
                    this.typeSubject.next(type);
              
                return type;
            }));
    }
    deleteType(id: string) {
        return this.http.delete(`${typeUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createPriority(params) {
        return this.http.post(priorityUrl, params);
    }
    
    updatePriority(id, params) {
        return this.http.put(`${priorityUrl}/${id}`, params)
            .pipe(map((priority: any) => {
                    this.priority = { ...this.priorityValue, ...priority };
                    this.prioritySubject.next(priority);
              
                return priority;
            }));
    }
    deletePriority(id: string) {
        return this.http.delete(`${priorityUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createTeam(params) {
        return this.http.post(teamUrl, params);
    }
    
    updateTeam(id, params) {
        return this.http.put(`${teamUrl}/${id}`, params)
            .pipe(map((team: any) => {
                    this.team = { ...this.teamValue, ...team };
                    this.teamSubject.next(team);
              
                return team;
            }));
    }
    deleteTeam(id: string) {
        return this.http.delete(`${teamUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createHelp(params) {
        return this.http.post(helpUrl, params);
    }
    
    updateHelp(id, params) {
        return this.http.put(`${helpUrl}/${id}`, params)
            .pipe(map((help: any) => {
                    this.help = { ...this.helpValue, ...help };
                    this.helpSubject.next(help);
                return help;
            }));
    }
    deleteHelp(id: string) {
        return this.http.delete(`${helpUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createLanguage(params) {
        return this.http.post(languageUrl, params);
    }
    
    updateLanguage(id, params) {
        return this.http.put(`${languageUrl}/${id}`, params)
            .pipe(map((langauge: any) => {
                    this.language = { ...this.languageValue, ...langauge };
                    this.languageSubject.next(langauge);
                return langauge;
            }));
    }
    deleteLanguage(id: string) {
        return this.http.delete(`${languageUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createDepartment(params) {
        return this.http.post(departmentUrl, params);
    }
    
    updateDepartment(id, params) {
        return this.http.put(`${departmentUrl}/${id}`, params)
            .pipe(map((department: any) => {
                    this.department = { ...this.departmentValue, ...department };
                    this.departmentSubject.next(department);
                return department;
            }));
    }
    deleteDepartment(id: string) {
        return this.http.delete(`${departmentUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createClassification(params) {
        return this.http.post(classificationUrl, params);
    }
    
    updateClassification(id, params) {
        return this.http.put(`${classificationUrl}/${id}`, params)
            .pipe(map((classification: any) => {
                    this.classification = { ...this.classificationValue, ...classification };
                    this.classificationSubject.next(classification);
                return classification;
            }));
    }
    deleteClassification(id: string) {
        return this.http.delete(`${classificationUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createSla(params) {
        return this.http.post(slaUrl, params);
    }
    
    updateSla(id, params) {
        return this.http.put(`${slaUrl}/${id}`, params)
            .pipe(map((sla: any) => {
                    this.sla = { ...this.slaValue, ...sla };
                    this.slaSubject.next(sla);
                return sla;
            }));
    }
    deleteSla(id: string) {
        return this.http.delete(`${slaUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createHour(params) {
        return this.http.post(hourUrl, params);
    }
    
    updateHour(id, params) {
        return this.http.put(`${hourUrl}/${id}`, params)
            .pipe(map((hour: any) => {
                    this.hour = { ...this.hourValue, ...hour };
                    this.hourSubject.next(hour);
                return hour;
            }));
    }
    deleteHour(id: string) {
        return this.http.delete(`${hourUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createNotification(params) {
        return this.http.post(notificationUrl, params);
    }
    
    updateNotification(id, params) {
        return this.http.put(`${notificationUrl}/${id}`, params)
            .pipe(map((notification: any) => {
                    this.notification = { ...this.notificationValue, ...notification };
                    this.notificationSubject.next(notification);
                return notification;
            }));
    }
    deleteNotification(id: string) {
        return this.http.delete(`${notificationUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createMilestone(params) {
        return this.http.post(milestoneUrl, params);
    }
    
    updateMilestone(id, params) {
        return this.http.put(`${milestoneUrl}/${id}`, params)
            .pipe(map((milestone: any) => {
                    this.milestone = { ...this.milestoneValue, ...milestone };
                    this.milestoneSubject.next(milestone);
                return milestone;
            }));
    }
    deleteMilestone(id: string) {
        return this.http.delete(`${milestoneUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createRequest(params) {
        return this.http.post(requestUrl, params);
    }
    
    updateRequest(id, params) {
        return this.http.put(`${requestUrl}/${id}`, params)
            .pipe(map((request: any) => {
                    this.request = { ...this.requestValue, ...request };
                    this.requestSubject.next(request);
                return request;
            }));
    }
    deleteRequest(id: string) {
        return this.http.delete(`${requestUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createRequestType(params) {
        return this.http.post(requestTypeUrl, params);
    }
    
    updateRequestType(id, params) {
        return this.http.put(`${requestTypeUrl}/${id}`, params)
            .pipe(map((requestType: any) => {
                    this.request = { ...this.requestTypeValue, ...requestType };
                    this.requestTypeSubject.next(requestType);
                return requestType;
            }));
    }
    deleteRequestType(id: string) {
        return this.http.delete(`${requestTypeUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    
    createImpact(params) {
        return this.http.post(impactUrl, params);
    }
    
    updateImpact(id, params) {
        return this.http.put(`${impactUrl}/${id}`, params)
            .pipe(map((impact: any) => {
                    this.impact = { ...this.impactValue, ...impact };
                    this.impactSubject.next(impact);
                return impact;
            }));
    }
    deleteImpact(id: string) {
        return this.http.delete(`${impactUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createUrgency(params) {
        return this.http.post(urgencyUrl, params);
    }
    updateUrgency(id, params) {
        return this.http.put(`${urgencyUrl}/${id}`, params)
            .pipe(map((urgency: any) => {
                    this.urgency = { ...this.urgencyValue, ...urgency };
                    this.urgencySubject.next(urgency);
                return urgency;
            }));
    }
    deleteUrgency(id: string) {
        return this.http.delete(`${urgencyUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createCategory(params) {
        return this.http.post(categoryUrl, params);
    }
    updateCategory(id, params) {
        return this.http.put(`${categoryUrl}/${id}`, params)
            .pipe(map((category: any) => {
                    this.category = { ...this.categoryValue, ...category };
                    this.categorySubject.next(category);
                return category;
            }));
    }
    deleteCategory(id: string) {
        return this.http.delete(`${categoryUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   
    
}
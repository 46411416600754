

export class TicketReport {
    id: string;
    year: string;
    month: string;
    week: string;
    date: string;
    hour: string;
    uyear: string;
    umonth: string;
    uweek: string;
    udate: string;
    uhour: string;
    ticketId: string;
    customerId: string;
    ticketNo: string;
    created: string;
    updated: string;
    closed: string;
    escalated: string;
    resolved: string;
    subject: string;
    customerName: string;
    businessName: string;
    mobile: string;
    category: string;
    classificationId: string;
    channel: string;
    ticketSourceId: string;
    ticketLevelId: string;
    status;
    priority;
    ticketStatusId;
    dept: string;
    departmentId: string;
    team: string;
    supportTeamId: string;
    rtmmappingId: string;
    agentId: string;
    proj: string;
    projectId: string;
    atype: string;
    accountTypeId: string;
    region: string;
    zone: string;
    city: string;
    subcity: string;
    woreda: string;
    kebele: string;
    sefer: string;
    reporter: string;
    worker: string;
    createdById: string;
    updatedById: string;
    reportedById: string;
    accountId: string;
    number: string;

    ticketLevel ;
    stats;
    ticketStatus;
    classification;
    duesoon;
    active: boolean;
}


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewComponent } from './view.component';
const profileModule = () => import('../profile/profile.module').then(x => x.ProfileModule);
const logoutModule = () => import('../logout/logout.module').then(x => x.LogoutModule);
const passwordModule = () => import('../password/password.module').then(x => x.PasswordModule);
const accountsModule = () => import('./accounts/accounts.module').then(x => x.AccountsModule);
const departmentModule = () => import('./deparmtnet/deparments/departments.module').then(x => x.DepartmentsModule);
const roleModule = () => import('./role/roles/roles.module').then(x => x.RolesModule);
const teamModule = () => import('./supportteam/deparments/supportteam.module').then(x => x.SupportTeamModule);
const moduleModule = () => import('./module/modules/modules.module').then(x => x.ModulesModule);
const permissionModule = () => import('./permission/roles/permissions.module').then(x => x.PermissionsModule);
const routes: Routes = [
    {
        path: '', component: ViewComponent,
        children: [
            { path: 'department', loadChildren: departmentModule },
            { path: 'account', loadChildren: accountsModule },
            { path: 'role', loadChildren: roleModule },
            { path: 'team', loadChildren: teamModule },
            { path: 'module', loadChildren: moduleModule },
            { path: 'permission', loadChildren: permissionModule },
            { path: 'profile', loadChildren: profileModule },
            { path: 'logout', loadChildren: logoutModule },
            { path: 'password', loadChildren: passwordModule },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AccountRoutingModule { }


export class Access {
    Accounts;
    AreaName;
    City;
    Country;
    Department;
    Kebele;
    Modules;
    Pages;
    Permission;
    Region;
    Role;
    Subcity;
    SupportTeam;
    Word;
    Zone;
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Country, Woreda, SubCity,Region,Zone,City,Kebele,Sefer,Address} from '../_models';

const addressUrl = `${environment.apiUrl}/address`;
const countryUrl = `${environment.apiUrl}/country`;
const regionUrl = `${environment.apiUrl}/region`;
const zoneUrl = `${environment.apiUrl}/zone`;
const cityUrl = `${environment.apiUrl}/city`;
const subCityUrl = `${environment.apiUrl}/subcity`;
const woredaUrl = `${environment.apiUrl}/woreda`;
const kebeleUrl = `${environment.apiUrl}/kebele`;
const seferUrl = `${environment.apiUrl}/sefer`;
const businessUrl = `${environment.apiUrl}/business`;

@Injectable({ providedIn: 'root' })
export class AddressService {
    private addressSubject: BehaviorSubject<Address>;
    public address: Observable<Address>;
    private countrySubject: BehaviorSubject<Country>;
    public country: Observable<Country>;
    private regionSubject: BehaviorSubject<Region>;
    public region: Observable<Region>;
    private zoneSubject: BehaviorSubject<Zone>;
    public zone: Observable<Zone>;
    private woredaSubject: BehaviorSubject<Woreda>;
    public woreda: Observable<Woreda>;
    private citySubject: BehaviorSubject<City>;
    public city: Observable<City>;
    private subCitySubject: BehaviorSubject<SubCity>;
    public SubCity: Observable<SubCity>;
    private kebeleSubject: BehaviorSubject<Kebele>;
    public kebele: Observable<Kebele>;
    private seferSubject: BehaviorSubject<Sefer>;
    public sefer: Observable<Sefer>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.addressSubject = new BehaviorSubject<Address>(null);
        this.address = this.addressSubject.asObservable();
        this.countrySubject = new BehaviorSubject<Country>(null);
        this.country = this.countrySubject.asObservable();
        this.regionSubject = new BehaviorSubject<Region>(null);
        this.region = this.regionSubject.asObservable();
        this.zoneSubject = new BehaviorSubject<Zone>(null);
        this.zone = this.zoneSubject.asObservable();
        this.woredaSubject = new BehaviorSubject<Woreda>(null);
        this.woreda = this.woredaSubject.asObservable();
        this.citySubject = new BehaviorSubject<City>(null);
        this.city = this.citySubject.asObservable();
        this.subCitySubject = new BehaviorSubject<SubCity>(null);
        this.SubCity = this.subCitySubject.asObservable();
        this.kebeleSubject = new BehaviorSubject<Kebele>(null);
        this.kebele = this.kebeleSubject.asObservable();
        this.seferSubject = new BehaviorSubject<Sefer>(null);
        this.sefer = this.seferSubject.asObservable();
    }

    public get addressValue(): Address {
        return this.addressSubject.value;
    }
    public get countryValue(): Country {
        return this.countrySubject.value;
    }
    public get regionValue(): Region {
        return this.regionSubject.value;
    }

    public get zoneValue(): Zone {
        return this.zoneSubject.value;
    }

    public get woredaValue(): Woreda {
        return this.woredaSubject.value;
    }

    public get cityValue(): City {
        return this.citySubject.value;
    }

    public get subCityValue(): SubCity {
        return this.subCitySubject.value;
    }

    public get kebeleValue(): Kebele {
        return this.kebeleSubject.value;
    }

    public get seferValue(): Sefer {
        return this.seferSubject.value;
    }

   
    getAllAddress() {
        return this.http.get<Address[]>(addressUrl);
    }
    getAllCountry() {
        return this.http.get<Country[]>(countryUrl);
    }
    getAllRegion() {
        return this.http.get<Region[]>(regionUrl);
    }

    getAllBusinessRegion(id: string) {
        return this.http.get<Region[]>(`${businessUrl}/region/${id}`);
    }

    getAllZone() {
        return this.http.get<Zone[]>(zoneUrl);
    }

    getAllWoreda() {
        return this.http.get<Woreda[]>(woredaUrl);
    }

    getAllCity() {
        return this.http.get<City[]>(cityUrl);
    }

    getAllSubCity() {
        return this.http.get<SubCity[]>(subCityUrl);
    }

    getAllKebele() {
        return this.http.get<Kebele[]>(kebeleUrl);
    }

    getAllSefer() {
        return this.http.get<Sefer[]>(seferUrl);
    }

   //country Method
   getAddressById(id: string) {
    return this.http.get<Address>(`${addressUrl}/${id}`);
    }
    getCustomerAddress(id: string) {
        return this.http.get<Address>(`${addressUrl}/customer/${id}`);
        }
    getCountryById(id: string) {
        return this.http.get<Country>(`${countryUrl}/${id}`);
    }
    getRegionsOfCountry(id: string) {
        return this.http.get<Region[]>(`${countryUrl}/regionincountry/${id}`);
    }
    
    getCityOfRegion(id: string) {
        return this.http.get<City[]>(`${regionUrl}/cityinregion/${id}`);
    }
    
    getCityInZoneById(id: string) {
        return this.http.get<City>(`${zoneUrl}/cityinzone/${id}`);
    }
    getSubCitiesOfCity(id: string) {
        return this.http.get<SubCity[]>(`${cityUrl}/subcityincity/${id}`);
    }
    
    getWoredaInSubCityById(id: string) {
        return this.http.get<Woreda>(`${subCityUrl}/woredainsubcity/${id}`);
    }

    getKebeleInSubCityById(id: string) {
        return this.http.get<Kebele>(`${subCityUrl}/kebeleinsubcity/${id}`);
    }

    getSeferInSubCityById(id: string) {
        return this.http.get<Sefer>(`${subCityUrl}/seferinsubcity/${id}`);
    }
    createAddress(params) {
        return this.http.post(addressUrl, params);
    }
    
    updateAddress(id, params) {
        return this.http.put(`${addressUrl}/${id}`, params)
            .pipe(map((address: any) => {
                    this.address = { ...this.addressValue, ...address };
                    this.countrySubject.next(address);
              
                return address;
            }));
    }
    deleteAddress(id: string) {
        return this.http.delete(`${addressUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createCountry(params) {
        return this.http.post(countryUrl, params);
    }
    
    updateCountry(id, params) {
        return this.http.put(`${countryUrl}/${id}`, params)
            .pipe(map((country: any) => {
                    this.country = { ...this.countryValue, ...country };
                    this.countrySubject.next(country);
              
                return country;
            }));
    }
    deleteCountry(id: string) {
        return this.http.delete(`${countryUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    //Region Method
    getRegionById(id: string) {
        return this.http.get<Region>(`${regionUrl}/${id}`);
    }
    
    createRegion(params) {
        return this.http.post(regionUrl, params);
    }
    
    updateRegion(id, params) {
        return this.http.put(`${regionUrl}/${id}`, params)
            .pipe(map((region: any) => {
                    region = { ...this.regionValue, ...region };
                    this.regionSubject.next(region);
              
                return region;
            }));
    }
    deleteRegion(id: string) {
        return this.http.delete(`${regionUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }


    //Zone Method
    getZoneById(id: string) {
        return this.http.get<Zone>(`${zoneUrl}/${id}`);
    }

    getWoredaById(id: string) {
        return this.http.get<Woreda>(`${woredaUrl}/${id}`);
    }
    
    
    createZone(params) {
        return this.http.post(zoneUrl, params);
    }
    
    updateZone(id, params) {
        return this.http.put(`${zoneUrl}/${id}`, params)
            .pipe(map((zone: any) => {
                    zone = { ...this.zoneValue, ...zone };
                    this.zoneSubject.next(zone);
              
                return zone;
            }));
    }
    deleteZone(id: string) {
        return this.http.delete(`${zoneUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    //woreda Method
    getCityById(id: string) {
        return this.http.get<City>(`${cityUrl}/${id}`);
    }
    getSubCityinCityById(id: string) {
        return this.http.get<City>(`${cityUrl}/subcityincity/${id}`);
    }
    
    createWoreda(params) {
        return this.http.post(woredaUrl, params);
    }
    
    updateWoreda(id, params) {
        return this.http.put(`${woredaUrl}/${id}`, params)
            .pipe(map((woreda: any) => {
                woreda = { ...this.woredaValue, ...woreda };
                    this.woredaSubject.next(woreda);
              
                return woreda;
            }));
    }
    deleteDistrict(id: string) {
        return this.http.delete(`${woredaUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

     //City Method
     getCitytById(id: string) {
        return this.http.get<City>(`${cityUrl}/${id}`);
    }
    
    createCity(params) {
        return this.http.post(cityUrl, params);
    }
    
    updateCity(id, params) {
        return this.http.put(`${cityUrl}/${id}`, params)
            .pipe(map((city: any) => {
                city = { ...this.cityValue, ...city };
                    this.citySubject.next(city);
              
                return city;
            }));
    }
    deleteCity(id: string) {
        return this.http.delete(`${cityUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

     //SubCity Method
     getSubCityById(id: string) {
        return this.http.get<SubCity>(`${subCityUrl}/${id}`);
    }
    getSubCityDetail(id: string) {
        return this.http.get<City>(`${subCityUrl}/detail/${id}`);
    }
    createSubCity(params) {
        return this.http.post(subCityUrl, params);
    }
    
    updateSubCity(id, params) {
        return this.http.put(`${subCityUrl}/${id}`, params)
            .pipe(map((subCity: any) => {
                subCity = { ...this.subCityValue, ...subCity };
                    this.seferSubject.next(subCity);
              
                return subCity;
            }));
    }
    deleteSubCity(id: string) {
        return this.http.delete(`${subCityUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

     //kebele Method
     getKebeleById(id: string) {
        return this.http.get<Kebele>(`${kebeleUrl}/${id}`);
    }
    
    createKebele(params) {
        return this.http.post(kebeleUrl, params);
    }
    
    updateKebele(id, params) {
        return this.http.put(`${kebeleUrl}/${id}`, params)
            .pipe(map((kebele: any) => {
                kebele = { ...this.kebeleValue, ...kebele };
                    this.kebeleSubject.next(kebele);
                return kebele;
            }));
    }
    deleteKebele(id: string) {
        return this.http.delete(`${kebeleUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

     //sefer Method
     getSeferById(id: string) {
        return this.http.get<Sefer>(`${seferUrl}/${id}`);
    }
    
    createSefer(params) {
        return this.http.post(seferUrl, params);
    }
    
    updateSefer(id, params) {
        return this.http.put(`${seferUrl}/${id}`, params)
            .pipe(map((sefer: any) => {
                sefer = { ...this.seferValue, ...sefer };
                    this.seferSubject.next(sefer);
              
                return sefer;
            }));
    }
    deleteSefer(id: string) {
        return this.http.delete(`${seferUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    
   

    
}
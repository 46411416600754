import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Business } from '../_models/business';
import { Pricing } from '../_models/pricing';
import { Account } from '../_models/account';

const baseUrl = `${environment.apiUrl}/business`;
const productUrl = `${environment.apiUrl}/product`;
const accountUrl = `${environment.apiUrl}/accounts`;


@Injectable({ providedIn: 'root' })
export class BusinessService {
    private businessSubject: BehaviorSubject<Business>;
    private productSubject: BehaviorSubject<Pricing>;
    private accountSubject: BehaviorSubject<Account>;
    public business: Observable<Business>;
    public product: Observable<Pricing>;
    public account: Observable<Account>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.businessSubject = new BehaviorSubject<Business>(null);
        this.productSubject = new BehaviorSubject<Pricing>(null);
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.business = this.businessSubject.asObservable();
    }

    public get businessValue(): Business {
        return this.businessSubject.value;
    }

    public get productValue(): Pricing {
        return this.productSubject.value;
    }

    register(business: Business) {
        return this.http.post(`${baseUrl}/register`, business);
    }

    getAll() {
        return this.http.get<Business[]>(baseUrl);
    }
    getAllProducts(id: string) {
        return this.http.get<Pricing[]>(`${productUrl}/product/${id}`);
    }
    getAllUsers(id: string) {
        return this.http.get<Account[]>(`${baseUrl}/account/${id}`);
    }

    getById(id: string) {
        return this.http.get<Business>(`${baseUrl}/${id}`);
    }
    getByProductById(id: string) {
        return this.http.get<Pricing>(`${baseUrl}/product/${id}`);
    }

    getByAccountById(id: string) {
        return this.http.get<Account>(`${accountUrl}/${id}`);
    }
    
    create(params) {
        return this.http.post(baseUrl, params);
    }
    createProduct(params) {
        return this.http.post(`${baseUrl}/product`, params);
    }
 
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((business: any) => {
                    // publish updated business to subscribers
                    business = { ...this.businessValue, ...business };
                    this.businessSubject.next(business);
              
                return business;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    updateProduct(id, params) {
        return this.http.put(`${productUrl}/${id}`, params)
            .pipe(map((product: any) => {
                    // publish updated business to subscribers
                    product = { ...this.productValue, ...product };
                    this.productSubject.next(product);
              
                return product;
            }));
    }
    deleteProduct(id: string) {
        return this.http.delete(`${productUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    deleteAccount(id: string) {
        return this.http.delete(`${accountUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomeComponent } from './home.component';
import { AuthGuard } from '../_helpers';
import { Role } from '../_models';


const accountModule = () => import('../account/account.module').then(x => x.AccountModule);
const adminModule = () => import('../admin/admin.module').then(x => x.AdminModule);
const profileModule = () => import('../profile/profile.module').then(x => x.ProfileModule);

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'homeside/:id', loadChildren: accountModule },
    { path: 'homeprofile', loadChildren: profileModule, canActivate: [AuthGuard] },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class HomeRoutingModule { }

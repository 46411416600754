import { Component ,OnInit} from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { AccountService,ApplcationService } from '../../../_services';
import { first } from 'rxjs/operators';

@Component({
    selector: 'vendor-layout',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
  })
export class FaqComponent implements OnInit {
    app;
    id: string;
    pId: string;
    account = this.accountService.accountValue;
    

    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private accountService: AccountService,
      private applcationService: ApplcationService
      
    ) { }
    reloadCurrentPage() {
      window.location.reload();
     }
    ngOnInit() {
      this.id = this.route.snapshot.params['id'];
      this.pId = this.route.snapshot.params['pId'];
      
        this.applcationService.getAllPricingByIds(this.id)
            .pipe(first())
            .subscribe(app => this.app = app);

    }

}
import { NgModule } from '@angular/core';
import { Routes, RouterModule,ExtraOptions } from '@angular/router';

import { HomeComponent } from './home';
import { PrcingComponent } from './home/pages/prcing.component';
import { FaqComponent } from './home/pages/faq/faq.component';
import { CartComponent } from './home/pages/cart/cart.component';
import { AccountComponent } from './account';
import { AuthGuard } from './_helpers';
import { Role } from './_models';

const authModule = () => import('./auth/auth.module').then(x => x.AuthModule);
const homepageModule = () => import('./homepage/homepage.module').then(x => x.HomePageModule);
const homeModule = () => import('./home/home.module').then(x => x.HomeModule);
const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);
const profileModule = () => import('./profile/profile.module').then(x => x.ProfileModule);
const logoutModule = () => import('./logout/logout.module').then(x => x.LogoutModule);
const passwordModule = () => import('./password/password.module').then(x => x.PasswordModule);
const addressModule = () => import('./address/address.module').then(x => x.AddressModule);
const callcenterModule = () => import('./callcenter/callcenter.module').then(x => x.CallcenterModule);
const cdrModule = () => import('./cdr/cdrs/cdr.module').then(x => x.CdrModule);
const callModule = () => import('./call/calls/call.module').then(x => x.CallModule);
const menuModule = () => import('./menu/menu.module').then(x => x.MenuModule);
const customerModule = () => import('./customer/customer.module').then(x => x.CustomerModule);
const ticketModule = () => import('./ticket/ticket.module').then(x => x.TicketModule);
const taskModule = () => import('./task/task.module').then(x => x.TaskModule);
const crmreportModule = () => import('./crmreport/crmreport.module').then(x => x.CrmreportModule);
const settingModule = () => import('./setting/setting.module').then(x => x.SettingModule);
const dashboardModule = () => import('./dashboard/dashboard.module').then(x => x.DashboardModule);
const agentModule = () => import('./agentAnalytic/agentAnalytics/agent.module').then(x => x.AgentModule);
const deptAModule = () => import('./deptAnalytic/deptAnalytics/dept.module').then(x => x.DeptModule);
const teamModule = () => import('./teamAnalytic/teamAnalytics/team.module').then(x => x.TeamModule);
const topModule = () => import('./topAnalytic/topAnalytics/top.module').then(x => x.TopModule);
const rtmModule = () => import('./rtmAnalytic/rtmAnalytics/rtm.module').then(x => x.RtmModule);
const calllogModule = () => import('./calllogAnalytic/calllogAnalytics/calllog.module').then(x => x.CalllogModule);
const slaModule = () => import('./slaAnalytic/slaAnalytics/sla.module').then(x => x.SlaModule);
const taskSlaModule = () => import('./taskSlaAnalytic/taskSlaAnalytics/tasksla.module').then(x => x.TaskSlaModule);
const kdModule = () => import('./kdAnalytic/kdAnalytics/kd.module').then(x => x.KdModule);
const ticketAnalyticsModule = () => import('./ticketAnalytic/ticketAnalytics/ticket.module').then(x => x.TicketModule);
const taskAnalyticsModule = () => import('./taskAnalytic/taskAnalytics/task.module').then(x => x.TaskModule);
const projectAnalyticsModule = () => import('./projectAnalytic/projectAnalytics/project.module').then(x => x.ProjectModule);
const faqModule = () => import('./faq/faq.module').then(x => x.FaqModule);
const requestModule = () => import('./request/request.module').then(x => x.RequestModule);
const  knowledgebaseModule = () => import('./knowledgebase/knowledgebase.module').then(x => x.KnowledgeBaseModule);
const branchModule = () => import('./branch/branches/branch.module').then(x => x.BranchModule);
const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
  };
const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'dashboard',  loadChildren: dashboardModule, canActivate: [AuthGuard]},
    { path: 'menu', loadChildren: menuModule, canActivate: [AuthGuard]},
    { path: 'call', loadChildren: callModule, canActivate: [AuthGuard]},
    { path: 'crm', loadChildren: customerModule, canActivate: [AuthGuard]},
    { path: 'ticket', loadChildren: ticketModule, canActivate: [AuthGuard]},
    { path: 'task', loadChildren: taskModule, canActivate: [AuthGuard]},
    { path: 'callcenter', loadChildren: callcenterModule, canActivate: [AuthGuard]},
    // { path: 'br', loadChildren: smsModule, canActivate: [AuthGuard]},
    { path: 'cdr', loadChildren: cdrModule, canActivate: [AuthGuard]},
    { path: 'analytic', loadChildren: crmreportModule, canActivate: [AuthGuard]},
    { path: 'accounts', loadChildren: accountModule , canActivate: [AuthGuard]},
    { path: 'address', loadChildren: addressModule, canActivate: [AuthGuard] },
    { path: 'auth', loadChildren: authModule },
    { path: 'homepage', loadChildren: homepageModule },
    { path: 'profile', loadChildren: profileModule, canActivate: [AuthGuard] },
    { path: 'logout', loadChildren: logoutModule, canActivate: [AuthGuard] },
    { path: 'password', loadChildren: passwordModule, canActivate: [AuthGuard] },
    { path: 'settings', loadChildren: settingModule, canActivate: [AuthGuard]},
    { path: 'analytics/agent', loadChildren: agentModule, canActivate: [AuthGuard] },
    { path: 'analytics/department', loadChildren: deptAModule, canActivate: [AuthGuard] },
    { path: 'analytics/team', loadChildren: teamModule, canActivate: [AuthGuard] },
    { path: 'analytics/top', loadChildren: topModule, canActivate: [AuthGuard] },
    { path: 'analytics/rtm', loadChildren: rtmModule, canActivate: [AuthGuard] },
    { path: 'analytics/kd', loadChildren: kdModule, canActivate: [AuthGuard] },
    { path: 'analytics/ticket', loadChildren: ticketAnalyticsModule, canActivate: [AuthGuard] },
    { path: 'analytics/task', loadChildren: taskAnalyticsModule, canActivate: [AuthGuard] },
    { path: 'analytics/project', loadChildren: projectAnalyticsModule, canActivate: [AuthGuard] },
    { path: 'analytics/calllog', loadChildren: calllogModule, canActivate: [AuthGuard] },
    { path: 'analytics/sla', loadChildren: slaModule, canActivate: [AuthGuard] },
    { path: 'analytics/tasksla', loadChildren: taskSlaModule, canActivate: [AuthGuard] },
    { path: 'faq', loadChildren: faqModule },
    { path: 'request', loadChildren: requestModule },
    { path: 'knowledgebase', loadChildren: knowledgebaseModule },
    { path: 'branch', loadChildren: branchModule, canActivate: [AuthGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: ''},
];

@NgModule({
    imports: [RouterModule.forRoot(routes,routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

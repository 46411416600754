import { Component, ViewChild } from "@angular/core";
import { CrmService,TaskService,AccountService } from '../_services';
import * as moment from 'moment';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: any; // ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

@Component({
  selector: "app-root",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  moment = moment
 now = moment();
  ticketSlaStatus;
  taskSlaStatus;
  categories = [];
  priority =[];
  taskPriority =[];
  froms=[];
  topics = [];
  status =[];
  dailyTickets =[];
  date =[]
  uniqueDate =[]
  dailyStatus =[]
  uniqueStatus =[]
  stats =[];
  levels= []
  statusCount =[];
  x;
  y;
  statues= [];
  statusChart =[]
  taskProjects =[]
  taskProject =[]
  taskStatusChart =[]
  number;
  ticket : string;
  xaxis;
  taskxaxis;
  account = this.accountService.accountValue;
  taskstatus= [];
  highattention =[]
  todolists =[]
 //Chart

  constructor(
    private crmService: CrmService,
    private taskService: TaskService,
    private accountService: AccountService
  ) {
  
  }

  ngOnInit() {
  
    this.crmService.getTicketPriority(this.account.id)
    .subscribe(priority => this.priority = priority);
    this.crmService.getTaskPriority(this.account.id)
    .subscribe(taskPriority => this.taskPriority = taskPriority);
    this.crmService.getTicketSlaTicket(this.account.id)
    .subscribe(ticketSlaStatus => this.ticketSlaStatus = ticketSlaStatus);

    this.crmService.getTaskSlaTask(this.account.id)
    .subscribe(taskSlaStatus => this.taskSlaStatus = taskSlaStatus);

    this.crmService.getTicketDashboardStatus(this.account.id)
    .subscribe(status => this.status = status);


    this.crmService.getTaskDashboardStatus(this.account.id)
    .subscribe(taskstatus => this.taskstatus = taskstatus);
    this.crmService.gethighAttention(this.account.id)
    .subscribe(highattention => this.highattention = highattention);
    this.crmService.getTodoList(this.account.id)
    .subscribe(todolists => this.todolists = todolists);
    this.crmService.getDailyTicket(this.account.id)
    .subscribe(dtd =>{ 
      var open =[]
      var inprogress =[]
      var resolved = []
      var clsoed =[]
      var cancel =[]
      open[0] =0;
      inprogress[0] =0
      resolved[0]  =0
      clsoed[0]  =0
      cancel[0]  =0
      dtd.forEach(customerData => {
        this.date.push(customerData.date)
        this.dailyStatus.push(customerData.status)
        this.uniqueDate  = [...new Set(this.date)]; 
        this.uniqueStatus  = [...new Set(this.dailyStatus)]; 
        this.xaxis = {
          'categories' : this.uniqueDate
        }
       
        for (let index = 0; index < this.uniqueDate.length; index++) {
            if( customerData.status  === 'New' && customerData.date === this.uniqueDate[index] ){
                open[index] = customerData.number
            
            }else if(customerData.status === 'In Progress' && customerData.date === this.uniqueDate[index]){
                inprogress[index] = customerData.number
         
             }else  if(customerData.status === 'Resolved' && customerData.date === this.uniqueDate[index]){
                resolved[index] = customerData.number
        
             
             }else  if(customerData.status === 'Closed'&& customerData.date === this.uniqueDate[index]){
                clsoed[index] = customerData.number
            
             
             }else  if(customerData.status === 'Cancelled' && customerData.date === this.uniqueDate[index]){
              cancel[index] = customerData.number
           }else if(customerData.date === this.uniqueDate[index])  {
              open[index] =0;
              inprogress[index] =0
              resolved[index]  =0
              clsoed[index]  =0
              cancel[index]  =0
             }
             this.statusChart =[{'name' : 'new','data' : open},{'name' : 'In Progress','data' : inprogress},{'name' : 'Resolved','data' : resolved},{'name' : 'Closed','data' : clsoed},{'name' : 'Cancelled','data' : cancel}]
        }
        
     
        
          });
      
     });

    
    this.crmService.getTicketDashboardCategory(this.account.id)
    .subscribe(categories => this.categories = categories);
    this.taskService.getTaskStats(this.account.id)
    .subscribe(dtd =>{ 
      var open =[]
      var started =[]
      var inprogress =[]
      var onhold = []
      var waiting = []
      var completed =[]
      var clsoed =[]
      open[0] =0
      started[0] =0
      inprogress[0] =0
      onhold[0] = 0
      waiting[0] = 0
      completed[0] =0
      clsoed[0] =0
      dtd.forEach(taskData => {
        console.log(taskData);
        
        this.taskProjects.push(taskData.date)
        this.taskProject  = [...new Set(this.taskProjects)]; 
        this.taskxaxis = {
          'categories' : this.taskProject
        }
        for (let index = 0; index < this.taskProject.length; index++) {
            if( taskData.status  === 'New' && taskData.date === this.taskProject[index] ){
                  open[index] = taskData.number
            }else if(taskData.status === 'Started' && taskData.date === this.taskProject[index]){
                  started[index] = taskData.number
            }else if(taskData.status === 'In Progress' && taskData.date === this.taskProject[index]){
                  inprogress[index] = taskData.number
             }else  if(taskData.status === 'On Hold' && taskData.date === this.taskProject[index]){
                  onhold[index] = taskData.number
             }else if(taskData.status === 'Waiting' && taskData.date === this.taskProject[index]){
                   waiting[index] = taskData.number
             }else if(taskData.status === 'Completed' && taskData.date === this.taskProject[index]){
                  completed[index] = taskData.number
             }else if(taskData.status === 'Closed'&& taskData.date === this.taskProject[index]){
                  clsoed[index] = taskData.number
             }else if(taskData.date === this.taskProject[index])  {
                    open[index] =0
                    started[index] =0
                    inprogress[index] =0
                    onhold[index] = 0
                    waiting[index] = 0
                    completed[index] =0
                    clsoed[index] =0
             }
             this.taskStatusChart =[{'name' : 'new','data' : open},{'name' : 'Started','data' : started},{'name' : 'In Progress','data' : inprogress},{'name' : 'On Hold','data' : onhold},{'name' : 'Completed','data' : completed},{'name' : 'Closed','data' : clsoed}]
        }
          });
      
     }); 

  }
  public  areaChart = {
    chart: {
      height: 300,
      type: "area"
    },
    colors: ["#A70D2A", "#E8A317", "#3b3b71","#387C44"],
    dataLabels: {
      enabled: false
    },
    title: {
      text: "Total Ticket Summary statistics",
      align: "left",
      style: {
        color: "#074f79"
      }
    },
    stroke: {
      curve: "smooth"
    },
    xaxis: {
      categories: this.uniqueDate,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy "
      }
    }
  };

  public  taskAreaChart = {
    chart: {
      height: 300,
      type: "area"
    },
    dataLabels: {
      enabled: true,
    },
    title: {
      text: "Total Task Summary statistics",
      align: "left",
      style: {
        color: "#074f79"
      }
    },
    stroke: {
      curve: "smooth"
    },
    xaxis: {
      categories: this.uniqueDate,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy "
      }
    }
  };
}
 





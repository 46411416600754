import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AccountService } from '../_services';
import { Access } from '../_models';
import { first } from 'rxjs/operators';

@Component({
    selector: 'access',
    templateUrl: './access.html',
    styleUrls: ['./access.scss'],
})
export class AccessComponent {
    Page : String;
    @Input() page: Access;
    access;
    account = this.accountService.accountValue;
    modules : any[];
    constructor(private router: Router,    private accountService: AccountService) {}
    
    ngOnInit(): void {
        this.accountService.getAccess(this.page.Accounts,this.account.roleId)
        .pipe(first())
        .subscribe(access => this.access = access);
        this.accountService.getPagePermission('Account',this.account.roleId)
        .subscribe(modules => this.modules = modules);
      }
}

import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { AccountService } from '../../_services';
import { AuthGuard } from '../../_helpers';
import { Role } from '../../_models';
@Component({
  selector: 'app-vendor-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VendorHeaderComponent {
  account = this.accountService.accountValue;

  constructor(private accountService: AccountService) { }

  
}


import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SidebarComponent} from './sidebar/sidebar.component';
import { ViewComponent } from './view.component';
// import { AccessComponent } from './access.component';
import { RouterModule } from '@angular/router';
import { AccountModule } from './account.module';

@NgModule({
    declarations: [
        SidebarComponent,
        ViewComponent
    ],
    exports: [
        SidebarComponent,
        ViewComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    bootstrap: [],
    entryComponents: []
})


export class AccountSharedModule {
    static forRoot(): ModuleWithProviders<AccountModule> {
        return {
            ngModule: AccountModule,
            providers: []
        };
    }
 }

<div class="" id="container">

    <div class="form-container sign-in-container">
        <div *ngIf="tokenStatus == TokenStatus.Validating">
            Validating token...
        </div>
        <div *ngIf="tokenStatus == TokenStatus.Invalid">
            Token validation failed, if the token has expired you can get a new one at the <a routerLink="../forgot-password">forgot password</a> page.
        </div>
        <form *ngIf="tokenStatus == TokenStatus.Valid" [formGroup]="form" (ngSubmit)="onSubmit()">
        <h1 class="font-weight-bold">Reset Password</h1>
       
        <div class="form-group">
            <label>Password</label>
            <input type="password" formControlName="password" class="input-box" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
        </div>
        <div class="form-group">
            <label>Confirm Password</label>
            <input type="password" formControlName="confirmPassword" class="input-box" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
            </div>
        </div>
        <button class="signin">Reset  Password</button>
       
      
      </form>
    </div>

    <div class="overlay-container">
      <div class="overlay">
        <div class="overlay-panel overlay-right">
            <img src="assets/img/reset.png"  />
            <h1 class="font-weight-bold">Good to see you!</h1>
            <p>You already have an account? </p>
         <a [routerLink]="['/auth/login']">
               <button class="signup" id="signUp">Sign In</button>
         </a> 
        </div>
      </div>
    </div>
  </div>
  
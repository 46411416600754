import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Product,Category ,Unit} from '../_models/';

const baseUrl = `${environment.apiUrl}/product`;
const categoryUrl = `${environment.apiUrl}/category`;
const unitUrl = `${environment.apiUrl}/unit`;

@Injectable({ providedIn: 'root' })
export class SalesService {
    private productSubject: BehaviorSubject<Product>;
    public product: Observable<Product>;
    private categorySubject: BehaviorSubject<Category>;
    public category: Observable<Category>;
    private unitSubject: BehaviorSubject<Unit>;
    public unit: Observable<Unit>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.productSubject = new BehaviorSubject<Product>(null);
        this.product = this.productSubject.asObservable();
        this.categorySubject = new BehaviorSubject<Category>(null);
        this.category = this.categorySubject.asObservable();
        this.unitSubject = new BehaviorSubject<Unit>(null);
        this.unit = this.unitSubject.asObservable();
    }

    public get productValue(): Product {
        return this.productSubject.value;
    }
    public get categoryValue(): Category {
        return this.categorySubject.value;
    }
    public get unitValue(): Unit {
        return this.unitSubject.value;
    }

    getAll() {
        return this.http.get<Product[]>(baseUrl);
    }

    getById(id: string) {
        return this.http.get<Product>(`${baseUrl}/${id}`);
    }
    
    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((product: any) => {
                    // publish updated Product to subscribers
                    product = { ...this.productValue, ...product };
                    this.productSubject.next(product);
              
                return product;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    getAllCategory() {
        return this.http.get<Category[]>(categoryUrl);
    }
    getCategoryById(id: string) {
        return this.http.get<Category>(`${categoryUrl}/${id}`);
    }
    
    createCategory(params) {
        return this.http.post(categoryUrl, params);
    }
    
    updateCategory(id, params) {
        return this.http.put(`${categoryUrl}/${id}`, params)
            .pipe(map((category: any) => {
                    // publish updated Product to subscribers
                    category = { ...this.categoryValue, ...category };
                    this.categorySubject.next(category);
              
                return category;
            }));
    }
    deleteCategory(id: string) {
        return this.http.delete(`${categoryUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    getAllUnit() {
        return this.http.get<Unit[]>(unitUrl);
    }
    getUnitById(id: string) {
        return this.http.get<Unit>(`${unitUrl}/${id}`);
    }
    
    createUnit(params) {
        return this.http.post(unitUrl, params);
    }
    
    updateUnit(id, params) {
        return this.http.put(`${unitUrl}/${id}`, params)
            .pipe(map((unit: any) => {
                    // publish updated Product to subscribers
                    unit = { ...this.unitValue, ...unit };
                    this.unitSubject.next(unit);
              
                return unit;
            }));
    }
    deleteUnit(id: string) {
        return this.http.delete(`${unitUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { PricingDetail } from '../_models';

const baseUrl = `${environment.apiUrl}/pricingDetail`;

@Injectable({ providedIn: 'root' })
export class PricingDetailService {
    private pricingDetailSubject: BehaviorSubject<PricingDetail>;
    public pricingDetail: Observable<PricingDetail>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.pricingDetailSubject = new BehaviorSubject<PricingDetail>(null);
        this.pricingDetail = this.pricingDetailSubject.asObservable();
    }

    public get pricingDetailValue(): PricingDetail {
        return this.pricingDetailSubject.value;
    }

    register(pricingDetail: PricingDetail) {
        return this.http.post(`${baseUrl}/register`, PricingDetail);
    }

    getAll() {
        return this.http.get<PricingDetail[]>(baseUrl);
    }

    getById(id: string) {
        return this.http.get<PricingDetail>(`${baseUrl}/${id}`);
    }
    
    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((pricingDetail: any) => {
                    // publish updated membership to subscribers
                    pricingDetail = { ...this.pricingDetailValue, ...pricingDetail };
                    this.pricingDetailSubject.next(pricingDetail);
              
                return pricingDetail;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}
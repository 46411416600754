

export class Driver {
    id: string;
    nameA: string;
    nameE: string;
    photo: string;
    mobile: string;
    year: string;
    month: string;
    date: string;
    licenceYear: string;
    licenceType: string;
    active: boolean;
}
<h3 class="card-header">Verify Email</h3>
<alert></alert>
<div class="card-body">
    <div *ngIf="emailStatus == EmailStatus.Verifying">
        Verifying...
      
    </div>
    <div *ngIf="emailStatus == EmailStatus.Failed">
        Verification failed, you can also verify your account using the <a routerLink="forgot-password">forgot password</a> page.
    </div>
</div>

import {
  Component,
  AfterViewInit,
  EventEmitter,
  Output,
  Input,
  OnInit
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '../../_services';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements AfterViewInit, OnInit {
  public language: string;
  account = this.accountService.accountValue;
  modules : any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    public titleService: Title
) { }

  ngAfterViewInit() {
    // ----
  }
 
  ngOnInit(): void {
    this.accountService.getModulePermission(this.account.roleId)
    .pipe(first())
    .subscribe(modules => this.modules = modules);
  }
}



export class Cdr {
    id: string;
    disposition: string;
    calldate: string;
    cdate: string;
    ctime: string;
    year: string;
    month: string;
    week: string;
    hour: string;
    src: string;
    dst: string;
    number: string;
    dext: string;
    sext: string;
    iteam: string;
    oteam: string;
    billmin: string;
    billsec: string;
}
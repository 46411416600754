import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AccountClass } from '../_models';

const baseUrl = `${environment.apiUrl}/class`;

@Injectable({ providedIn: 'root' })
export class AccountClassService {
    private accountClassSubject: BehaviorSubject<AccountClass>;
    public accountClass: Observable<AccountClass>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.accountClassSubject = new BehaviorSubject<AccountClass>(null);
        this.accountClass = this.accountClassSubject.asObservable();
    }

    public get accountClassValue(): AccountClass {
        return this.accountClassSubject.value;
    }


    getAll() {
        return this.http.get<AccountClass[]>(baseUrl);
    }

    getById(id: string) {
        return this.http.get<AccountClass>(`${baseUrl}/${id}`);
    }
    
    create(params) {
        return this.http.post(baseUrl, params);
    }
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((accountClass: any) => {
                // update the current account if it was updated
               
                    // publish updated account to subscribers
                    accountClass = { ...this.accountClassValue, ...accountClass };
                    this.accountClassSubject.next(accountClass);
             
                return accountClass;
            }));
    }
    
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
               
            }));
    }
    

}


export class Operators {
    id: string;
    nameA: string;
    nameE: string;
    nameO: string;
    nameT: string;
    nameS: string;
    logo: string;
    tinNuber: string;
    mobile: string;
    email: string;
    telephone: string;
    bank: string;
    accountNumber: string;
    city: string;
    subCity: string;
    sefer: string;
    building: string;
    houseNumber: string;
    direction: string;
    active: boolean;
}
import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {Gender, Ethnicity, MaritalStatus,EmploymentStatus } from '../_models';

const genderUrl = `${environment.apiUrl}/gender`;
const ethnicityUrl = `${environment.apiUrl}/ethnicity`;
const maritalStatusUrl = `${environment.apiUrl}/maritalStatus`;
const employmentStatusUrl = `${environment.apiUrl}/employmentStatus`;

@Injectable({ providedIn: 'root' })
export class HrmSettingsService {
    private genderSubject: BehaviorSubject<Gender>;
    public gender: Observable<Gender>;
    private ethnicitySatusSubject: BehaviorSubject<Ethnicity>;
    public ethnicity: Observable<Ethnicity>;
    private maritalStatusSubject: BehaviorSubject<MaritalStatus>;
    public maritalStatus: Observable<MaritalStatus>;
    private employmentStatusSubject: BehaviorSubject<EmploymentStatus>;
    public employmentStatus: Observable<EmploymentStatus>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.genderSubject = new BehaviorSubject<Gender>(null);
        this.gender = this.genderSubject.asObservable();
        this.ethnicitySatusSubject = new BehaviorSubject<Ethnicity>(null);
        this.ethnicity = this.ethnicitySatusSubject.asObservable();
        this.maritalStatusSubject = new BehaviorSubject<MaritalStatus>(null);
        this.maritalStatus = this.maritalStatusSubject.asObservable();
        this.employmentStatusSubject = new BehaviorSubject<EmploymentStatus>(null);
        this.employmentStatus = this.employmentStatusSubject.asObservable();
    }

    public get genderValue(): Gender {
        return this.genderSubject.value;
    }

    public get ethnicityValue(): Ethnicity {
        return this.ethnicitySatusSubject.value;
    }

    public get maritalStatusValue(): MaritalStatus {
        return this.maritalStatusSubject.value;
    }

    public get employmentStatusValue(): EmploymentStatus {
        return this.employmentStatusSubject.value;
    }

   
   

    getAllGender() {
        return this.http.get<Gender[]>(genderUrl);
    }

    getAllEthnicity() {
        return this.http.get<Ethnicity[]>(ethnicityUrl);
    }

    getAllMaritalStatus() {
        return this.http.get<MaritalStatus[]>(maritalStatusUrl);
    }

    getAllEmploymentStatus() {
        return this.http.get<EmploymentStatus[]>(employmentStatusUrl);
    }

   
  

   //country Method
    getGenderById(id: string) {
        return this.http.get<Gender>(`${genderUrl}/${id}`);
    }

    getEthnicityById(id: string) {
        return this.http.get<Ethnicity>(`${ethnicityUrl}/${id}`);
    }

    getMaritalStatusById(id: string) {
        return this.http.get<MaritalStatus>(`${maritalStatusUrl}/${id}`);
    }
    getEmploymentStatusById(id: string) {
        return this.http.get<EmploymentStatus>(`${employmentStatusUrl}/${id}`);
    }

    createGender(params) {
        return this.http.post(genderUrl, params);
    }
    
    updateGender(id, params) {
        return this.http.put(`${genderUrl}/${id}`, params)
            .pipe(map((gender: any) => {
                    this.gender = { ...this.genderValue, ...gender };
                    this.genderSubject.next(gender);
              
                return gender;
            }));
    }
    deleteGender(id: string) {
        return this.http.delete(`${genderUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createEthnicity(params) {
        return this.http.post(ethnicityUrl, params);
    }
    
    updateEthnicity(id, params) {
        return this.http.put(`${ethnicityUrl}/${id}`, params)
            .pipe(map((ethnicity: any) => {
                    this.ethnicity = { ...this.ethnicityValue, ...ethnicity };
                    this.ethnicitySatusSubject.next(ethnicity);
              
                return ethnicity;
            }));
    }
    deleteEthnicity(id: string) {
        return this.http.delete(`${ethnicityUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createMaritalStatus(params) {
        return this.http.post(maritalStatusUrl, params);
    }
    
    updateMaritalStatus(id, params) {
        return this.http.put(`${maritalStatusUrl}/${id}`, params)
            .pipe(map((maritalStatus: any) => {
                    this.maritalStatus = { ...this.maritalStatusValue, ...maritalStatus };
                    this.maritalStatusSubject.next(maritalStatus);
              
                return maritalStatus;
            }));
    }
    deleteMaritalStatus(id: string) {
        return this.http.delete(`${maritalStatusUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createEmploymentStatus(params) {
        return this.http.post(employmentStatusUrl, params);
    }
    
    updateEmploymentStatus(id, params) {
        return this.http.put(`${employmentStatusUrl}/${id}`, params)
            .pipe(map((employmentStatus: any) => {
                    this.employmentStatus = { ...this.employmentStatusValue, ...employmentStatus };
                    this.employmentStatusSubject.next(employmentStatus);
              
                return employmentStatus;
            }));
    }
    deleteEmploymentStatus(id: string) {
        return this.http.delete(`${employmentStatusUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }


   
    
}
<div class="" id="container">

  <div class="form-container sign-in-container">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="navbar-header" >
          <a class="navbar-brand" routerLink="/">
              <!-- Logo icon -->
              <b class="logo-icon">
                  <img src="assets/img/onetaplogo.png" alt="homepage" class="light-logo" />
              </b>
          </a>
      </div>
      <alert></alert>
       <div class="welcome">
            <h1>Welcome, {{account.firstName}}.</h1>
            <h3>You're almost there! Just confirm your email</h3>
           
       </div>

       <div class="board">
            <p>We sent an email to <b class="email">{{email}}</b> for verification. Please check your email to verify and activate your account.</p>
      </div>
     
 
   <div class="resend">
    If you haven’t received an email, please check your spam folder or <span class="link">Click</span> here to retry again
   </div>
    
    </form>
  </div>

  <div class="overlay-container">
    <div class="overlay">
      <div class="overlay-panel overlay-right">
          <img src="assets/img/register2.png"  />
   
      </div>
    </div>
  </div>
</div>



export class TicketOffice {
    id: string;
    mobile: string;
    email: string;
    telephone: string;
    city: string;
    subCity: string;
    sefer: string;
    building: string;
    houseNumber: string;
    direction: string;
    active: boolean;
}
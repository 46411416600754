import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { GigStep,PriceStep,ProfileStep } from '../_models/';

const baseUrl = `${environment.apiUrl}/gigstep`;
const profileUrl = `${environment.apiUrl}/profilestep`;
const priceUrl = `${environment.apiUrl}/pricestep`;
const freeUrl = `${environment.apiUrl}/freestep`;


@Injectable({ providedIn: 'root' })
export class StepService {
    private gigSubject: BehaviorSubject<GigStep>;
    private priceSubject: BehaviorSubject<PriceStep>;
    private profileSubject: BehaviorSubject<ProfileStep>;
    public gig: Observable<GigStep>;
    public price: Observable<PriceStep>;
    public profile: Observable<ProfileStep>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.gigSubject = new BehaviorSubject<GigStep>(null);
        this.priceSubject = new BehaviorSubject<PriceStep>(null);
        this.profileSubject = new BehaviorSubject<ProfileStep>(null);
        this.gig = this.gigSubject.asObservable();
    }

    public get gigValue(): GigStep {
        return this.gigSubject.value;
    }

    public get priceValue(): PriceStep {
        return this.priceSubject.value;
    }
    public get profileValue(): ProfileStep {
        return this.profileSubject.value;
    }



    getAll() {
        return this.http.get<GigStep[]>(baseUrl);
    }
    create(params) {
        return this.http.post(baseUrl, params);
    }
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((gig: any) => {
                    // publish updated business to subscribers
                    gig = { ...this.gigValue, ...gig };
                    this.gigSubject.next(gig);
              
                return gig;
            }));
    }
    getById(id: string) {
        return this.http.get<GigStep>(`${baseUrl}/${id}`);
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    getAllPrice() {
        return this.http.get<PriceStep[]>(priceUrl);
    }
    createPrice(params) {
        return this.http.post(priceUrl, params);
    }
    getPriceById(id: string) {
        return this.http.get<PriceStep>(`${priceUrl}/${id}`);
    }
    updatePrice(id, params) {
        return this.http.put(`${priceUrl}/${id}`, params)
            .pipe(map((price: any) => {
                    // publish updated business to subscribers
                    price = { ...this.priceValue, ...price };
                    this.priceSubject.next(price);
              
                return price;
            }));
    }
    deletePrice(id: string) {
        return this.http.delete(`${priceUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    getAllProfile() {
        return this.http.get<ProfileStep[]>(profileUrl);
    }
    createProfile(params) {
        return this.http.post(profileUrl, params);
    }
    getProfileById(id: string) {
        return this.http.get<ProfileStep>(`${profileUrl}/${id}`);
    }
    updateProfile(id, params) {
        return this.http.put(`${profileUrl}/${id}`, params)
            .pipe(map((profile: any) => {
                    // publish updated business to subscribers
                    profile = { ...this.profileValue, ...profile };
                    this.priceSubject.next(profile);
              
                return profile;
            }));
    }
    deleteProfile(id: string) {
        return this.http.delete(`${profileUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}


export class Ticket {
    id: string;
    number: string;
    name: string;
    tickettype;
    ticketLevel ;
    ticketStatus;
    ticketPriority;
    classification;
    category;
    hour : string;
    active: boolean;
}

export class TaskReport {
    id: string;
    year: string;
    month: string;
    week: string;
    date: string;
    hour: string;
    uyear: string;
    umonth: string;
    uweek: string;
    udate: string;
    uhour: string;
    taskId: string;
    startDate: string;
    endDate :String;
    ticketNo: string;
    reminder: string;
    ReminderPeriod: string;
    rstartDate: string;
    rendDate: string;
    frist: string;
    resolution: string;
    escalated: string;
    reopen: string;
    closed: string;
    completedtime: string;
    classificationId: string;
    taskstatusId: string;
    departmentId: string;
    supportTeamId: string;
    accountId: string;
    ticketSourceId: string;
    ticketLevelId: string;
    reportedById: string;
    created: string;
    updated: string;
    category: string;
    channel;
    priority;
    status: string;
    statusColor: string;
    dept: string;
    team: string;
    proj: string;
    reporter: string;
    reporterEmail: string;
    reporterMobile: string;
    updater: string;
    updaterEmail: string;
    updaterMobile: string;
    worker: string;
    woerkerEmail: string;
    workerMobile: string;
    slaId: string;
    sla: string;
    resolvedat: string;
    closedat: string;
    ticketLevel ;
    stats;
    ticketStatus;
    classification;
    active: boolean;
    number;
    priorities;
}



<div class="loginpage">

 
    <div class="form-container sign-in-container">
      <div class="navbar-header" >
        <a class="navbar-brand" routerLink="/">
            <!-- Logo icon -->
            <b class="logo-icon">
                <img src="assets/img/onetaplogo.png" alt="homepage" class="light-logo" />
            </b>
        </a>
    </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <div >
            <div class="signin">
            <h1 class="font-weight-bold">Hello, Friend!</h1>
            <div class="form-group">
              <label>Email</label>
              <input type="text" formControlName="email" class="input-box" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email is invalid</div>
              </div>
          </div>
            <div class="form-group">
              <label>Password</label>
              <input type="password" formControlName="password" class="input-box" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
          </div>
          <button class="btn default-btn">
            Sign In
        </button>
         
        <div class="forgot" [routerLink]="['../forgot-password']">
            Cant login?
            </div>
          </div>
        <alert></alert>
        </div>
      
      </form>
   
    </div>

    <div class="overlay-container">
      <div class="overlay">
        <div class="overlay-panel overlay-right">
          <img src="assets/img/onetapucc.png" alt="homepage" class="light-logo" />
        </div>
      </div>
    </div>
  </div>
  
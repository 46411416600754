
<div id="main-wrapper">

    <header class="topbar" *ngIf="account">
        <!-- *ngIf="module.page === 'Branch' && account.type === 'HeadOffice'" -->
                <nav class="navbar top-navbar navbar-expand-md" >
                    <div class="navbar-header" >
                        <a class="navbar-brand" routerLink="/">
                            <!-- Logo icon -->
                            <b class="logo">
                                <img src="assets/img/onetaplogo.png" alt="homepage" class="light" />
                            </b>
                        </a>
                    </div>
                    <ul class="navbar-nav  mr-auto navbar-left" *ngIf="modules" >
                        <li class="nav-item"  *ngIf="account.type === 'HeadOffice'">
                            <a  routerLink="/dashboard"  class="nav-link waves-effect waves-dark"[routerLinkActive]="'active'"  href="javascript:void(0)">
                                <span class="d-md-block">  <img width="20px" src="../assets/img/header-icons/MainDashboard.svg">Dashboard
                                </span>
                            </a>
                        </li>
                        <li class="nav-item"  *ngIf="account.type === 'Agent'" >
                            <a routerLink="/call" class="nav-link waves-effect waves-dark" routerLinkActive="active" href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainSales.svg">Search  
                          </span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="account.type === 'Branch'">
                            <a routerLink="/branch"  class="nav-link waves-effect waves-dark" routerLinkActive="active" href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainSales.svg">  Branch
                          </span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="account.type === 'Agent'">
                            <a routerLink="/ticket"  class="nav-link waves-effect waves-dark" routerLinkActive="active" href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainSales.svg">  Ticket
                          </span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="account.type === 'Agent'">
                            <a routerLink="/task"  class="nav-link waves-effect waves-dark" routerLinkActive="active" href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainSales.svg">  Task
                          </span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="account.type === 'HeadOffice'">
                            <a routerLink="/crm"  class="nav-link waves-effect waves-dark" routerLinkActive="active" href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainSales.svg">CRM  
                          </span>
                            </a>
                        </li>
                        <li class="nav-item" >
                            <a routerLink="/knowledgebase"  class="nav-link waves-effect waves-dark" routerLinkActive="active" href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainSales.svg">Knowledge Base
                          </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/faq"  class="nav-link waves-effect waves-dark" routerLinkActive="active" href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainSales.svg">FAQ  
                          </span>
                            </a>
                        </li>
                   
                        <li class="nav-item"  *ngIf="account.type === 'HeadOffice'">
                            <a routerLink="/callcenter" [routerLinkActive]="'active'" class="nav-link waves-effect waves-dark"  href="javascript:void(0)">
                                <span class="d-md-block"> Call Center</span>
                            </a>
                        </li>
                        <li class="nav-item"  *ngIf="account.type === 'HeadOffice'">
                            <a routerLink="/cdr" [routerLinkActive]="'active'" class="nav-link waves-effect waves-dark"  href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainServices.svg"> CDR Report</span>
                            </a>
                        </li>
                        <li class="nav-item"  *ngIf="account.type === 'HeadOffice'"> 
                            <a routerLink="/analytic" [routerLinkActive]="'active'" class="nav-link waves-effect waves-dark"  href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainServices.svg"> Analytics</span>
                            </a>
                        </li>
                        <li class="nav-item" > 
                            <a routerLink="/settings" [routerLinkActive]="'active'" class="nav-link waves-effect waves-dark"  href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainServices.svg"> Settings</span>
                            </a>
                        </li>
                
                    </ul>
            
                    <ul class="navbar-nav  border-left profile-bar">
                 
                        <li class="nav-item" style="cursor: default"  placement="bottom-right">
                            <a mat-button [matMenuTriggerFor]="menu" class="nav-link  dropdown-toggle waves-effect waves-dark" style="cursor: default" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                    <img src="assets/img/onetaplogo.png" width="50" height="50" class="rounded-circle" alt="image" >
                               <span class="profile">  {{account.firstName}} {{account.lastName}}</span>
                         
                            </a>
                            <div class="" >
                    
                                 <mat-menu #menu="matMenu" xPosition="after" yPosition="below">
                                            
                                    <li class="nav-item dropdown-item"  mat-menu-item> 
                                        <a [routerLink]="['accounts/profile']">
                                            <span> <img src="../assets/img/header-icons/MainServices.svg"> My Profile </span> 
                                        </a>
                                    </li>
                                    <li class="nav-item dropdown-item"  mat-menu-item> 
                                        <a [routerLink]="['accounts/password']">
                                            <span> <img src="../assets/img/header-icons/MainServices.svg">  Change Password</span>
                                        </a>
                                    </li>
                                    <hr/>
                                    <li class="nav-item dropdown-item"  mat-menu-item> 
                                        <p  (click)="logout()">
                                            <span> <img src="../assets/img/header-icons/MainServices.svg"> Log out </span> 
                                        </p>
                                    </li>
                                </mat-menu>
                            </div>
                        </li>
                    </ul>
        </nav>
    </header>
 
    <router-outlet>  </router-outlet>
    <footer class="footer text-center" *ngIf="account">
        <div class="footer-wrap">
            <div class="footer-left">
                <a href="https://onetap.et"><h3> OneTap </h3></a>
                <a href="https://onetap.et"><p> Copyrights &copy; 2023, OneTap Technologies PLC. All Rights Reserved. </p></a> 
            </div>
            <div class="footer-right">
                V.1.0
            </div>
        </div>
    </footer>
    <div class="chat-windows"></div>
</div>


export class BusType {
    id: string;
    brand: string;
    model: string;
    seats: string;
    year: string;
    since: string;
    active: boolean;
}
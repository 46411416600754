
   <app-vendor-header></app-vendor-header>

            <div class="p-4">
                <div class="container">
                    <h1>Hi {{account.firstName}} {{account.lastName}}!</h1>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{app.application.applcationName}}</h5>
                  <p class="card-text">
                    {{app.application.describtion}}
                  </p>
                </div>
              </div>
    
              
            <div class="container">
                <!--Section: Content-->
                <section class="text-center">
                  <h4 class="mb-4"><strong>Pricing Plan</strong></h4>
          
                  <div class="btn-group mb-4" role="group" aria-label="Basic example">
                 
                  </div>
          
                  <div class="row gx-lg-5">
          
                  
          
                    <!--Grid column-->
                    <div class="col-lg-3 col-md-6 mb-4"   *ngFor="let plan of app.prices">
          
                      <!-- Card -->
                      <div class="card" >
          
                        <div class="card-header" [ngStyle]="{'background-color':pId ==plan.id ? plan.colorCode : '#eee'}">

                          <p class="text-uppercase small mb-2"><strong>{{plan.plan}} </strong></p>
                          <h5 class="mb-0">{{plan.price}} Birr / Month</h5>
                        </div>
          
                        <div class="card-body" >
                          <ul class="list-group list-group-flush"  *ngFor="let feature of plan.appFeatures" >
                            <li class="list-group-item">{{feature.name}} </li>
                          </ul>
                        </div>
          
                        <div class="card-footer bg-white py-3" *ngIf="pId ==plan.id">
                          <a [routerLink]="['/pricing', app.application.id,'cart',plan.id]"> <button type="button" class="btn btn-danger btn-sm"  [style.background-color]="plan.colorCode">Check Out</button> </a>
                        </div>

                        <div class="card-footer bg-white py-3" *ngIf="pId !=plan.id">
                          <a [routerLink]="['/pricing', app.application.id,'plan',plan.id]" > <button type="button" class="btn btn-default btn-sm" >Learn More</button> </a>
                        </div>
          
                      </div>
                      <!-- Card -->
          
                    </div>
                   
                  </div>
                </section>
                <!--Section: Content-->
              </div>
      







    <div class="vendor-sidebar">
        <!-- <a href="javascript:void(0)" class="flex tip" ngbTooltip="vendor" placement="right" [routerLink]="['/homeside']" >
        <img src="../../../assets/img/header-icons/marketplace-vendor-list.svg"  />
    </a>
    <a href="javascript:void(0)" class="flex" ngbTooltip="products" placement="right" [routerLink]="['/homeside']" >
        <img src="../../../assets/img/header-icons/marketplace-vendor-product.svg"  />
    </a>
    <a href="javascript:void(0)" class="flex" ngbTooltip="settings" placement="right"  [routerLink]="['/homeside']" >
        <img src="../../../assets/img/header-icons/marketplace-vendor-setting.svg" />
    </a>
     -->
    </div>
